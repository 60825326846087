/* You can add global styles to this file, and also import other style files */

@import "./assets/css/main3.css";
@import "./assets/css/profile.css";
@import "./assets/css/user.css";
@import "./assets/css/flowboard.css";
@import "./assets/css/dashboard.css";
@import "./assets/css/common.css";
@import "./assets/css/sidebar.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "./assets/css/review.css";
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/css/competency.css';
@import './assets/css/theme.css';
/* @import './assets/css/base.css';
@import './assets/css/components.css'; */
@import './assets/css/layout.css';
@import url('https://testapps-cdn.trainery.one/CommonNavMenus/New_left_menu_style/leftMenuStyles.css');

:root{
    --RC-bg-darkblue: #203864;
    --RC-bg-gold: #EBBC46;
    --RC-bg-green: #66BD50;
    --RC-bg-red: #F86F6F;
    --RC-btn-save-background:#255D9B;
    --RC-btn-save-secondary-background:#4B8BD1;
    --RC-closed-color: #F86F6F;
    --RC-copy-icon-color: #EBBC46;
    --RC-edit-color: #5b84eb;
    --RC-edit-icon-color: #5b84eb;
    --RC-extend-icon-color: #5b84eb;
    --RC-label-color: #888;
    --RC-left-menu-color: #33475E;
    --RC-preview-icon:#5b84eb;
    --RC-primary-color: #D7E3FE;
    --RC-reopen-color: #66BD50;
    --RC-reopen-icon-color: #66BD50;
    --RC-secondary-color:#EAF0FF;
    --RC-share-color: #ED7117;
    --RC-table-count-color: #8394A6;
    --RC-table-employee-column-color: #00579F;
    --RC-table-even-bg-color:#F0F0F0;
    --RC-table-header-color: #cccccc;
    --RC-table-header-text-color: #3F3F3F;
    --RC-table-heading-text-color: #3F3F3F;
    --RC-table-row-hover-color: #EAF0FF;
    --RC-text-color: #3F3F3F;
    --RC-top-menu-color:#5E7084;
    --RC-top-menu-hover-active-color:#33475D;
    --RC-update-color: #EBBC46;
    --RC-upload-icon-color: #5b84eb;
    --RC-white-color: #ffffff;
   --RC-left-menu-highlight-background-color:#EBBC46;
   --RC-secondary-utility-color:#4B8BD1;
   --RC-utility-color:#255D9B;
   --RC-l2-menu-color:#8394A6;
   --primary-bg: #33475e;
   --text-color: white;
   --highlight-color: #e4a802;
   --menu-hover-bg: #ffffff14;
   --l2-menu-hover:#EBEBEB;
   --secondary-header-bg-color:#879cb3;
   --RC-common-bg-color:#F6F6F6;
   /* 1575e7 */
}



::ng-deep.mat-datepicker-content .mat-calendar {
  width: unset !important;
  height: unset !important;
}

/* WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

html,
body,
.side,
.content-section {
  height: 100%;
  width: 100%;
  margin: 0;
}
/* For Safari */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins' !important;
}





.activebg {
  background-color: #EBBC46 !important;
  color: #03080A !important;
}

.myDIV>.active>a,
.myDIV>.active>a:focus,
.myDIV>.active>a:hover {
  background-color: #EBBC46 !important;
  color: #03080A !important;
}

.addusersuperadmin .mat-dialog-container {
  position: absolute;
  top: 40px;
  left: 24%;
  width: auto !important;
  /* min-width:765px; */
  /* width:60%!important; */
  height: 83% !important;
  overflow: hidden !important;
  padding: -10 !important;
}

.adduser .mat-dialog-container {
  position: absolute;
  top: 40px !important;
  left: 150px !important;
  width: 1040px !important;
  height: 520px !important;
}

.addusercomp .mat-dialog-container {
  top: 5% !important;
  left: 9% !important;
  width: 83% !important;
  height: 660px !important;
  overflow: auto !important;
}


.export-data-dialog .mat-dialog-container {
  top: 19% !important;
  left: 9% !important;
  width: 83% !important;
  height: 450px !important;
  overflow: auto !important;
}

.adduser .mat-dialog-container {
  top: 5% !important;
  left: 9% !important;
  width: 83% !important;
  height: 770px !important;
  overflow: auto !important;
}

.select-elements .mat-dialog-container {
  top: 20%;
  left: 25%;
  width: 700px !important;
  height: 505px !important;
  padding: 10px !important;
}


.dropdown-link-a {
  padding: 2px;
}

a {
  text-decoration: none !important;
}


.modal-content {
  background-color: #fefefe;
  margin: 0% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

.font-size-13px {
  font-size: 13px !important;
}

.topbar .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.topbar .navbar-expand-lg .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  justify-content: space-between;
}

ul.nav-external1 li {
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.upload-dialog .mat-dialog-container{
  top: 11% !important;
  left: 25% !important;
  width: 50% !important;
  height: 660px !important;
  overflow: auto !important;
  padding: 3px !important;
}

.certificate-dialog .mat-dialog-container{
  top: 5% !important;
  left: 20% !important;
  width: 55% !important;
  height: max-content !important;
  padding: 0px !important;
  overflow: auto !important;
}



.addusercomps .mat-dialog-container {
  top: 5% !important;
  left: 9% !important;
  width: 83% !important;
  height: 660px !important;
  overflow: auto !important;
}

@media only screen and (min-width:768px) and (max-width:1060px) {
  .org-head {
    font-size: 10px !important;
  }
}

@media only screen and (min-width:768px) and (max-width:1400px) {
  #profile-pic {
    left: 85%;
  }

  .addusercomp .mat-dialog-container {
    top: 2% !important;
    left: 9% !important;
    width: 83% !important;
    height: 100% !important;
    /* overflow: auto !important; */
  }

  .adduser .mat-dialog-container {
    top: 2% !important;
    left: 9% !important;
    width: 83% !important;
    height: 100% !important;
    /* overflow: auto !important; */
  }

  .addusercomps .mat-dialog-container {
    top: 2% !important;
    left: 9% !important;
    width: 83% !important;
    height: 85% !important;
    /* overflow: auto !important; */
  }
}

mat-icon {
  font-family: 'Material Icons' !important;
}

.nav>li>.apdding {
  position: relative;
  display: block;
  padding: 0px 0px !important;
}



.head {
  /* width:210px; */
  width: 208px;
  /* min-height: 70px; */
  padding-top: 10px;
  margin: 20px 10px 0px 20px;
}

.image-space {
  padding-right: 10px;
  padding-left: 10px;
}


.avatar {
  vertical-align: middle;
  width: 36px;
  height: 33px;
  border-radius: 50%;
  background-color: #03080A;
}

.avataruser {
  vertical-align: middle;
  width: 28px;
  height: 26px;
  border-radius: 50%;
}

.logo {
  padding-left: 10px;
  padding-top: 10px;
}

.top-right {
  text-align: end;
}

.center {
  text-align: center;
}

.ngx-toastr {
  border-radius: 10px !important;
}

.ngx-toastr {
  height: 60px !important;
  width: 500px !important;
}

.navbar.navbar-default {
  background-color: rgb(0, 32, 128);
  border-color: #000000;
}

.navbar.navbar-default .navbar-brand {
  color: white;
}

.navbar.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

.navbar.navbar-default .navbar-nav.navbar-right li a {
  color: white;
  font-family: 'Ubuntu', sans-serif;
}

.bell {
  padding-left: 10px;
  margin-left: 10px;
}

.help {
  padding-left: -25px;
  margin-left: -5px;
  margin-top: -5px;
}

/* edit and close button in table */
.fixed-side {
  background-color: #F2F2F2;
  position: sticky;
  right: 0;
  z-index: 1;
  opacity: 1 !important;
}

.input-edit {
  width: 100%;
  border: none !important;
}

.input-edit:focus {
  outline: none;
}

.small-input {
  /* background-color: #F2F2F2!important;
    border: none!important;
    height: 33px;
    border-radius: 4px;
    width:100%;
    padding-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px; */

  line-height: 20px;
  width: 100%;
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: none;
  background: #F2F2F2 !important;
}

.small-input-edit {
  line-height: 20px;
  width: 100%;
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF !important;
}

@media only screen and (min-width:1000px) and (max-width:1948px) {
  .divrow2outer {
    margin-left: -3% !important;
  }

  .firstrowpie1 {
    margin-left: 0% !important;
  }
}

@media only screen and (min-width:1000px) and (max-width:1092px) {
  .divrow2outer {
    margin-left: 0% !important;
  }

  .firstrowpie1 {
    margin-left: -6% !important;
  }
}

@media only screen and (min-width:1800px) and (max-width:1948px) {
  .divrow2outer {
    margin-left: -4% !important;
  }
}

@media (min-width:992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

@media only screen and (min-width:2560px) and (max-width:3560px) {

  /* .borderstyle {
    width:8.099%!important;
  } */
  .org_name_edit {
    width: 100%;
  }

  .org_name_edit_lastend {
    width: 100%;
  }

  .org_name_edit_last {
    width: 100%;
  }

  .first12 {
    margin-right: 65px;
  }

  .margin-to-leftsmall {
    margin-left: -82px !important;
  }

  /* .first12 {
    margin-right: 33px!important;
  } */
  .first1 {
    margin-right: 71px !important;
  }

  .past-margin {
    margin-left: -100px;
  }

  .first2 {
    margin-left: 5%;
  }

  .d2outer {
    margin-left: -5.5% !important;
  }

  .firstrowpie1 {
    margin-right: 11px;
    /* margin-right: -29px; */
    /* width:795px!important; */
    margin-left: 10px !important;
  }

  .divrow2outer {
    margin-left: -3%;
  }

  .resemp-review .ngx-select__disabled {
    width: 100% !important;
  }

  .small-widthname {
    width: 18.3% !important;
  }

  .small-width {
    width: 12% !important;
  }

  .search-icon-admin {
    top: 39%;
  }

  .review-val {
    margin-left: 551px !important;
  }

  .targetval {
    width: 100%;
  }

  .action-head {
    text-align: center;
    padding-left: 224px !important;
  }

  .action-btngp {
    margin-left: 160px;
  }

  .user_name_edit {
    width: 100%;
  }

  .input-edit {
    width: 100%;
  }

  .org_name_edit2 {
    width: 100%;
  }

  .org_name_edit3 {
    width: 100%;
  }

  .drop-select-adduser {
    width: 100%;
  }

  .due-date-user {
    width: 100%;
  }

  .head-logo {
    margin-right: 10px;
  }

  .addadmins-goalreview .ngx-select__selected {
    width: 1429px;
  }

  .add-goal-new .ngx-select__selected {
    width: 1410px;
  }

  #search-blue-user-survey {
    left: 1410px;
  }

  .cons-message {
    margin-left: 886px !important;
  }

  .dueDate {
    /* width:630px!important; */
    width: 97.4% !important;
    height: 48px;
  }

  .companypie {
    padding-left: 3px;
    margin-right: -1px;
  }

  .feedright {
    margin-left: -13px;
  }

  .pieright {
    margin-right: 10px;
  }

  .compie {
    margin-right: -1px;
  }

  .goalpie {
    padding-left: 3px;
    margin-right: 324px;
  }

  .addquesform .mat-dialog-container {
    position: absolute;
    top: 60px !important;
    left: 941px !important;
  }

  .mat-dialog-container {
    position: static !important;
    top: 71px;
    left: 789px;
  }

  .editlevel .mat-dialog-container {
    position: absolute;
    top: 127px;
    left: 992px;
  }

  .trasferdir .mat-dialog-container {
    position: absolute;
    top: 126px;
    left: 941px;
  }

  .addDir .mat-dialog-container {
    position: absolute;
    top: 52px;
    left: 941px;
  }

  .emplist .mat-dialog-container {
    position: absolute;
    top: 52px;
    left: 683px;
  }

  .empparticipant .mat-dialog-container {
    top: 126px;
    left: 910px;
  }

  .firstrowdiv1 {
    width: auto;
    height: 560px;
  }

  .firstrowdiv2 {
    width: 1662px;
    height: 560px;
    /* width:auto;
    height: 632px;
    padding-top: 227px; */
  }

  .eventchart {
    height: 550 !important;
    width: 1100 !important;
  }

  .eventchart .apexcharts-canvas {
    height: 550 !important;
    width: 1100 !important;
  }

  .btn-user {
    padding-left: 119px !important;
  }

  .savebtn {
    background-color: #f27127;
    /* margin-right: 10px; */
    /* margin-right: 195px!important; */
  }

  .box {
    margin-right: 22px;
  }

  #importdiv {
    padding-left: 104px;
  }

  /* .allign-man-val{
  margin-left: -500px;
} */
  /* .searchValue-mytodo {
 
  width:381px!important;
 
} */
  .search-icon-mytodo {
    top: 44%;
  }

  .searchformst {
    margin-right: -595px;
  }

  .marginfieldreq {
    margin-left: -275px;
  }

  .goal .mat-dialog-container {
    position: absolute;
    top: 162px;
    left: 949px;
  }

  .review-val {
    margin-left: 597px;
  }

  .addSurvey .mat-dialog-container {
    position: absolute;
    top: 52px;
    left: 923px;
  }

  .marginfield-opt {
    margin-left: -185px;
  }

  .commentbox .mat-dialog-container {
    position: absolute;
    top: 123px;
    left: 949px;
  }

  .progress {
    margin-left: 19px !important;
    width: 98%;
  }

  /* .addadmins-goal .ngx-select__selected {
    width:1620px!important;
  } */
  .large-tc {
    padding-left: 58px !important;

  }

  .large-ln {
    padding-left: 74px !important;
  }

  .large-dp {
    margin-left: -4px !important;
  }

  .large-e {
    margin-left: 55px !important;
  }

  .large-st {
    margin-left: 140px !important;
  }

  .large-ll {
    margin-left: 104px !important;
  }

  .large-bt {
    text-align: center;
    margin-left: 226px !important;
  }

  .addmember .mat-dialog-container {
    left: 1013px;
  }

  .adminPop .mat-dialog-container {
    left: 1049px;
  }

  .addgroupstyle .mat-dialog-container {
    left: 960px;
  }

  .deletedir .mat-dialog-container {
    left: 1110px;
  }

  #search-blue-user {
    left: 1214px;
  }

  .finaluser {
    margin-left: -490px !important;
  }

  .addadmins-goal .ngx-select__selected {
    width: 1507px !important;
  }

  .addadmins-reviews .ngx-select__selected {
    /* width:1507px!important; */
    width: 100%;
  }

  .dueDatereview {
    width: 94.3% !important;
  }

  .valmargin-review {
    margin-left: 551px;
  }

  .space-large-div {
    padding-left: 90px !important;
  }

  .div2outer {
    margin-left: -154px !important;
  }

  .large-width {
    width: 140% !important;
  }

  .firstrowdiv1 {
    padding-top: 71px !important;
  }

  .add-goal .ngx-select__selected {
    width: 1426px !important;
  }

  .addadmins-reviews .ngx-select__selected {
    /* width:1409px!important; */
    width: 100%;
  }

  .user-drop-review .ngx-select__search {
    /* width:769px; */
    width: 101.5% !important;
  }

  .user-drop-gedit .ngx-select__disabled {
    width: 100% !important;
  }

  .user-drop-gedit-goal .ngx-select__disabled {
    width: 100% !important;
  }

  .goal-res-employee .ngx-select_multiple {
    width: 101.5% !important;
  }

  .dueDate-rev {
    width: 100% !important;
  }

  .addreviewdueDate {
    width: 98%;
  }
}


@media only screen and (min-width:1450px) and (max-width:2560px) {

  /* .borderstyle {
    width:8.099%!important;
  } */
  .org_name_edit {
    width: 100%;
  }

  .org_name_edit_lastend {
    width: 100%;
  }

  .org_name_edit_last {
    width: 100%;
  }

  .first12 {
    margin-right: 65px;
  }

  .first2 {
    margin-left: 8%;
  }

  .d2outer {
    margin-left: -110px;
  }

  .firstrowpie1 {
    margin-left: 7%;
    margin-left: 10px;
  }

  .divrow2outer {
    margin-left: -5%;
  }

  .resemp-review .ngx-select__disabled {
    width: 100% !important;
  }

  .plusbtn {
    text-align: left;
    padding-left: 0px !important;
  }

  .small-widthname {
    width: 21.8% !important;
  }

  .small-width {
    width: 12% !important;
  }

  .search-icon-admin {
    top: 39%;
  }

  .addreviewdueDate {
    width: 98%;
  }

  .review-val {
    margin-left: 551px !important;
  }

  .dueDate-rev {
    width: 99.2% !important;
  }

  .goal-res-employee .ngx-select_multiple {
    width: 101.5% !important;
  }

  .user-drop-gedit .ngx-select__disabled {
    width: 100% !important;
  }

  .user-drop-gedit-goal .ngx-select__disabled {
    width: 100% !important;
  }

  .targetval {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #80808066 !important;
    height: 40px;
  }

  .user-drop-gedit .ngx-select__search {
    /* width:769px; */
    width: 101.2% !important;
  }

  .user-drop-gedit-goal .ngx-select__search {
    /* width:769px; */
    width: 101.5% !important;
  }

  .action-btngp {
    margin-left: 12%;
  }

  .action-head {
    text-align: center;
    padding-left: 8% !important;
  }

  .user_name_edit {
    width: 100%;
  }

  .input-edit {
    width: 100%;
  }

  .org_name_edit2 {
    width: 100%;
  }

  .org_name_edit3 {
    width: 100%;
  }

  .drop-select-adduser {
    width: 100%;
  }

  .due-date-user {
    width: 100%;
  }

  .head-logo {
    margin-right: 10px !important;
  }

  .addadmins-goalreview .ngx-select__selected {
    width: 1571px;
  }

  .add-goal-new .ngx-select__selected {
    width: 1569px;
  }

  #search-blue-user-survey {
    left: 1410px;
  }

  .cons-message {
    margin-left: 553px !important;
  }

  .firstrowdiv1 {
    padding-top: 71px !important;
  }

  .upload-type {
    padding-left: 29px;
  }

  .imp-width-last {
    width: 43% !important;
  }

  .imp-width-mid {
    width: 19% !important;
  }

  .dueDatereview {
    width: 81% !important;
  }

  /* .addadmins-goal .ngx-select__selected {
    width:1507px!important;
  } */
  .addadmins-reviews .ngx-select__selected {
    /* width:1543px!important; */
    width: 100%;
  }

  .user-drop-review .ngx-select__search {
    /* width:769px; */
    width: 99.5% !important;
  }

  .add-goal .ngx-select__selected {
    width: 1415px !important;
  }

  .add-goalcon .ngx-select__selected {
    /* width:1583px!important; */
    width: 99% !important;
  }

  .valmargin-review {
    margin-left: 551px;
  }

  #search-blue-user {
    left: 1158px;
  }

  .dueDategoal {
    width: 99.5% !important;
  }

  .dueDatecon {
    width: 99.2% !important;
  }

  .dueDate {
    height: 48px;
    /* width:630px!important; */
    width: 100% !important;
  }

  .large-width {
    width: 135% !important;
  }

  .empparticipant .mat-dialog-container {
    top: 126px;
    left: 20% !important;
  }

  .addquesform .mat-dialog-container {
    position: absolute;
    top: 60px !important;
    left: 20% !important;
  }

  .mat-dialog-container {
    position: absolute;
    top: 71px;
    left: 20%;
  }

  .finaluser {
    margin-left: -465px !important;
  }

  .editlevel .mat-dialog-container {
    position: absolute;
    top: 127px;
    left: 20%;
  }

  .trasferdir .mat-dialog-container {
    position: absolute;
    top: 126px;
    left: 20%;
  }

  .addDir .mat-dialog-container {
    position: absolute;
    top: 52px;
    left: 20%;
  }

  .emplist .mat-dialog-container {
    position: absolute;
    top: 52px;
    /* left: 683px; */
  }

  .addrightbtn {
    text-align: end;
    margin-left: 1029px;
  }

  .firstrowdiv1 {
    width: 121%;
    height: 560px;
    /* 632px; */
  }

  .firstrowdiv2 {
    width: 123%;
    height: 560px;
    padding-top: 133px;
  }

  .space-large-div {
    padding-left: 90px !important;
  }

  .div2outer {
    margin-left: -154px !important;
  }

  .btn-user {
    padding-left: 119px !important;
  }

  .savebtn {
    background-color: #f27127;
    /* margin-right: 10px; */
    /* margin-right: 324px!important; */
  }

  /* .addadmins-goal .ngx-select__selected {
  width:1620px!important;
} */
  .box {

    margin-right: 15px;
    /* margin-right: 22px; */
  }

  #importdiv {
    padding-left: 15px;
  }

  /* .allign-man-val{
  margin-left: -500px;
} */
  /* .searchValue-mytodo {
 
  width:381px!important;
 
} */
  .search-icon-mytodo {
    top: 44%;
  }

  .searchformst {
    margin-right: -595px;
  }

  .marginfieldreq {
    margin-left: -275px;
  }

  .goal .mat-dialog-container {
    position: absolute;
    top: 162px;
    left: 949px;
  }

  .review-val {
    margin-left: 597px;
  }

  .addSurvey .mat-dialog-container {
    position: absolute;
    top: 52px;
    left: 923px;
  }

  .marginfield-opt {
    margin-left: -36px;
  }

  .commentbox .mat-dialog-container {
    position: absolute;
    top: 123px;
    left: 949px;
  }

  .progress {
    margin-left: 19px !important;
    width: 98%;
  }

  .large-tc {
    padding-left: 33px !important;

  }

  .large-ln {
    padding-left: 49px !important;
  }

  .large-dp {
    margin-left: -30px !important;
  }

  .large-e {
    margin-left: 31px !important;
  }

  .large-st {
    margin-left: 117px !important;
  }

  .large-ll {
    margin-left: 73px !important;
  }

  .large-bt {
    text-align: end;
    margin-left: 74px !important;
  }

  .addmember .mat-dialog-container {
    left: 25%;
  }

  .adminPop .mat-dialog-container {
    left: 25%;
  }

  .addgroupstyle .mat-dialog-container {
    left: 20%;
  }


}

@media only screen and (min-width:1030px) and (max-width:1512px) {
  .choose-field {
    width: 54%;
  }
}

@media only screen and (min-width:1030px) and (max-width:1440px) {
  .divrow2outer {
    margin-left: -4%;
  }

  .search-icon-hierarchy {
    top: 53%;
  }

  /* .imageupload-width {
    width:42%!important;
  } */
  .past-margin {
    margin-left: -5% !important;
  }

  .margin-to-leftsmall {
    margin-left: -51px !important;
  }

  .d2outer {
    margin-left: -55px !important;
  }

  .plusbtn {
    text-align: left;
    padding-left: 12px !important;
  }

  .small-widthname {
    width: 21% !important;
  }

  .cons-message {
    margin-left: 335px;
  }

  .addquesform .mat-dialog-container {
    position: absolute;
    top: 17px !important;
    left: 254px !important;
    width: 929px !important;
  }

  .org_name_last {
    width: 100%;
  }
}

@media only screen and (min-width:924px) and (max-width:1108px) {
  .search-icon-hierarchy {
    top: 55% !important;
  }

  .box {
    width: 25% !important;
    margin-bottom: 4px;
  }

  .search-icon {
    top: 53%;
  }
}

@media only screen and (min-width:770px) and (max-width:850px) {
  .search-icon-gp {
    top: 53% !important;
  }

}

@media only screen and (min-width:770px) and (max-width:913px) {
  .managerwidth {
    width: 2% !important;
  }
}

@media only screen and (min-width:780px) and (max-width:946) {
  .search-icon-report {
    top: 77% !important;
  }

}

@media only screen and (min-width:768px) and (max-width:1024px) {
  .choose-field {
    width: 84%;
  }

  .uploadmar {
    margin-bottom: 20px;
  }

  .plusbtn {
    padding-left: 0px !important;
  }

  .firstrowpie1 {
    margin-left: -6% !important;
  }

  .d2outer {
    margin-left: -4%;
  }

  .box {
    width: 25% !important;
    margin-bottom: 4px;
  }

  .phone_style {
    width: 82px !important;
  }

  /* .imageupload-width {
    width:66%!important;
  } */
  .past-margin {
    margin-left: -48px !important;
  }

  .margin-to-leftsmall {
    margin-left: -6% !important;
  }

  .search-icon {
    top: 72%;
  }

  .btn-allign {
    text-align: end !important;
    padding-right: 7px !important;
  }

  .dispmsg {
    margin-left: 15px;
  }

  .o-user {
    padding-left: 0px !important;
  }

  .table-list {
    width: 16px !important;
    height: auto;
  }

  .t-user {
    padding-left: 21px !important;
  }

  .o-user {
    padding-left: 0px !important;
  }

  .b-user {
    margin-left: 0px !important;
  }

  .action-head {
    text-align: center;
    padding-left: 0px !important;
  }

  /* #search-blue-user {
    overflow: visible;
    position: absolute;
    width:17px;
    height: 24px;
    left: 400px;
    top: 15px;
    transform: matrix(1,0,0,1,0,0);
} */

  .small-widthname {
    width: 16.5% !important;
  }

  .small-width {
    width: 8.6% !important;
  }

  .cons-message {
    background-color: #e1f0f7;
    padding: 21px;
    width: auto !important;
    text-align: center;
    margin-top: 10px !important;
    margin-left: 0px !important;
    margin-bottom: 10px;
  }

  .addquesform .mat-dialog-container {
    position: absolute;
    top: 9px !important;
    left: 148px !important;
    width: 785px !important;
  }

  .drop-select-dashboard {
    width: 134px;
  }

  .startendDate {
    width: 151px;
  }

  .filterdiv {
    width: 100% !important;
    padding-top: 0px !important;
  }

  .number-feed2 {
    font-size: 23px !important;
  }

  .number-content2 {
    font-size: 12px;
  }

  .division {
    margin-right: 0px !important;
  }

  .search-icon-hierarchy {
    top: 75%;
  }

  .search-icon-gp {
    top: 54%;
  }

  .user-drop .ngx-select__search {
    width: 250px !important;
    margin-left: -3px;
  }

  .drop-select-indi {
    width: 250px;
  }

  .user-drop-con .ngx-select__toggle {
    width: 250px !important;
  }

  .drop-select-indi {
    width: 250px;
  }

  .user-drop .ngx-select__search {
    width: 250px !important;
  }

  .due-date {
    width: 250px;
  }

  .user-drop .ngx-select_multiple {
    width: 250px !important;
  }

  .drop-select-group {
    width: 245px;
  }

  .bluebtn-upload {
    font-size: 10px;
  }

  .upload-text-drop {
    top: 58% !important;
  }

}

@media only screen and (min-width:470px) and (max-width:668px) {
  .search-icon {
    top: 73% !important;
  }

  .assign-leftend {
    margin-left: 0px !important;
  }

}

@media only screen and (min-width:773px) and (max-width:805px) {

  .search-icon-manage {
    top: 73%;
  }

  .assign-leftend {
    margin-left: 0px !important;
  }


}

@media only screen and (min-width:930px) and (max-width:1268px) {

  .search-icon-report {
    top: 53%;
  }

  .assign-leftend {
    margin-left: 0px !important;
  }

}

@media only screen and (min-width:768px) and (max-width:1104px) {
  .choose-field {
    width: 84%;
  }

  .uploadmar {
    margin-bottom: 20px;
  }

  .plusbtn {
    padding-left: 0px !important;
  }

  .firstrowpie1 {
    margin-left: -6% !important;
  }

  .d2outer {
    margin-left: -4%;
  }
}

@media only screen and (min-width:769px) and (max-width:936px) {
  .box {
    width: 25% !important;
    margin-bottom: 4px;
  }

  .tab .nav-tabs li a {
    padding-right: 5px !important;
  }

  .search-icon-report {
    top: 73% !important;
  }

  .search-icon-mytodo {
    top: 77%;
  }

  .assign-leftend {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width:470px) and (max-width:768px) {
  .assign-leftend {
    margin-left: 0px !important;
  }

  .plusbtn {
    padding-left: 0px !important;
  }

  .firstrowpie1 {
    margin-left: -6% !important;
  }

  .tab .nav-tabs li a {
    padding-right: 5px !important;
  }

  .box {
    width: 31% !important;
    /* height: 56px; */
  }

  .search-icon-mytodo {
    top: 77%;
  }

  .expmypdf {
    margin-left: 14px !important;
  }

  /* .headerlogo {
    width:120px;
    height: 45px;
  } */

  .navbar-nav {
    margin: -39.5px 166px !important;
  }

  #header {
    height: 0px !important;
  }

  .box {
    width: 32% !important;
    height: 85px;
  }

  .drop-select-dashboard {
    width: 134px;
  }

  .startendDate {
    width: 151px;
  }

  .filterdiv {
    width: 100% !important;
    padding-top: 0px !important;
  }

  .number-feed2 {
    font-size: 17px !important;
  }

  .past-margin {
    margin-left: -7% !important;
  }

  .d2outer {
    margin-left: -8% !important;
  }

  .first2 {
    margin-right: -25px !important;
  }

  .margin-to-leftsmall {
    margin-left: -7% !important;
  }

  .divrow2outer {
    margin-left: -6% !important;
    margin-right: 46px !important;
  }

  .p-padding {
    padding-left: 22px !important;
  }

  .progress-dash {
    height: 21px !important;
  }

  .org_name_last {
    width: 100%;
  }

  /* .last-section{
    margin-top: 100px!important;
  } */
  .city-margin {
    margin-right: 8px !important;
  }

  .state-margin {
    margin-left: 0px;
  }

  .division {
    margin-right: 0px !important;
  }

  .search-icon {
    top: 69%;
  }

  .search-icon-hierarchy {
    top: 69%;
  }

  .namebox {
    width: 80%;
    /* margin-left: 63px; */
  }

  .search-icon-gp {
    top: 53%;
  }

  .search-icon-manage {
    top: 53%;
  }

  .edit {
    margin-right: 2px;
  }

  .btn-sm-user {
    padding: 0px 0px !important;
  }

  .selectedText {
    margin-left: 10px !important;
  }

  .head-mar {
    margin-right: 0px;
  }

  .fontclass {
    font-size: 10px !important;
  }

  .nummargin {
    margin-left: 28px !important;
  }

  .nme-space {
    padding-left: 0px;
  }

  .mreview {
    margin-left: 0px;
  }

  .cons-message {
    width: auto;
    margin-left: 0px !important;
  }

  .bluebtn {
    font-size: 10px !important;
  }

  .headerspace {
    padding: 0px 5px !important;
    margin-top: -2px;
    height: 58px;
    background-color: #FFFFFF;
    width: 171px;
  }

  .addquesform .mat-dialog-container {
    left: 81px !important;
    width: 86% !important;
    height: 566px !important;
    top: 15px !important;
  }

  .user-drop .ngx-select__search {
    width: 250px !important;
    margin-left: -3px;
  }

  .drop-select-indi {
    width: 250px;
  }

  .user-drop-con .ngx-select__toggle {
    width: 250px !important;
  }

  .search-icon-report {
    top: 73%;
  }

  .number-content2 {
    padding-left: 6px !important;
  }

  .padding-num-content {
    margin-bottom: 15px;
  }
}

/* media query for profile start*/
@media only screen and (min-width:1351px) {
  #Search {
    left: 362px !important;
  }


}

@media only screen and (min-width:2560px) {
  .deletePop .mat-dialog-container {
    left: 1023px;
  }

  .goaleditor>.angular-editor {
    width: 100% !important;
  }

}


@media only screen and (max-width:2560px) {

  .sidebar-mobile {
    display: none;
  }

  .sidebar-full {
    display: block;
  }

  #Search {
    left: 963px !important;
  }


  .toggle-nav {
    display: none;
  }

  #header .toggle-navigation.toggle-left {
    display: none;
  }


}

@media only screen and (max-width:1440px) {

  .sidebar-mobile {
    display: none;
  }

  .sidebar-full {
    display: block;
  }

  .goaleditor>.angular-editor {
    width: 100% !important;
  }

  #Search {
    left: 357px !important;
  }

  .toggle-nav {
    display: none;
  }

  #header .toggle-navigation.toggle-left {
    display: none;
  }



}

@media only screen and (min-width:1024px) {

  /* .phone_style {
    width:104px!important;
  }
  .ext_style {
    width:29px!important;
  } */
  .goaleditor>.angular-editor {
    width: 100% !important;
  }

  .user-drop-gedit .ngx-select__search {
    width: 102 !important;

  }

  .user-drop-gedit-goal .ngx-select__search {
    width: 102 !important;

  }

  .goal>.ngx-select_multiple {
    width: 100% !important;
  }

  .dueDatecon {
    width: 100.5;
  }

  .dueDate {
    width: 100%;

  }

  /* .addreviewdueDate{
    width:102%;
  } */

}

@media only screen and (max-width:1024px) {
  .phone_style {
    width: 82px !important;
  }

  .sidebar-mobile {
    display: none;
  }

  .goaleditor>.angular-editor {
    /* width:630px!important; */
    width: 100% !important;
  }

  /* .input-wid {
    width:630px;
  } */
  .user-drop-gedit .ngx-select__search {
    /* width:630px!important; */
    width: 100% !important;
  }

  .user-drop-gedit-goal .ngx-select__search {
    /* width:630px!important; */
    width: 100% !important;
  }

  .user-drop-review .ngx-select__search {
    /* width:769px; */
    width: 103% !important;
  }

  .addreviewdueDate {
    width: 102.5%;
  }

  .addadmins-goal>.ngx-select {
    /* width:630px!important; */
    width: 100% !important;
  }

  .dueDate {
    width: 100% !important;
    /* width:630px!important; */
  }

  .type-mar {
    margin-left: 0px !important;
  }

  .text-enable {
    padding-left: 0px !important;
  }

  .goal>.ngx-select_multiple {
    /* width:630px!important; */
    width: 100% !important;
  }

  .sidebar-full {
    display: block;
  }

  #Search {
    left: 201px !important;
  }

  .org_name {
    /* width:274px; */
    width: 100%;
  }

  .org_name_last {
    /* width:274px; */
    margin-top: 0px;

  }

  /* .org_name_edit { 
    width:263px;
  }*/
  /* .input-edit {
    width:237px!important;
  } */
  .org_name_edit_last {
    width: 263px;
  }

  .loctbl>thead>tr>td {
    padding: 6.7px !important;

  }

  .org_name_edit3 {
    margin-bottom: -15px;
  }

  .due-date-user {
    width: 100%
  }

  #UserDetails {
    top: 247px;
  }

  #hierarchysec {
    top: 548px;
    width: 113px;
  }

  #groups {
    top: 688px;
  }

  .tab .tab-content {
    font-size: 13px;
  }

  .radio #active {
    margin-left: -20px !important;
    margin-top: 30px;
  }

  .ac-labl {
    margin-left: 33px;
  }

  .checkbox label,
  .radio label {
    padding-left: 39px;
  }

  .inactive {
    margin-left: 27px !important;
  }

  .inac-labl {
    margin-left: 33px;
  }

  .file_textw {
    width: 114px;
  }

  .phone_std {
    width: 17px !important;
  }

  .phone_style {
    width: 80px !important;
  }

  .ext_style {
    width: 29px !important;
  }

  .activeedit {
    margin-top: 22px;
  }

  .inactiveedit {
    margin-left: 25px !important;
  }

  .inac-labledit {
    margin-left: 33px;
  }

  .user-drop-con .ngx-select__toggle {
    width: 245px !important;
  }

  .drop-select-indi {
    width: 245px !important;
  }

  .user-drop .ngx-select__search {
    width: 245px !important;
  }

  .due-date {
    width: 245px !important;
  }

  .user-drop .ngx-select_multiple {
    width: 245px !important;
  }

  .drop-select-group {
    width: 245px;
  }

  .sur-margin {
    margin-top: 0px;
  }

  .subheading {
    padding-top: 0px;
  }

  .actions-btn {
    margin-left: 0px !important;
    margin-top: 0px;
  }

}

@media only screen and (max-width:768px) {
  .mar-left-div {
    margin-left: 0px;
  }

  /* .addadmins-reviews .ngx-select__selected {
    width:429px!important;
  } */
  .t-user {
    padding-left: 0px !important;
  }

  .o-user {
    padding-left: 0px !important;
  }

  .b-user {
    margin-left: 0px !important;
  }

  .action-head {
    text-align: center;
    padding-left: 0px !important;
  }

  .edit {
    padding: none !important;
  }

  .box {
    width: 31% !important;
    height: auto !important;

  }

  .expmypdf {
    margin-left: 13px !important;
  }

  /* .search-icon {
    top: 81%;
  }  */
  .sidebar-mobile {
    display: none;
  }

  .sidebar-full {
    display: block;
  }

  #Search {
    left: 74px !important;
  }

  .hrscroll-table {
    overflow-x: auto;
    width: auto;
  }

  .division {
    margin-right: -9px;

  }

  .content-white {
    padding: 5px 5px 0px 5px;
  }

  .org_name {
    width: 100%;
  }

  .org_name_last {
    width: 100%;
  }

  .tab .tab-content {
    padding: 15px 0px 5px;
    /* padding: 23px 0px 5px; */
  }

  /* .last-section{
    padding-right: 3px !important;
    padding-left: 33px !important;

  } */
  .img-height {
    height: 217px;
    text-align: center;
  }

  .org_name_edit {
    /* position: absolute; */
    width: 230px;
  }

  /* .input-edit {
    width:202px!important;
  } */
  .org_name_edit_last {
    width: 230px;
  }

  #Id_right {
    left: 45px !important;
  }

  #zip {
    left: 45px !important;
  }

  #country {
    left: 45px !important;
  }

}

@media only screen and (max-width:425px) {
  .edit {
    padding: none !important;
  }

  .org {
    display: none;
    /* font-size: 18px; */
  }

  .sidebar-full {
    display: none;
  }

  .sidebar-mobile {
    display: block;
  }

  .sidebar-full {
    margin-top: 17px !important;
  }

  .location {
    margin-top: 37px;
  }

  .mat-dialog-actions {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 52px;
    align-items: center;
    margin-bottom: -24px;
    justify-content: space-evenly;
  }

  #Address {
    top: 70px;
    left: 25px;
  }

  .space-top-bottom {
    padding-bottom: 0px !important;
  }

  .organ-style {
    font-size: 17px;
    margin-left: 8px;
  }

  #Search_d {
    font-size: 11px;
    left: 98.5px;
    top: -43px;
  }

  .Path_94 {
    width: 53px;
    height: 11px;

  }

  .Line_28 {
    top: -22.5px;
    width: 114px;
    left: 95px;
  }

  #search {
    top: -40px;
    left: 176px !important
  }


  /* .sidebar-mobile:hover,nav.sidebar-full.expanded {
    width:55px !important;
    overflow:none;
    } */

  .sidebar-mobile {
    margin-top: 17px !important;
  }

  .head-logo {
    margin-right: -15px;
    margin-left: -28px !important;
  }

  .profile {
    margin-left: 133px !important;
  }

  #header .brand-mobile {
    background: url('../src/assets/img/logo_mobile.png') no-repeat !important;
    margin: 26px 0px 0px 1px !important;
  }

  .avatar {
    vertical-align: right !important;
    width: 47px !important;
    height: 45px !important;
  }

  .Ellipse_1 {
    left: 161px !important;

  }

  /* .navbar-nav {
      margin: 7.5px 194px!important;
  } */
  #navbarSupportedContent {
    display: none;
  }

  #header .toggle-navigation.toggle-left {
    display: block;
  }

  .left-width {
    margin-left: 29px;
  }

  /* .space-top-bottom {
    padding-bottom: 37px;
  } */
  #Search {
    top: -1px;
    left: 140px !important;
    width: 237px;
    height: 7.5px;
  }

  .tab .nav-tabs li a {
    margin-left: 0px !important;
    padding: 0px px 11px;
  }

  .tab .nav-tabs li a:hover,
  .tab .nav-tabs li.active a,
  .tab .nav-tabs li.active {
    margin-right: 0px;
  }

  .first-li {
    margin-right: 0px;
    border-bottom: 3px solid #e1e1e1;
  }

  #Group_75 {
    left: 6px;
  }

  /* #account_tree_black_24dp{
    left: 120px;
  } */
  .last-section {
    top: 94px;
    left: -17px;
  }

  .org_name_last {
    width: 233px;
  }

  .state-margin {
    margin-left: 0px;
    width: 231px;
  }

  #Id_right {
    left: 43px;
    top: -22px;
  }

  #contacts {
    margin-top: 110px;
    margin-bottom: 22px;
  }

  #zip {
    width: 156px;
    height: 29px;
    left: 41px;
    top: 61px;
  }

  #country {
    left: 42px;
    top: 134px;
    width: 77px;
  }

  .city-margin {
    margin-right: 12px;
  }

  #Name {
    top: -16px;
  }

  #Address {
    width: 79px;
  }

  .image_rect {
    margin-top: 16px;
  }

  /* header section */
  .toggle-nav {
    display: block;
    position: relative;
    left: 196px;
    top: -47px;
    margin-top: -16px;
    margin-left: 92px;
  }

  .navbar-collapse.in {
    margin-top: -24px;
    margin-left: 136px;
  }

  .content-section {
    margin-top: 17px;
  }
}

@media only screen and (max-width:375px) {
  .edit {
    padding: none !important;
  }

  .org {
    display: none;
    /* font-size: 18px; */
  }

  .location {
    margin-top: 37px;
  }

  #Address {
    top: 70px;
    left: 25px;
  }

  .space-top-bottom {
    padding-bottom: 0px !important;
  }

  .organ-style {
    font-size: 17px;
    margin-left: 8px;
  }

  #Search_d {
    font-size: 11px;
    left: 98.5px;
    top: -43px;
  }

  .Path_94 {
    width: 53px;
    height: 11px;

  }

  .Line_28 {
    top: -22.5px;
    width: 114px;
    left: 95px;
  }

  #search {
    top: -40px;
    left: 176px !important
  }

  .sidebar-full {
    display: none;
  }

  .sidebar-mobile {
    display: block;
  }

  /* .sidebar-mobile:hover,nav.sidebar-full.expanded {
    width:55px !important;
    overflow:none;
    } */
  .sidebar-mobile {
    margin-top: 17px !important;
  }

  .head-logo {
    margin-right: -15px;
    margin-left: -28px !important;
  }

  .profile {
    margin-left: 133px !important;
  }

  .location {
    margin-top: 37px;
  }

  #Address {
    top: 70px;
    left: 25px;
  }

  .space-top-bottom {
    padding-bottom: 0px !important;
  }

  .organ-style {
    font-size: 17px;
    margin-left: 8px;
  }

  #Search_d {
    font-size: 11px;
    left: 98.5px;
    top: -43px;
  }

  .Path_94 {
    width: 53px;
    height: 11px;

  }

  .Line_28 {
    top: -22.5px;
    width: 114px;
    left: 95px;
  }

  #search {
    top: -40px;
    left: 176px !important
  }

  .sidebar-full {
    display: none;
  }

  .sidebar-mobile {
    display: block;
  }

  /* .sidebar-mobile:hover,nav.sidebar-full.expanded {
    width:55px !important;
    overflow:none;
    } */
  .sidebar-mobile {
    margin-top: 17px !important;
  }

  .head-logo {
    margin-right: -15px;
    margin-left: -28px !important;
  }

  .profile {
    margin-left: 133px !important;
  }

  .sidebar-full {
    margin-top: 17px !important;
  }

  #navbarSupportedContent {
    display: none;
  }

  .toggle-left {
    display: block;
  }

  #Search {
    left: 54px !important;
  }

  #country {
    top: 143px;
  }

  .toggle-nav {
    display: block;
    position: relative;
    left: 196px;
    top: -47px;
    margin-top: -16px;
    margin-left: 92px;
  }

  .navbar-collapse.in {
    margin-top: -24px;
    margin-left: 97px;
  }

  .content-section {
    margin-top: 17px;
  }
}

@media only screen and (max-width:320px) {
  .edit {
    padding: none !important;
  }

  .location {
    margin-top: 37px;
  }

  #Address {
    top: 70px;
    left: 25px;
  }

  .space-top-bottom {
    padding-bottom: 0px !important;
  }

  .organ-style {
    font-size: 17px;
    margin-left: 8px;
  }

  #Search_d {
    font-size: 11px;
    left: 98.5px;
    top: -43px;
  }

  .Path_94 {
    width: 53px;
    height: 11px;

  }

  .Line_28 {
    top: -22.5px;
    width: 114px;
    left: 95px;
  }

  #search {
    top: -40px;
    left: 176px !important
  }

  .sidebar-full {
    display: none;
  }

  .sidebar-mobile {
    display: block;
  }

  .sidebar-mobile:hover,
  nav.sidebar-full.expanded {
    width: 55px !important;
    overflow: none;
  }

  .sidebar-mobile {
    margin-top: 17px !important;
  }

  .head-logo {
    margin-right: -15px;
    margin-left: -28px !important;
  }

  .profile {
    margin-left: 133px !important;
  }

  .navbar-nav .open .dropdown-menu {
    margin-left: -61px !important;
  }

  .toggle-nav {
    display: block;
    position: relative;
    left: 150px;
    top: -47px;
  }

  .navbar-collapse.in {
    margin-top: -16px;
    margin-left: 92px;
  }

  .content-section {
    margin-top: 17px;
  }

  /* #Search{
    left: 30px!important;
  } */
  #Group_75 {
    left: 8px;
  }

  #account_tree_black_24dp {
    left: 2px;
  }

  .first-li {
    border-bottom: 3px solid #e1e1e1;
  }

  .org {
    display: none;
    /* font-size: 18px; */
  }

  .head {
    padding-top: 5px;
  }

  /* .navbar-collapse.in {
    overflow-y: none!important;
  }
  .navbar-collapse {
    padding-left: opx!important;
  } */
}



/* media query for profile end*/


html,
body {
  height: 100%;
}

body {
  margin: 0;

  color: #000000 !important;
  /* font-family: Roboto, "Helvetica Neue", sans-serif;  */
}



/* new side style */

.fa-2x {
  font-size: 2em;
}

.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}


.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  overflow: visible;
}

.main-menu {
  background: darkblue;
  border-right: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 60px;
  overflow: hidden;
  -webkit-transition: width .05s linear;
  transition: width .05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.main-menu>ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li>a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all .1s linear;
  transition: all .1s linear;

}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
  font-family: 'Titillium Web', sans-serif;
}

.main-menu>ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.login-align {
  text-align: center
}

.logospaces {
  width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  ;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.main-menu li:hover>a,
nav.main-menu li.active>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #5fa2db;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url(https://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
}


.dropdown-inputbox .ngx-select__search {
  /* width:285px!important; */
  width: 196px !important;
  /* height: 33px !important; */
  border-color: #cbc3c3 !important;
  background-color: white !important;
  border-radius: 7px !important;
  font-size: 13px !important;
}

.dropdown-inputbox .ngx-select__choices {
  width: 100% !important;
  height: auto;
  max-height: 200px;
  min-width: 196px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0;
  position: absolute;
}

.dropdown-inputbox .ngx-select__toggle {
  outline: 0;
  position: relative;
  text-align: left !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  height: 33px !important;
  border: 1px solid #7a7a7a54 !important;
  /* height: 15px!important; */
  padding-top: 7px;
  border-radius: 7px;
  width: 196px !important;
  margin-right: 6px;
  justify-content: space-between;
}

.dropdown-inputbox .show {
  display: inline-block !important;
}

.dropdown-inputbox .form-control {
  min-height: 33px !important;
  outline: 0;
  position: relative;
  text-align: left !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  border: 1px solid #7a7a7a54 !important;
  /* height: 15px!important; */
  padding-top: 7px;
  border-radius: 7px;
  width: 196px !important;
  margin-right: 6px;
  margin-top: 5px;
  justify-content: space-between;
}

.dropdown-orgprofile .form-control {
  line-height: 20px;
  width: 100%;
  padding: 5px 20px 5px 10px;
  min-height: 33px !important;
  font-size: 15px !important;
  outline: 0;
  position: relative;
  text-align: left !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  border: 1px solid #03080A !important;
  /* padding-top: 7px; */
  width: 100% !important;
  /* margin-right: 6px; */
  border-radius: 0px !important;
  justify-content: space-between;
}

.dropdown-orgprofile>.ngx-select {
  width: 100% !important;
}

.dropdown-inputbox-review .form-control {
  min-height: 33px !important;
  outline: 0;
  position: relative;
  text-align: left !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  border: 1px solid #7a7a7a54 !important;
  /* height: 15px!important; */
  padding-top: 7px;
  border-radius: 7px;
  width: 196px !important;
  margin-right: 6px;
  margin-top: 4px;
  justify-content: space-between;
}

.dropdown-inputbox-reviews .form-control {
  min-height: 33px !important;
  outline: 0;
  position: relative;
  text-align: left !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  border: 1px solid #7a7a7a54 !important;
  /* height: 15px!important; */
  padding-top: 7px;
  border-radius: 7px;
  width: 196px !important;
  margin-right: 6px;
  margin-top: 4px;
  justify-content: space-between;
}

.dropdown-inputbox-review .ngx-select__search {
  /* width:285px!important; */
  width: 196px !important;
  /* height: 33px !important; */
  border-color: #cbc3c3 !important;
  background-color: white !important;
  border-radius: 7px !important;
  font-size: 13px !important;
}


.dropdown-inputbox-reviews .ngx-select__search {
  /* width:285px!important; */
  width: 250px !important;
  /* height: 33px !important; */
  border-color: #cbc3c3 !important;
  background-color: white !important;
  border-radius: 7px !important;
  font-size: 13px !important;
}

.dropdown-inputbox-review .ngx-select__choices {
  width: 100% !important;
  height: auto;
  max-height: 200px;
  min-width: 196px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0;
  position: absolute;
}



.dropdown-inputbox-reviews .ngx-select__choices {
  width: 100% !important;
  height: auto;
  max-height: 200px;
  min-width: 196px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0;
  position: absolute;
}

.dropdown-inputbox-reviews .ngx-select__toggle {
  outline: 0;
  position: relative;
  text-align: left !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  height: 33px !important;
  border: 1px solid #7a7a7a54 !important;
  /* height: 15px!important; */
  padding-top: 5px;
  border-radius: 7px;
  width: 250px !important;
  margin-top: 1px;
  margin-right: 6px;
  justify-content: space-between;
}

.dropdown-inputbox-review .ngx-select__toggle {
  outline: 0;
  position: relative;
  text-align: left !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  height: 33px !important;
  border: 1px solid #7a7a7a54 !important;
  /* height: 15px!important; */
  padding-top: 7px;
  border-radius: 7px;
  width: 196px !important;
  margin-right: 6px;
  justify-content: space-between;
}

.dropdown-inputbox-review .show {
  display: inline-block !important;
}


.common-lebel {
  font-weight: 400;
  font-size: 13px !important;

}

.filter-apply-btn {
  background-color: rgba(242, 113, 39, 1) !important;
  border-color: rgba(242, 113, 39, 1) !important;
}

@media only screen and (min-width:1114px) and (max-width:1400px) {

  .dropdown-inputbox .ngx-select__search {
    width: 153px !important;
  }

  .dropdown-inputbox .ngx-select__choices {
    width: 100% !important;
    min-width: 153px;
  }

  .dropdown-inputbox .ngx-select__toggle {
    width: 153px !important;
  }

  .dropdown-inputbox .form-control {
    width: 153px !important;
  }

  .search-box-input {
    width: 153px !important;
  }
}

@media only screen and (min-width:1000px) and (max-width:1114px) {

  .dropdown-inputbox .ngx-select__search {
    width: 131px !important;
  }

  .dropdown-inputbox .ngx-select__choices {
    width: 100% !important;
    min-width: 131px;
  }

  .dropdown-inputbox .ngx-select__toggle {
    width: 131px !important;
  }

  .dropdown-inputbox .form-control {
    width: 131px !important;
  }

  .search-box-input {
    width: 131px !important;
  }
}

@media only screen and (min-width:800px) and (max-width:1000px) {

  .dropdown-inputbox .ngx-select__search {
    width: 102px !important;
  }

  .dropdown-inputbox .ngx-select__choices {
    width: 100% !important;
    min-width: 102px;
  }

  .dropdown-inputbox .ngx-select__toggle {
    width: 102px !important;
  }

  .dropdown-inputbox .form-control {
    width: 102px !important;
  }

  .search-box-input {
    width: 102px !important;
  }
}

@media only screen and (min-width:768px) and (max-width:800px) {

  .dropdown-inputbox .ngx-select__search {
    width: 93px !important;
  }

  .dropdown-inputbox .ngx-select__choices {
    width: 100% !important;
    min-width: 93px;
  }

  .dropdown-inputbox .ngx-select__toggle {
    width: 93px !important;
  }

  .dropdown-inputbox .form-control {
    width: 93px !important;
  }

  .search-box-input {
    width: 93px !important;
  }
}


.login-para {
  padding-top: 2.5%;
  font-size: 15px !important;
  padding-left: 8%;
  padding-right: 8%;
  color: #ffff;
}

/* new side style ends */
.login-link {
  /* display: inline-block !important; */
  font-size: 14px !important;
  padding-top: 6%;
  color: white !important;
  text-align: center;
  text-decoration: underline !important;
  cursor: pointer;
}

.login-link-div {
  text-align: center;
}

.left-width-style {
  margin-left: 0px;
  margin-top: 70px;
}

.mat-table {
  width: 100%;
  background: #fff;
}

.loading-gif-main {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 60px;
}

.loading-gif-container {
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinnerloader {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  opacity: .8;
  min-height: 100%;
  top: 54px;
  /* left: 63px; */
}




spinnerloaderr-dialog
{
  position: fixed;
    height: 71%;
    width: 83%;
    z-index: 100;
    background-color: #fff;
    opacity: .8;
    /* min-height: 100%; */
    top: 47px;
    left: 173px;
}

.spinnerloader-full {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  opacity: .8;
  min-height: 100%;
  top: 0px;
  left: 0px;
  /* top: 54px;
  left: 63px; */
}

.dot-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cancel-bg-button {
  background-color: #D9D9D9 !important;

}

.goback-bg-button {
  background-color: #898989 !important;
  color: white;
}

.goback-bg-button:hover {
  background-color: #898989 !important;
  color: white !important;
}

.btn-padding {
  padding: 5px 12px !important;
}



/* MAT-SLIDE-TOGGLE CSS CHANGE STARTS HERE */

.slide-toggle-wrap .mat-slide-toggle:not(.mat-checked) .mat-slide-toggle-thumb {
  background-color: #6992f9;
}

.slide-toggle-wrap .mat-slide-toggle:not(.mat-checked) .mat-slide-toggle-bar {
  background-color: #f2e0ff;
}

.slide-toggle-wrap .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}

.slide-toggle-wrap .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #e7c6c6;
}

/* MAT-SLIDE-TOGGLE CSS CHANGE ENDS HERE */

.type-badge{
  padding: 2px 18px;
  font-size: 12px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 0.1px;
  width: 85px;
}


.type-standard{
  background-color: #6153cc !important;
  color: #fff !important;
  /* background-color: #ffebc0 !important;
  color: #8f6200; */
}

.type-annual{
background-color: #ca5010 !important;
color: #fff !important;
/* background-color: #e5d2e3 !important;
color: #86417b; */
}

.type-onboard{
  background-color: #3185fc !important;
  color: #fff !important;
  /* background-color: #caf0cc !important;
  color: #437406; */
}

.type-checkin{
  background-color: #1b998b !important;
  color: #fff !important;
  /* background-color: #fed5d1 !important;
  color: #A0410D !important; */
}
.type-360{
background-color: #004e8c;
color: #fff;
/* background-color: #c3caf9 !important;
color: #183ee7; */
}

.cycle-standard-badge {

  background-color: #66BD501A !important;
  color: var(--RC-reopen-color) !important;

}

.cycle-annual-badge {
  padding: 2px 18px;
  background-color: #F86F6F1A !important;
  color: var(--RC-bg-red) !important;
  font-size: 12px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 85px;
}

.cycle-onboard-badge {
  padding: 2px 18px;
  background-color: #EBBC461A !important;
  color: var(--RC-copy-icon-color) !important;
  font-size: 12px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  width: 85px;
  font-weight: bold;
}

.cycle-360-badge {
  padding: 2px 18px;
  background-color: #6992F91A !important;
  color: var(--RC-edit-color) !important;
  font-size: 12px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  width: 85px;
  font-weight: bold;
}

.cycle-checkin-badge {
  padding: 2px 18px;
  background-color: #879cb32c !important;
  color: #879CB3 !important;
  font-size: 12px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 85px;
}

.cycle-360-assign-badge {
  padding: 2px 18px;
  background-color: #dc3545 !important;
  color: white !important;
  font-size: 11px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 191px !important;
}

.cycle-360-assign-inprogress-badge {
  padding: 2px 18px;
  background-color: #A7BCFA !important;
  color: black !important;
  font-size: 11px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 191px !important;
}


.cycle-360-assign-completed-badge {
  padding: 2px 18px;
  background-color: #198b09 !important;
  color: white !important;
  font-size: 11px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 191px !important;
}

.cycle-360-approval-pending-badge {
  padding: 2px 18px;
  background-color: #ffc107 !important;
  color: #000000 !important;
  font-size: 11px;
  text-align: center !important;
  border-radius: 27px;

  display: inline-block;
  font-weight: bold;
  width: 191px !important;
}

.cycle-360-approval-inprogress-badge {
  padding: 2px 18px;
  background-color: #A7BCFA !important;
  color: #000000 !important;
  font-size: 11px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 191px !important;
}

.cycle-360-completed-badge {
  padding: 2px 18px;
  background-color: #198b09 !important;
  color: #000000 !important;
  font-size: 11px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 191px !important;
}

.cycle-360-rejected-badge {
  padding: 2px 18px;
  background-color: #E15634 !important;
  color: #000000 !important;
  font-size: 11px;
  text-align: center !important;
  border-radius: 27px;
  margin-top: -3px;
  display: inline-block;
  font-weight: bold;
  width: 191px !important;
}

.review-textarea {
  width: 100%;
  height: 142px;
  border-radius: 6px;
  padding-left: 12px;
}

.label-color {
  color:   #3F3F3F;
}

.review-textarea {
  width: 100%;
  height: 150px;
  border-radius: 6px;
  padding-left: 12px;
}

/* .ng-select
{
min-width:200px;
} */

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  border: 3.5px solid transparent;
  border-top: 3.5px dotted #663399;
  border-right: 3.5px dotted #e74c3c;
  border-radius: 50%;
  animation: spin 1.5s linear infinite, switchBorders 1.5s linear infinite;
}

@keyframes switchBorders {
  0% {
    border-top: 3.5px dotted #663399;
    border-right: 3.5px dashed #e74c3c;
  }
  50% {
    border-top: 3.5px dashed #663399;
    border-right: 3.5px dotted #e74c3c;
  }
  100% {
    border-top: 3.5px dotted #663399;
    border-right: 3.5px dashed #e74c3c;
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.05);
    opacity: 0.9;
  }
}

.loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32.5px;
  height: 32.5px;
  opacity: 0.8;
  /* Replace with your icon URL */
  background-image: url(./assets/img/logo/trainey_logo.png);
  background-size: contain !important;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

/* .loader:before,
.loader:after {
  content: '';
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  border-radius: 50%;
  color: #418cc9;
  background: currentColor;
  box-shadow: 50px 0, -50px 0;
  animation: left 1s infinite ease-in-out;
}

.loader:after {
  color: #6466aa;
  animation: right 1.1s infinite ease-in-out;
} */


@keyframes right {

  0%,
  100% {
    transform: translateY(-10px)
  }

  50% {
    transform: translateY(10px)
  }
}

@keyframes left {

  0%,
  100% {
    transform: translateY(10px)
  }

  50% {
    transform: translateY(-10px)
  }
}


.spinnerSecondaryLoader {
  margin-left: 40%;
  margin-top: 3%;
}
.header-title-sub {
  text-transform: lowercase;
  font-size: 19px;
}