

.content-box
{
    margin: 12px;
    margin-top: 0px;
}
.header-main
{
    margin-left: 17px;
}
.header-secondary
{
    margin-left: 17px;
   
}
/* .collection-box
{ 
   border: #333 2px solid;
    background-color: #EEF5F9;
    padding: 12px;
} */
.accordion-box
{   
  border: 1px solid black;
  /* border-radius: 4px; */
}
.collection-accordion {
    border-radius: 10px;
   display: block;
    max-width: 100%;
   
  }
  
  .example-accordion-item {
    display: block;
    /* border: solid 1px white; */
  }
  .orangeadd {
    padding-top: 11px;
    font-size: 10px!important;
    background-color: rgba(243, 93, 7, 0.959);
    color: white!important;
    margin: -6px;
}
.competencyCancel {
  padding-top: 11px;
  font-size: 10px!important;
 
  color: rgb(0, 0, 0)!important;
  margin: -6px;
}
  .scroll {
    margin-top: -7px!important;
    margin-bottom: 7px;
    overflow: scroll;
    height: 215px!important;
    text-align:justify;
  }
  .table-content
  {
    padding-top: 8px;
    padding-left: 12px;
    width: 25%;
  }
  .bluesabe{
    padding: 3px 8px!important;
    background-color: #295BDC !important;
    color:white!important;
  }
  .cancelcompetency{
    padding: 3px 8px!important;
    font-size: 10px!important; 
    margin-right: 3px;
    box-shadow: 0px 2px 7px rgb(0 0 0 / 12%);
    background-color: #6c757d !important;
    color: white !important;
  }
  .collapsebtn{
    width:20px!important;
  }
  .comdescripion{
    color:   #3F3F3F;
    margin-bottom: 0px!important;
    font-size: 14px!important;
    /* margin-top: 7px; */
  }
  .elementsbg{
    width: 100%;
    background-color: #eee7eb78;
    padding: 5px;
    margin-bottom: 7px;
    margin-left: 0px!important;
    margin-right: 0px!important;
    /* padding-left: 10px; */
  }
  .droplist{
    background-color: #eee7eb78;
    padding: 5px;
    padding-left: 10px!important;
    margin-bottom: 7px;
    margin-left: 7px!important;
    margin-right: 0px!important;
    width: 100%;
  }

  .search-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

  .searchValuecompetency {
    border-radius: 4px;
    border: none;
    width: 100%!important;
    padding: 4px;
    padding-left: 10px;
    color: rgba(41,91,220,1)!important;
    box-shadow: -1px 3px 9px rgb(0 0 0 / 12%);
    background-color: white!important;
    margin-top: 6px;
    margin-bottom: 0px;
  }
  .check-com{
    margin-top: -7px!important;
  }
  .add-head-com{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .search-icon-competency {
    position: absolute;
    top: 16%;
    right: 33px;
    margin-top: -10px;
    color: rgb(95 96 101)!important;
  }
  .com-head{
    position:absolute; top:0px; left:0px; height:200px; right:0px;overflow:hidden;
  }
  .com-footer{
    position: absolute;
    bottom: 0px;
    height: 40px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    margin: 10px;
    padding: 10px;
    margin-right: 2px;
    background-color: white;
  }
  .margincompetency{
    margin-top: -10px;
  }
  .blueadd{
    padding-top: 11px;
    font-size: 10px!important;
    background-color: #295BDC !important;
    color:white!important;
    margin: -6px;
  }
  .cancelsec{
    font-size: 10px!important; 
    margin-right: 3px!important; 
    color: black;
    margin: -6px;
    box-shadow: 0px 2px 7px rgb(0 0 0 / 42%);

  }
  .rightborder
{
  box-shadow: -1px 0 6px #0000007a !important;
  margin-left: -3px!important;
  max-height: 450px !important;
  
}

.scrolls {
  margin-bottom: 7px;
  overflow: scroll;
  max-height: 307px !important;
  min-height: 307px !important;
  height: 307px;
  text-align: justify;
}
  .innerdiv{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .addnewelement{
    font-size: 14px!important;
    color:rgba(243, 93, 7, 0.959);
    padding-top: 10px;
  }
  .competencyscroll{
    overflow-y: scroll;
    padding-right: 18px;
  }
  .addnewelementhere{
    font-size: 14px!important;
    color:rgb(104, 102, 100);
    margin-top: 10px;
    margin-bottom: -15px;
  }
  .leftpaddbtn{
    padding-left: 30px;
  }
  .dropdown-element{
    margin-left: 5px;
  }
  .attribute-color{
    background-color: #E9BAAC!important;
    color: #d83c0d!important;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 18px;
    float:right;
}
.attributee-color{
  background-color: #E9BAAC!important;
  color: #d83c0d!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
 
}
.goal-color{
  background-color: #A1F8F3!important;
  color: #04948d!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}
.quest-color{
  background-color: #A7BCFA!important;
  color: #2b48a0!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}
.quest-color-small{
  background-color: var(--RC-btn-save-secondary-background)!important;
  color: var(--RC-white-color)!important;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 5px 6px;
  padding-bottom: 5px!important;
}
.attribute-color-small{
  background-color: var(--RC-btn-save-secondary-background)!important;
  color: var(--RC-white-color)!important;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 5px 6px;
  padding-bottom: 5px!important;
}
.goal-color-small{
  background-color: #A1F8F3!important;
  color: #04948d!important;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 5px 6px;
  padding-bottom: 5px!important;
}
.element-drop-list {
  padding: 2px;
  border-radius: 8px;
  width: 25px;
}

  .table-button
  {
    /* padding: 2px; */
    padding-top: 5px;
    width: 5%;
  }
  /* .table-button button
  {
    height: 30px !important;
    padding: none !important;
    color: yellowgreen;
    margin: 1px !important;
  } */

  /* .example-accordion-item  {
  border: 1px solid black;
  border-radius: 4px;
  margin: 10px;
  } */
  
  .example-accordion-item-header {
    background-color:#EEF5F9;
    margin-bottom: 5px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    height: 45px;
  }
  
  .example-accordion-item-description {
    font-size: 0.85em;
    color: #999;
  }
  
  .example-accordion-item-body {
    height: auto;
    margin: 10px;
  }
  /* .example-accordion-item-header,
  .example-accordion-item-body {
    padding: 6px;
  }
   */
  .example-accordion-item-header:hover {
    cursor: pointer;
    background-color: #eee;
  } 
  
   .example-accordion-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .example-accordion-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  } 
  #settings
  {
    /* color: #eee;
    background-color: #333; */
    float: right;
    /* padding: 4px; */
    font-size: larger;
    /* border-radius: 2px; */
    /* margin: 2px; */
  }
  #visibility
  {
    color: #eee;
    background-color: rgb(19, 78, 189);
    float: right;
    padding: 4px;
    font-size: larger;
    border-radius: 2px;
    margin: 2px;
  } 


 .example-container {
    /* width: 400px; */
    width: 100%;
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;
  }
  
  .example-list-comp {
    /* border: solid 1px #ccc; */
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
  }
  
  .example-box-comp {
    padding: 5px 0px;
    margin-top: 5px;
    margin-bottom: -12px;
    /* border-bottom: solid 1px #ccc; */
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
  }
  
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .cdk-drag-placeholder {
    opacity: 0;
  }
  
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .example-box-comp:last-child {
    border: none;
  }
  
  .example-list-comp.cdk-drop-list-dragging .example-box-comp:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  } 
  .competency-table{
    background-color: #eef5f9;
    border:1px solid #c2bbbb;
    border-style:solid;
    border-width:medium;
    opacity: 0.7;
    fill: #EEF5F9;
    stroke: #707070;
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    border-radius: 4px;
    border-collapse: separate !important;
  }
  .searchuser-bar{
    text-align: end;
  
  }
  .mat-raised-button 
  {
    width: 10px !important;
    
  }
  .searchValueElement {
    border-radius: 7px;
    border: none;
    width: 215px!important;
    height: 31px;
    padding: 3px;
    padding-top: 2px;
    padding-left: 10px;
    color: rgba(41,91,220,1)!important;
    background-color: rgba(240,240,240,1)!important;
    margin-top: 6px;
  }

.formElement-input
{
  padding: 3px;
  width: 100%;
  height: 26px;
}

.formElement-input:focus
{
  border:2px solid black;
}
.filter-reset
{
  background-color: #333;
  color: white;
  padding: 3px;
  border-radius: 6px;
  margin-top: 4px;
  margin-right: 5px;
}
.horizontal-overflow-table
{
  overflow: auto;
  white-space: nowrap;
}
.ui-autocomplete-input {
  margin: 1px !important;
 width: 100%;
  height: 142px;
  overflow: auto;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
   background: none repeat scroll 0 0 #FFFFFF;
  border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  /* border-style: none solid solid none;
  border-width: medium 1px 1px medium; */
  border:1px solid #b4bcc3; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: #555555;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding:14px;
  transition: background-color 0.2s ease 0s;
}

.ui-autocomplete-inputs {
  margin: 1px !important;
 width: 100%;
  height: 142px;
  overflow: auto;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
   background: none repeat scroll 0 0 #FFFFFF;
  border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  /* border-style: none solid solid none;
  border-width: medium 1px 1px medium; */
  border:1px solid #b4bcc3; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: #555555;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding:14px;
  transition: background-color 0.2s ease 0s;
}
/* .selfReview-table
{
  width:100%;
  border-bottom: #707070 1px solid;
  
}
#review-header-col
{
  padding:20px;
} */
/* .selfreview-table
{
  width:100%;
  
} */

.review-rating
{
    background-color: white;
    margin-left: -7px;
}
.reviewtitle
{
  font-weight: 900;
}
.attribute-style
{
  margin-bottom:12px !important;
}

.comment-box
{
  cursor: pointer;
  margin-left: 1px;
  display: block;
}
.review-col-header
{
margin-bottom: 1px;
}
.col-employee
{
padding-left: 0px;
padding-right: 5px;
}
.col-manager
{
padding-right: 14px;
}
.col-emp-mgr
{
padding-bottom:14px;
}
.filts {
  background-color: #295BDC;
  padding: 3px 5px 8px 5px;
  margin-right: 5px;
  border-radius: 4px;
  font-size: 13px;
  border: none;
  cursor: pointer;
  border-spacing: 0px;
}
.appfilters{
  position: relative;
  top:11px;
}
.dottex {
  overflow: hidden !important;
  max-width: 330px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.dottexts {
  overflow: hidden !important;
  /* max-width: 150px; */
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.dottexts-competency {
  overflow: hidden !important;
  max-width: 400px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.sort-field-arrow{
  height: 18px;
}
.example-list-com
{
  padding-left: 15px;
  padding-right: 15px;
}
.internal {
  text-align: center;
  width: 20px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  border-radius: 4px;
  padding: 0px 4px;
  padding-bottom: 5px!important;
  background: rgb(91 0 255 / 41%);
  color: rgb(101 0 253 / 87%);
  display: inline-block;
}
/* .external
{
  float: left;
  background: tomato;
  width: 20px;
  text-align: center;
  font-weight: 900;
  border-radius: 3px;
} */



::ng-deep .dropdown-inputbox-filter .ngx-select__search {
  /* width: 285px!important; */
  width: 100% !important;
  /* height: 33px !important; */
  border-color: #cbc3c3 !important;
  background-color: white !important;
  border-radius: 7px !important;
  font-size: 13px !important;
  max-width: 189px;
}  

::ng-deep .dropdown-inputbox-filter .ngx-select__choices {
    width: 100% !important;
    height: auto;
    max-height: 200px;
    min-width: 189px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 0;
    position: absolute;
}

::ng-deep .dropdown-inputbox-filter .ngx-select__toggle {
  outline: 0;
  position: relative;
  text-align: left!important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  height: 33px !important;
  border: 1px solid #7a7a7a54!important;
  /* height: 15px!important; */
  padding-top: 7px;
  border-radius: 7px;
  width: 100% !important;
  max-width: 189px;
  margin-right: 6px;
  justify-content: space-between;
}

::ng-deep .dropdown-inputbox-filter .form-control {
  min-height: 33px !important;
  outline: 0;
  position: relative;
  text-align: left!important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  border: 1px solid #7a7a7a54!important;
  /* height: 15px!important; */
  padding-top: 7px;
  border-radius: 7px;
  width: 100% !important;
  margin-right: 6px;
  max-width: 189px;
  justify-content: space-between;
}

.dottexts-competency-elements
{
  overflow: hidden;
  max-width: 401px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.count-search-container {
  display: flex;
    align-items: baseline;
    justify-content: space-between;
}


.search-div-alignment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


@media only screen and (min-width:768px) and (max-width:1400px){
  .dottexts-competency-elements
  {
    overflow: hidden;
    max-width: 230px !important;
      white-space: nowrap;
      text-overflow: ellipsis;
  }

}



@media only screen and (min-width:1884px) and (max-width:2650px){
  .dottexts-competency-elements
  {
    overflow: hidden;
    max-width: 685px !important;
      white-space: nowrap;
      text-overflow: ellipsis;
  }

  
}