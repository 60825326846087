
.upload-btn-wrapper {
    margin-top: 12px;
    position: relative;
    float: right;
     overflow: hidden;
     padding-bottom: 10px;
     margin-bottom: 12px !important;
   }

   
   .upload-btn-wrapper input[type=file] {
     font-size: 18px;
     position: absolute;
     bottom: 27%;
     opacity: 0;
   
   }
   .selfreview-table
   {
     width:75%;
     
   }


   .review-user
   {
    color: #474646;
     /* text-align: right; */
     font-size: 18px;
     text-decoration: underline;
     font-weight: 700;
     margin-bottom: 12px;
   }

   @media screen and (min-width:768px) and (max-width:1024px){
     .selfreview-table
     {
       width:100%;
       
     }
    }
   .selfreview-col
   {
     padding-top:12px;
     padding-bottom:12px;
   }
   p
   {
     font-size: 12px !important;
   }
   .multiselect {
     width: 200px;
   }
   
   .selectBox {
     position: relative;
   }
   
   .selectBox select {
     width: 100%;
     font-weight: bold;
   }
   
   .overSelect {
     position: absolute;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
   }
   
   #checkboxes {
     display: none;
     border: 1px #dadada solid;
   }
   
   #checkboxes label {
     display: block;
   }
   
   #checkboxes label:hover {
     background-color: #1e90ff;
   }
   .attribute-style
   {
     margin-bottom:12px !important;
   }
   .attribute-style .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
     padding-bottom: 1px!important;
   }
   
   
   
     .comment-box
     {
       cursor: pointer;
       margin-left: 1px;
       display: block;
     }
   .review-col-header
   {
     margin-bottom: 1px;
   }
   .col-employee
   {
     padding-left: 0px;
     padding-right: 5px;
   }
   .col-manager
   {
     padding-right: 14px;
   }
   .col-emp-mgr
   {
     padding-bottom:14px;
   }
   
   .file-delete-span
   {
     float: right;
     cursor: pointer;
   }
   .file-upload
   {
     padding-top: 12px;
   }
   
   
   
   /* .bg-blue
   {
     background-color: #295BDC !important;
     color: white !important;
   } */

   .bg-linked-reviews
   {
     background-color: #372549 !important;
     color: white !important;
   }

   .review-row-white
   {
     background-color: white;
     margin-top:12px;
   }
   
   .review-competency-row {
    background-color: #EEF5F9;
    /* padding: 10px; */
    margin-bottom: 20px !important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    border: 1px solid #b4bcc3;
    padding: 0;
    border-radius: 4px;
   }
   .review-competency-content
   {
     padding-left: 25px;
   }
   .review-emp-col
{
  padding-left: 25px;
  padding: 5px 6px 5px 25px;
  height:auto;
  /* overflow: overlay; */
  border: 1px solid #b4bcc3;
  border-radius: 6px;
  margin-bottom: 7px;
  margin-top: 7px;
}
.review-emp-col textarea
{
  width: 100%;
  height: 20px;
  border: none;
  overflow: auto;
}

.review-mgr-col
{
  padding-left: 25px;
  border:1px solid #b4bcc3;
  /* border-right: 2px solid;
  border-bottom: 2px solid; */
}
.comp-padding
{
  padding-bottom:12px;
  padding-left: 0px !important;
  padding-right:0px !important;
}
.cancel-review-btn{
  margin-right: 15px;
  border: none!important;
  border-radius: 4px!important;
  height: 32px;
  font-size: 13px!important;
}
.review-select .ng-dropdown-panel.ng-select-top
{
  top: 100%!important;
  
}
.review-select .ng-dropdown-panel .scroll-host
{
  text-align: left;
}
.review-select .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child
{
  display: none;
}
.review-select .ng-select .ng-clear-wrapper
{
  display: none;
}


.review-selects .ng-dropdown-panel.ng-select-top
{
  top: 100%!important;
  
}
.review-selects .ng-dropdown-panel .scroll-host
{
  text-align: left;
}
/* .review-selects .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child
{
  display: none;
} */
.review-selects .ng-select .ng-clear-wrapper
{
  display: none;
}
.common-files-header
{
margin: 0;
font-size: 14px;
color:   #3F3F3F;
}
.modal-progress {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}


.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)} 
  to {-webkit-transform: scale(1)}
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

.modal-progress-container
{
  position: absolute;
  top:50%;
  left:50%;
}
.review-row-white
{
  margin-left:0px !important;
  margin-right: 0px !important;
}
.btn-new-review-small {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  background-color: #F58029;
  padding: 0px 18px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 10px;
}
.btn-new-Org-small{
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  background-color: #F58029;
  padding: 4px 30px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  /* vertical-align: super; */
}
.bg-orange-btn
{
  background-color: #F37028 !important;
}

.review-main
{
  border: 1px solid #cdcdcd;
 
  padding-top: 12px!important;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 3px 6px rgb(0 0 0 / 23%);
}

.review-manager-content
{
  background: #f5f5f5;
    /* cursor: not-allowed; */
}

.review-content
{
  width: 48.9% !important;
  margin-left: 15px;
}
.review-content-row
{
  margin-bottom: 12px;
 
}
.review-competency-content
{
  padding-left: 0px !important;
}

.attach-file{
  display: inline-block;
  width: 100%;
  overflow: hidden;
  border: 1px solid lavender;
  border-radius: 7px;
  background-color: #eee7eb78;
  padding: 5px;
  margin-bottom: 0px;
  margin-left: 0px!important;
  margin-right: 7px!important;
}
.btn-bg-darkblue
{
  -webkit-border-radius: 7;
  -moz-border-radius: 7;
  border-radius: 7px;
  font-family: Arial;
  color: black;
 font-weight: 300!important;
  background: #ffc409;
  text-decoration: none;
}
.postReview {
  background-color: #333;
  color: #d9ff00!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}

/* NEW CHECKIN BADGE STYLE  */
.new-checkin-badge
{
  background-color:#3A96E2;
  color: white!important;
  font-size: 12px;
  border-radius: 12px;
  padding: 2px 18px;
  cursor: pointer;
}

/* NEW CHECKIN BADGE STYLE  */
.closed-checkin-badge
{
  background-color:#2A9134;
  color: white!important;
  font-size: 12px;
  border-radius: 12px;
  padding: 2px 18px;
  cursor: pointer;
}

/* CHECKIN CONTAINER */
.checkin-history-container
{
margin-top: 22px;

}

/* CHECKIN HISTORY ROW  */
.checkin-history-row
{

margin: 0px !important;
border-top: 1px solid rgb(173, 164, 164);
}

/* CHECKIN HISTORY CONTENT */
.checkin-content
{ 
  background-color:#ffffff;
    padding: 0.7rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 2px;
    margin:6px 8px;
    
}

.checkin-completed
{
  background: limegreen;
  padding: 2px 18px;
  border-radius: 13px;
}

.add-new-checkin-completed
{
  background: #F37028;
  color: white !important;
  padding: 2px 18px;
  border-radius: 13px;
  cursor:pointer
}



.checkin-history-view
{
  padding-bottom: 12px;
  background-color: white;
  margin-top: 12px;
  margin-bottom: 12px;
}
.thead-color
{
  color:   #3F3F3F;
}
/* .checkin-content table tbody td
{
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

.checkin-content table thead th
{
  white-space: nowrap;
  text-align: left!important;
  font-style: normal!important;
  font-weight: 400;
  font-size: 14px;
  line-height: 3;
  color: #3F3F3F !important;
  background-color: #cccccc !important;
  text-transform: uppercase;
  height: 40px !important;
  text-transform: capitalize;
}

.chekin-topbar
{
  float: right;
  color: rgb(61, 173, 211);
  margin: 12px;
}
.rc-cycle-name
{
  color: var(--secondary-header-bg-color);
    font-size: 16px;
    font-weight: 500;
}

.upload-btn-disabled
{
background-color: grey;
color: white;
}

.txt-upper
{
  text-transform: uppercase;
}

.border-right
{
  border-right: 1px solid grey;
  margin-right: 21px;
}
.review-disabled
{
  background-color:#a9a9a957;
  cursor: not-allowed;
}
.line-ht-52
{
  line-height: 52px !important;
}

#breadcrumb {
  white-space: nowrap;
  text-align: left;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0,0,0,1);
  padding-left: 0px;
}

.review-comp-pd-l
{
  padding-left: 23px !important;
}
.review-comp-elem-pd-l
{
  padding-left: 37px !important;
}
.review-element-header
{
margin-bottom: 0px !important;
}
.review-element-pd-l
{
  padding-left: 46px !important;
}
.review-elem-desc
{
  margin-bottom: 5px;
}
.preview-comp-pd-l
{
  padding-left: 18px !important;
}

.preview-element-pd-l
{
  padding-left: 38px !important;
}
.comment-style
{ 
  background-color: #eef5f9;
  border-radius: 12px;
    padding: 15px 15px 15px 34px;
    margin-bottom: 12px;
   margin-left: 33px;
  }

  .flex-360
  {
    display: flex;
    justify-content: space-between;
  }