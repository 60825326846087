



.table-count-text{
  display: none;
}

.nine-dots-hover {
  transition: transform 0.3s, filter 0.3s;
  cursor: pointer;
  height: 30px;
  width: 30px;
  filter: brightness(0.4) !important;
}

.newText1 {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--RC-top-menu-color) !important;
}

.dowm-modal-icon {
  height: 12.5px;
  width: 12.5px;
  filter: brightness(0.4);
}
.sub-menu .nav
{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    height: 40px;
    padding: 9px;
    padding-left: 0px;
    margin-left: 7px;
    margin-bottom: 0;
    list-style: none;
    position: sticky;
    top: 70px;
    z-index: 1000;
}


.left-nav
{
    width: 61px!important;;
    height: 100%;
    position: fixed;
    outline: 1px solid;
    background-color:var(--RC-left-menu-color);
    top:49px;
    z-index: 3;
    padding: 0px 5px;
}



.leftmenu-open a:hover, .leftmenu-closed a:hover {
margin: 0px !important;
}


.tab .tab-content {
  color: #03080a!important;
  background-color: var(--RC-white-color);
  font-size: 14px;
  line-height: 25px;
  padding:0px !important;
  border-top:none;
}

/* .highlighted-2 {
  border-radius: 10px !important;
  background: #FFFFFF !important;
  margin: 0 5px;
} */

/* .leftmenu-open a:hover, .leftmenu-closed a:hover {
  background-color: #575757;
  text-decoration: none;
  color: var(--RC-white-color);
  border-radius: 10px !important;
  margin: 0 5px;
} */

.filter-bluebtn {
    background-color: #295bdc;
    border: none;
    color: #f7f7f7;
    padding: 8px 33px;
    text-align: center;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 8px 15px #0000001a;
    border-radius: 5px !important;
}

.spinner-loader-new
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffffc9;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #ccc;
}
/* .icon-hover
{
  width: 24px;
  height: 24px;
  background-color: #000;
  border-radius: 7px;
} */
.icon-hover:hover
{
  transform: scalex(1.2);
}

.filter-bluebtn:hover {
    box-shadow: 0 0 8px #3767df;
}

.filter-clearbtn {
    padding: 8px 33px;
    font-size: 13px;
    /* font-weight: 500; */
    margin: 4px 2px;
    color: #000;
    background-color: #d3d3d3;
    border: none;
    box-shadow: 0 8px 15px #0000001a;
    transition: all .3s ease 0s;
    cursor: pointer;
    outline: none;
    border-radius: 7px !important;
}

.filter-orangebtn {
    padding: 8px 33px;
    font-size: 13px;
    font-weight: 500;
    margin: 4px 2px;
    color: white;
    background-color: #f27127!important;
    border: none;
    box-shadow: 0 8px 15px #0000001a;
    transition: all .3s ease 0s;
    cursor: pointer;
    outline: none;
    border-radius: 7px;
}

.filter-orangebtn:hover {
    box-shadow: 0 0 8px #ccc;
}

.filter-clearbtn:hover {
    box-shadow: 0 0 8px #ccc;
}


.btn-cancel
{
    padding: 5px 32px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin: 4px 2px !important;
    color: #FFF !important;
    background-color: #7B7B7B !important;
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
    transition: all .3s ease 0s !important;
    cursor: pointer !important;
    outline: none !important;
    border-radius: 3px ;
    border: 1px solid #3F3F3F;
}
.btn-cancel:hover {
    box-shadow: 0 0 8px #ccc;
}

.btn-save
{
    padding: 5px 32px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin: 4px 2px !important;
    color: #FFFFFF !important;
    background-color: var(--RC-btn-save-background);
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
    transition: all .3s ease 0s !important;
    cursor: pointer !important;
    outline: none !important;
    border-radius: 3px ;
}

.btn-save:disabled
{
    padding: 5px 32px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin: 4px 2px !important;
    color: white !important;
  opacity: 0.6;
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
    transition: all .3s ease 0s !important;
    cursor: not-allowed !important;
    outline: none !important;
    border-radius: 3px ;
}


.btn-bulk-action
{
    padding: 3px 32px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin: 4px 2px !important;
    color: white !important;
    background-color: var(--RC-btn-save-secondary-background);
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
    transition: all .3s ease 0s !important;
    cursor: pointer !important;
    outline: none !important;
    border-radius: 3px ;
}

.btn-bulk-action:disabled
{
    padding: 3px 32px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin: 4px 2px !important;
    color: white !important;
    background-color: #d4d4d4d7;
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
    transition: all .3s ease 0s !important;
    cursor: not-allowed !important;
    outline: none !important;
    border-radius: 3px ;
}



.btn-save-exit
{
  padding: 5px 32px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin: 4px 2px !important;
  color: #fff !important;
  background-color: var(--RC-btn-save-secondary-background) !important;
  border: none !important;
  box-shadow: 0 8px 15px #0000001a!important;
  transition: all .3s ease 0s !important;
  cursor: pointer !important;
  outline: none !important;
  border-radius: 3px;
}

.btn-save-exit:disabled
{
  padding: 5px 32px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin: 4px 2px !important;
  color: #fff !important;
  background-color: #d4d4d4d7 !important;
  border: none !important;
  box-shadow: 0 8px 15px #0000001a!important;
  transition: all .3s ease 0s !important;
  cursor: not-allowed !important;
  outline: none !important;
  border-radius: 3px;
}

.btn-linked-review
{
  padding: 5px 32px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin: 4px 2px !important;
  color: #fff !important;
  background-color: #372549 !important;
  border: none !important;
  box-shadow: 0 8px 15px #0000001a!important;
  transition: all .3s ease 0s !important;
  cursor: pointer !important;
  outline: none !important;
  border-radius: 3px;
}

.btn-return
{
  padding: 5px 32px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin: 4px 2px !important;
  color: #fff !important;
  background-color: #e6a801 !important;
  border: none !important;
  box-shadow: 0 8px 15px #0000001a!important;
  transition: all .3s ease 0s !important;
  cursor: pointer !important;
  outline: none !important;
  border-radius: 3px;
}

.btn-post-review
{
  padding: 5px 32px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin: 4px 2px !important;
  color: #fff !important;
  background-color: #0ad6cc !important;
  border: none !important;
  box-shadow: 0 8px 15px #0000001a!important;
  transition: all .3s ease 0s !important;
  cursor: pointer !important;
  outline: none !important;
  border-radius: 3px;
}

.btn-cancel:hover {
    box-shadow: 0 0 8px #f37028;
}

.btn-table-action
{
  padding: 3px 32px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin: 4px 2px !important;
  color: white !important;
    background-color: var(--RC-btn-save-secondary-background);
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
  transition: all .3s ease 0s !important;
  cursor: pointer !important;
  outline: none !important;
  border-radius: 3px;
}

.border-bg-invalid{
  border: 1px solid var(--RC-bg-red) !important;
}

.btn-table-action:disabled
{
    padding: 3px 32px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin: 4px 2px !important;
    color: white !important;
    background-color: #d4d4d4d7;
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
    transition: all .3s ease 0s !important;
    cursor: not-allowed !important;
    outline: none !important;
    border-radius: 3px ;
}

#filter-title {
    color: #3F3F3F;
    font-size: 21px;
    opacity: 0;
    display: none;
  }


  .review-icon {
    padding-right: 5px;
    width: 27px;
    height: 24px;
    cursor: pointer;
  }

  .review-icon:hover {
    transform: scale(1.2);
    }
 .cancel-btn-bg
 {
    background-color: #d3d3d3 !important;
 }
 .p-editor-container
 {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
 }
 .cursor-pointer
 {
    cursor: pointer !important;
 }

 .cursor-drag
 {
  cursor:move
 }

 .app-menu.mat-menu-panel {
    min-width: 162px !important;
    max-width: 162px !important;
    border-radius: 8px !important;
    margin-top: 10px !important;
    overflow: visible !important;
}

 .my-apps-wrap h6, .home-app-wrap h6 {
    margin: 0 !important;
    padding: 5px !important;
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    color: #00000073 !important;
  }
  .mat-menu-content:not(:empty) {
    padding: 4px!important;
}

.app-menu .mat-menu-item {
    padding: 5px 18px !important;
    line-height: 36px !important;
    height: 36px !important;
}

.app-menu hr {
    height: 1px !important;
    margin: 4px 0 !important;
    overflow: hidden !important;
    line-height: 0 !important;
    /* background-color: #f0f0f0 !important; */
    opacity: .1 !important;
}

.signout-popup .mat-dialog-container {
    border-radius: 8px!important;
    width: 300px!important;
    padding: 20px 24px!important;
    height: 191.75px !important;
}

.signout-popup .mat-dialog-container {
  position: absolute !important;
  top: 60px !important;
  right: 20px !important;
  left: auto !important;
}

.avatar-non-img-styles-look {
    line-height: 52px !important;
  }

  .avatar-line-height
  {
    line-height: 26px !important;
  }

  .nav-item
  {
    cursor: pointer !important;
  }

  .topmenudisabled
  {
    pointer-events: none;
    opacity: 0.5;
    color: grey;
    cursor:not-allowed;
  }
::ng-deep .submenu-li-disabled
{
  color: grey !important;
  pointer-events: none;
  cursor: not-allowed;
}

.submenu-li-disabled:hover
  {
  color: grey !important;
  pointer-events: none;
  cursor: not-allowed;
}

.p-datepicker-header
{
  margin-top: 50px !important;
}
.p-button.p-button-icon-only {
  height: 39px;
}

p-calendar .p-inputtext {
  height: 40px;
}
.closeBtn
{
  width: 112px;
  padding: 7px;
  background: #f27127;
  color: var(--RC-white-color);
  text-align: center;
  font-weight: 600;
  border: none ;
  border-radius: 6px;

}

.addoreditDialog
{

  width: 300px;
  height: 300px;



}

.title-heading
{
  color: #888 !important;
  text-transform: capitalize;
}
.goals-badge
{

font-size: 14px;
font-weight: bold;
border-radius: 4px;
width: 50px !important;
display: inline-block;
text-align: center;
}

.btn-orange
{
  background-color: var(--RC-btn-save-background)!important;
    
}

.title-sub-heading
{
  color: black !important;
}
.profile-pic-alternate
{
  background-color: #b3cee5;
color: #0000b9;
font-weight: 500;
font-size: 18px;
font-family: "Poppins";
height: 40px !important;
width: 40px !important;
border-radius: 30px !important;
align-items: center;
line-height: 40px;
text-align: center;
}
.border-left-primary-color
{
  border-left: 3px solid #0000b9;;
}

.text-gray-300 {
  color: #dddfeb !important;
}

.employee-container
{
  border: 1px solid #ccc;
  height: 100px;
  padding: 31px;
  border-radius: 5px;


}


.dot-badge {
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
}


.goal-type
{
  display: inline-block;
padding: 0.35em 0.65em;
font-size: 1em;
font-weight: 700;
width: 100px;
line-height: 1;
color: var(--RC-white-color);
text-align: center;
white-space: nowrap;
vertical-align: baseline;
border-radius: 50rem!important;
}

.mat-paginator-margin
{
  margin-top: 10px;
}

.categoryPopup .mat-dialog-container {
  top: 18%!important;
  left: 23%!important;
  width: 50%!important;
  height: 500px!important;
  overflow: auto!important;
}
.sidemenu-goal .mat-dialog-container {
  top: 0!important;
  right: 0 !important;
  left:auto !important;
  width: 40%!important;
  height: 100%!important;
  overflow: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}


.sidemenu-employee-goal .mat-dialog-container {
  top: 0!important;
  right: 0 !important;
  left:auto !important;
  width: 80%!important;
  height: 100%!important;
  overflow: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}


.goal-list-library .mat-dialog-container {
top:0;
left: 0;
  width: 100%!important;
  height: 100%!important;
  overflow-y: hidden !important;
  overflow-x: scroll !important;
  border-radius: 0px !important;
  padding:0px 22px !important;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.bg-percentage{
  background-color: #e99494;
}

.bg-amount
{
  background-color: #dfda51;
}

.bg-count
{
  background-color: #8ed3d3;
}

.bg-text
{
  background-color: #8fd77d;
}
.bg-goalType
{
  background-color: lightgreen;
}

.input-filter {
  outline: none;
  border-radius: 5px;
  border: 1px solid #D0D0D0;
  font-size: 14px;
  padding: 2px 33px 5px 10px;
  width: 100%;
  
  max-width: 250px;
  max-height: 33px;
  }
.input-filter {
  background: url('../img/search.svg') top right no-repeat !important;
  background-size: contain !important;
}


@media screen and (min-width:769px) and (max-width:853px) {



  .input-filter {
    outline: none;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    font-size: 14px;
    padding: 2px 33px 5px 10px;
    width: 100%;
    margin-right: 5px;
    max-width: 106px;
    max-height: 33px;
  }
}

@media screen and (min-width:854px) and (max-width:1147px) {



  .input-filter {
    outline: none;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    font-size: 14px;
    padding: 2px 33px 5px 10px;
    width: 100%;
    margin-right: 5px;
    max-width: 174px;
    max-height: 33px;
  }
}

@media screen and (min-width:1148px) and (max-width:1300px) {



  .input-filter {
    outline: none;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    font-size: 14px;
    padding: 2px 33px 5px 10px;
    width: 100%;
    margin-right: 5px;
    max-width: 210px;
    max-height: 33px;
  }
}


.input-filter {
  outline: none;
  border-radius: 5px;
  border: 1px solid #D0D0D0;
  font-size: 14px;
  padding: 2px 33px 5px 10px;
  width: 100%;
  max-width: 200px !important;
  max-height: 33px;
}



.input-filter {
  background: url('../img/search.svg') top right no-repeat !important;
  background-size: contain !important;
}

.goal-dottext
{
    overflow:hidden;
    max-width:250px;
    white-space:nowrap; 
    text-overflow:ellipsis;
  
}

.goal-dottext-name
{
  overflow:hidden;
  max-width:100px;
  white-space:nowrap; 
  text-overflow:ellipsis; 
}


.goal-dottext-date
{
  overflow:hidden;
  max-width:85px;
  white-space:nowrap; 
  text-overflow:ellipsis;
}
.goal-dottexts
{
    overflow:hidden;
    max-width:150px;
    white-space:nowrap; 
    text-overflow:ellipsis;
  
}


.icon {
  display: inline-block;
  padding-right: 5px;
  width: 35px;
  height: 35px;
}

.goal-managment-progress-container {
  width: 100%;
  height: 10px;
  background-color: #dfdddd;
  border-radius: 2px;
  overflow: hidden;
}

.goal-progress-bar {
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: black;
  transition: width 0.5s ease;
}

.goal-progress-text
{
  font-size: smaller;
  padding-left: 6px;
  line-height: 11px;
  width: 20px;
}
.cycle-icon
{
  width: 30px;
  height: 30px;
  
}  


.avatar-goal
{
  background-color: #b3cee5;
  color: #0000b9;
  margin-right: 10px;
  font-size: 20px !important;
  font-family: Poppins;
  display: inline-block;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50.5px;
  /* align-items: center; */
  text-align: center;
  line-height: 42px;

}

.disabled-color
{
  color: grey !important;
  cursor: not-allowed;
  pointer-events: none;
}


.delete-icon {
  padding: 3px !important;
  background-color: #ec002a !important;
  border-radius: 3px;
  width: 30px;
  height: 30px;
}
.delete-icon:hover
{
  transform: scale(1.2);
}


.delete-icon-nine-box {
  padding: 3px !important;
  background-color: #ec002a !important;
  border-radius: 3px;
  width: 27px;
  height: 24px;
}
.delete-icon-nine-box:hover
{
  transform: scale(1.2);
}
#searchs-wrapper{
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.276);
  align-items: stretch;
  border-radius: 5px;
  background-color: var(--RC-white-color);
  overflow: hidden;
  max-width: 250px;
  max-height: 31px;
  
  }
  #searchs{
      border:none;
      width:350px;
      font-size: 15px;
  }
  #searchs:focus{
      outline: none;
  }
  .search-icon{
      margin: 10px;
      color:rgba(0, 0, 0, 0.564);
  }
  #searchs-button{
      border:none;
      cursor: pointer;
      color:var(--RC-white-color);
      background-color:  #255799;
      padding:0px 10px;
  }


  .search-filter {
    outline: none;
    border-radius: 0px;
    border:none;
    font-size: 14px;
    padding: 12px 25px !important;
    padding-left: 5px !important;
        width: 100%;
    max-width: 250px;
    max-height: 33px;
    }
  /* .search-filter {
    background: url('../img/search.svg') top left no-repeat !important;
      background-size: contain !important;
  } */



  .filter-container
{
    padding: 1rem 1rem 0rem 1rem;
    /* padding-right: 3rem; */
    border: 1px solid #ccc;
    background-color: var(--RC-white-color);
    border-radius: 5px;
    margin-bottom: 12px;
    margin-top: 12px;
}




.custom-datepicker .mat-form-field {
  margin-left: 0px !important;
  margin-right: 0px !important;
  top: 0px !important;
  }

.custom-datepicker .mat-datepicker-input {
  font-size: 14px;
  padding: 5px;
  width: 100%;
}

.custom-datepicker .mat-datepicker-content {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.custom-datepicker .mat-form-field-flex {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #ccc;
  align-items: center;
  align-content: space-between;
  /* flex-wrap: nowrap; */
  width: 100% !important;
  height: 35px;
  padding: 6px;
  /* margin-top: 8px; */
  border-radius: 5px;
  color: #717171;
}


.custom-datepicker .mat-form-field-infix {
  width: 100% !important;


}

/* Adjust the input field to take up most of the space */
.custom-datepicker .mat-input-element {
  flex-grow: 1;
}

/* Adjust the calendar icon's margin to place it at the end */
.custom-datepicker .mat-datepicker-toggle {
  margin-left: auto; /* Push the icon to the right */
}
.custom-datepicker .mat-form-field {
  margin-left: 0px !important;
  margin-right: 0px !important;
  top: 0px !important;
  }

.custom-datepicker .mat-datepicker-input {
  font-size: 14px;
  padding: 5px;
  width: 100%;
}

.custom-datepicker .mat-datepicker-content {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.custom-datepicker .mat-form-field-flex {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #ccc;
  align-items: center;
  align-content: space-between;
  /* flex-wrap: nowrap; */
  width: 100% !important;
  height: 35px;
  padding: 6px;
  /* margin-top: 8px; */
  border-radius: 5px;
  color: #717171;
}


/* Adjust the input field to take up most of the space */
.custom-datepicker .mat-input-element {
  flex-grow: 1;
}

/* Adjust the calendar icon's margin to place it at the end */
.custom-datepicker .mat-datepicker-toggle {
  margin-left: auto; /* Push the icon to the right */
}






.filter-datepicker .mat-form-field-infix {
  width: 100% !important;


}

/* Adjust the input field to take up most of the space */
.filter-datepicker .mat-input-element {
  flex-grow: 1;
}

/* Adjust the calendar icon's margin to place it at the end */
.filter-datepicker .mat-datepicker-toggle {
  margin-left: auto; /* Push the icon to the right */
}
.filter-datepicker .mat-form-field {
  margin-left: 0px !important;
  margin-right: 0px !important;
  top: 0px !important;
  }

.filter-datepicker .mat-datepicker-input {
  font-size: 14px;
  padding: 5px;
  width: 100%;
}

.filter-datepicker .mat-datepicker-content {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.filter-datepicker .mat-form-field-flex {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #ccc;
  align-items: center;
  align-content: space-between;
  /* flex-wrap: nowrap; */
  width: 150px !important;
  height: 35px;
  padding: 6px;
  /* margin-top: 8px; */
  border-radius: 5px;
  color: #717171;
}


/* Adjust the input field to take up most of the space */
.filter-datepicker .mat-input-element {
  flex-grow: 1;
}

/* Adjust the calendar icon's margin to place it at the end */
.filter-datepicker .mat-datepicker-toggle {
  margin-left: auto; /* Push the icon to the right */
}


.total-reviews-container .card 
{
  background-color: var(--RC-white-color);
  border: none;
  border-radius: 1rem;
  height: 330px;

}



.total-reviews-container .card .card-header
{
  background-color: #ffffff;
color: #000000 !important;
border-radius: 1rem 1rem 0 0;
font-family: Poppins;
border: none;
}


.performance-dashboard-column-reviews
{
  padding: 12px;
  border-right: 1px solid #ccc;
}

.performance-dashboard-column-reviews-top
{
  border-bottom: 1px solid #ccc;

}

.perfomance-dashboard-card 
{
  background-color: var(--RC-white-color);
  border: 1px solid #ccc;
  border-radius: 7px;

}



.perfomance-dashboard-card-header
{
    font-size: 16px;
    padding: 0.5rem 1rem;
    background-color: var(--secondary-header-bg-color);
    color: var(--RC-white-color) !important;
    border-radius: 7px 7px 0 0;
}

.perfomance-dashboard-card-body
{
  flex: 1 1 auto;
  padding: 1rem 1rem;
}


.dashboard-data-container
{
cursor: pointer;
}
.dashboard-data-container:hover
{
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
}

.confirmation-box .card 
{
border: none !important;
}

.confirmation-box .card-header
{
  border: none !important;
  /* background-color: #f5061f; */
  color: black;
  font-size: large !important;
  padding: 12px 7px !important;
   margin-bottom: 0 !important;
}

.confirmation-box .card-title
{
  text-align: center;
  text-transform: capitalize;
    color: black;
  font-size: large !important;
  margin-bottom: 0 !important;
}



.confirmation-box .card-body
{
  
  border: none !important;
}

.confirmation-box .card-footer
{
  background: white;
  border: none !important;
}


.confirmation-popup .mat-dialog-container{
  top: 35%;
  left: 35%;
  width: 450px!important;
  height: auto!important;
  padding: 0px!important;

}

@media (min-width: 764px) and (max-width: 1023px) {

  .confirmation-popup .mat-dialog-container{
    top: 35%;
    left: 25%;
    width: 450px!important;
    height: auto!important;
    padding: 0px!important;
  
  }
}
@media (min-width: 1024px) and (max-width: 2650px) {

  .confirmation-popup .mat-dialog-container{
    top: 30%;
    left: 40%;
    width: 450px!important;
    height: auto!important;
    padding: 0px!important;
  
  }
}


.add-employee-popup .mat-dialog-container
{
  top: 0 !important;
  left: 0 !important;
width: 100% !important; 
height: 100% !important;
overflow: auto;
}


.preview-popup .mat-dialog-container
{
  top: 3% !important;
  left: 10% !important;
width: 80% !important; 
height: 80% !important;
overflow: auto;
background: #f6f6f6;
padding: 0 !important;
}


.content
{
  background: white !important;
}

.selected-employees-left-side
{
    height: 100%;
    display: flex;
    flex-direction: column;
}


.selected-employees-left-side .employee-list
{
    margin: 2px;
    margin-bottom: 17px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 12px;
   
}


.emp-left-side-container
{
  
  border-radius: 7px;
  box-shadow: 3px 3px 3px 6px rgba(0,0,0,0.16), 3px 3px 6px rgba(0,0,0,0.23);
}

.pip-right-side
{
  border-left:2px solid #ccc;

}

.pip-sub-title
{
  font-size: large;
  font-weight: 600;
  margin-bottom: 25px;
  text-decoration: underline;
}

.title-primary-color
{
color:   #3F3F3F;
}

.pip-emp-details
{
    font-size: 17px;
    margin-bottom: 0.5rem;
}

.pip-goals
{
    font-size: large;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
.pip-goal
{
    padding-left: 2rem;
  font-size: 16px;
}

.pip-header
{
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 700;
  text-decoration: underline;
}

.goal-container
{
  border: 1px solid #ccc;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 13px !important;
}



.container-header
{
  color: #E4A802;
  font-size: 16px;
  font-weight: bold;
  padding-left: 8px;
  margin: 5px;
  text-transform: uppercase;
  display: none;


}

.goal-collapse-container
{
  border: 1px solid #ccc;
  padding: 12px;
  margin: 8px 0px;
  border-radius: 8px;
}


.goal-collapse-container .goal-title
{
  font-size: 18px;
  color: dimgray;
}

.status-count
   {
    text-align: center;
    font-size: x-large;
   }

   .status-name
   {

    font-size: large;    
   }

   .bg-ontrack
   {
    color: #4e73df;
   }

   .bg-needsattention
   {
    color: #fbc02d;
   }
   .bg-atrisk
   {
    color: #fa5f5f;
   }
   .bg-notstarted
   {
    color:#b4bac5 ;
   }

.bg-closed
{
    color:#86c06a ;
}



.custom-date-picker .mat-form-field-flex {
  border: 1px solid #000000;
}
 
.custom-date-picker .mat-form-field-infix {
  padding: 7px 10px;
  border-top-width: 0;
}
 
.custom-date-picker .mat-datepicker-toggle-default-icon {
  width: 1.3em !important;
}
 
.custom-date-picker .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: auto !important;
  background-color: unset !important;
  display: none;
}
 

.custom-date-picker {
  position: relative;
  display: flex;
  border: 1px solid darkgrey;
  border-radius: 5px;
  width: 150px;
  height: 36px;
}

.custom-date-picker .mat-input-element
{
  padding-left: 5px;
  width: 110px;
  border: none;
  background: transparent;
  margin-top: 0px;
}


.invalid-border
{
 border: 1px solid red;
}


.custom-date-picker input.ng-invalid
{
 border: none !important;
}

.cursor-drag
{
  cursor: all-scroll;
}
/* .sub-header
{
  font-size: 16px;
    font-weight: bold;
    color: #e4a802;
} */
tr.mat-row, tr.mat-footer-row {
  height: 45px !important;
}

tr.example-detail-row {
  height: 0 !important;
}

.export-to-pdf-color
{
  background:var(--RC-bg-red) ;
}

.table-actions
{
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  margin: 2px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 1px;
  /* vertical-align: text-bottom; */
  
}

.table-actions:hover
{
  transform: scale(1.2);
}

/* .table-action-width
{
  width: 94px;
} */
.count-hover:hover
{
  color: blue;
  text-decoration: underline;
  /* border-bottom: 2px solid; */
  /* border-color:   #3F3F3F !important; */
} 




.icon-disabled
{
  cursor: not-allowed;
  pointer-events:none;
  background-color: grey !important;
 
}




.application-badge{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  display: inline-block;
  height: auto;
  margin-inline-end: 8px;
  padding-inline: 7px;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.2s;
  text-align: start;
}


.rc-badge{
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}

.tl-badge{
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}

.lms-badge{
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.tc-badge
{
  color: #0958d9;
  background: #e6f4ff;
  border-color: #91caff;
}
.reopen-color {
  background: var(--RC-reopen-color);
}
.closed-color {
  background: var(--RC-closed-color);
}
.update-color {
  background-color: var(--RC-update-color);
}
.edit-color {
  background-color: var(--RC-edit-color);
}
.share-color {
  background-color: var(--RC-share-color);
}
.reopen-icon {
  background-color: var(--RC-reopen-icon-color);
}
.preview-icon{
  background-color: var(--RC-preview-icon);
}
.extend-icon {
  background-color: var(--RC-extend-icon-color);
}
.copy-icon {
  background-color: var(--RC-copy-icon-color);
}
.bg-red {
  background-color: var(--RC-bg-red);
}
.edit-icon {
  background: var(--RC-edit-icon-color);
}
.upload-icon {
  background: var(--RC-upload-icon-color);
}
.bg-green {
  background: var(--RC-bg-green);
}
.bg-gold {
  background-color: var(--RC-bg-gold);
}
.bg-darkblue {
  background-color: var(--RC-bg-darkblue);
}

td,th
{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.source-style
{
  padding-left: 20px !important ;
}

.definition-height{
  height: 80px !important;
}
.bg-content-secondary
{
  background: #ecf4f9;
}

.btn-sm-cancel
{
  padding: 3px 8px !important;
  font-size: 11px !important;
  font-weight: 500;
  margin: 4px 2px !important;
  color: white ;
  background-color: grey ;
  border: none ;
  box-shadow: 0 8px 15px #0000001a ;
  transition: all .3s ease 0s ;
  cursor: pointer ;
  outline: none !important;
  border-radius: 3px;
}

.btn-sm-cancel:disabled ,.btn-sm-blue:disabled
{
    background-color: #d4d4d4d7;
    border: none !important;
    box-shadow: 0 8px 15px #0000001a !important;
    transition: all .3s ease 0s !important;
    cursor: not-allowed !important;
  
}

.btn-sm-blue
{
  padding: 3px 8px !important;
  font-size: 11px !important;
  font-weight: 500;
  margin: 4px 2px !important;
  color: white ;
  background-color: #295bdc;
  border: none !important;
  box-shadow: 0 8px 15px #0000001a !important;
  transition: all .3s ease 0s !important;
  cursor: pointer !important;
  outline: none !important;
  border-radius: 3px;
}

.status-360
{
  width: 30px;
}

/* table scroll  */
.table-scroll
{
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

/* .mat-checkbox-inner-container-no-side-margin {
  border: 2px solid white;
  border-radius: 2px;
} */

.mat-checkbox-inner-container {
  display: inline-block;
  height: 17px !important;
  line-height: 0;
  /* margin: auto; */
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 17px !important;
  border-radius: 8px !important;
  flex-shrink: 0;
}

.mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--RC-utility-color) !important;
  
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--RC-utility-color) !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  border-radius: 5px !important;
  background-color: var(--RC-utility-color) !important;
}
.secondary-checkbox{

  .mat-checkbox-ripple .mat-ripple-element {
    background-color: var(--RC-secondary-utility-color) !important;
    
  }
  
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--RC-secondary-utility-color) !important;
  }
  
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    border-radius: 5px !important;
    background-color: var(--RC-secondary-utility-color) !important;
  }
}
.table-scroll table
{
  tbody{
    vertical-align: middle !important;
  }
  tr:nth-child(even){
    background-color:var(--RC-table-even-bg-color) !important;
  }
  
  tr:nth-child(odd){
    background-color: #ffff !important;
  }
  
  tr:hover{
    background-color: var(--RC-secondary-color) !important;
  }
}


.sub-table{
  table{
    border-collapse: collapse !important;
  }
  thead{
    white-space: nowrap;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 100;
    font-size: 14px;
    /* color: var(--RC-white-color); */
    background-color: #cccccc !important;
    text-transform: capitalize;
    height: 40px !important;
    vertical-align: middle !important;
    
    th{
      font-weight: 400 !important;
    }
  }
}

.title-sub-heading
{
  display: inline !important;
  font-weight: 500 !important;
  /* font-family: 'Poppins' !important; */
  color: black !important;
  font-size: large !important;
}

.dashboard-no-data
{
  height: 250px;
  display: flex;
  justify-content: space-around;
}


.signature-pad-container {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 3px 6px rgb(0 0 0 / 23%);


  canvas {
    width: 100%;
    height: 200px;
    border-top: 1px solid #ccc;
  }
  /* .button-container {
    padding: 10px;
    text-align: center;
  } */
  button {
    margin: 0 5px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: medium;
    cursor: pointer;
  }
  button:hover {
    background-color: #0056b3;
  }
  
  .signature-title
  {
    padding: 12px;
    font-size: x-large;
    font-family: poppins;
    color: #6e6767;
  }
.signature-name
{
  color: #474646;
    text-align: right;
    font-size: 18px;
    /* text-decoration: underline; */
    font-weight: 700;
    margin-bottom: 12px;
    padding-right: 12px;
}


}
.signature-pad-container-column
{
  padding-left: 4px;
    padding-right: 5px;
}

.sub-heading {
  color:   #3F3F3F;
  font-size: 15px;
  font-weight: 800;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

tr.mat-header-row
{
  white-space: nowrap;
    text-align: left!important;
   
    font-style: normal!important;
    font-weight: 600;
    font-size: 14px;
    /* color: var(--RC-white-color); */
    background-color: #cccccc !important;
    text-transform: uppercase;
    height: 40px !important;
}

tr.mat-row:nth-child(even){
  background-color:var(--RC-table-even-bg-color);
}

tr.mat-row:nth-child(odd){
  background-color: #ffff;
}

tr.mat-row:hover{
  background-color: var(--RC-secondary-color);
}


th.mat-header-cell
{
  white-space: nowrap;
    text-align: left!important;
    /* font-family: Poppins,Arial; */
    font-style: normal!important;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color:#3F3F3F !important;
    ;
}

.mat-elevation-z8{
  table{
    th{
      background-color: #cccccc !important;
      color: #3F3F3F;
    }
  }
}

.form-table-data {
  margin-right: 6px;
  margin-top: 5px;
  display: inline-block;
}

.rc-common-card{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow-x: auto;

}

/* sub-heading-change */
.sub-header
{
    text-transform: capitalize;
    color: #3F3F3F;
    font-size: large;
    font-weight: 400 !important;
}

/* cycle active section */
.cycle-active-section {
  background-color: var(--RC-secondary-color);
  border: 2px solid #66666E;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  padding: 16px;
  margin: 8px 0;
  border-radius: 4px;
}


/* Table add icon background color */
.table-add-icon{
  background-color: #255799;
}

.section-border{
  border-bottom: 1px solid #ccc;
}

/* employee-picker-popup-styling */

.employee-picker-popup .mat-dialog-container {
  top: 5%;
  left: 10%;
  width: 80% !important;
  height: auto;
  max-height: 90vh;
  overflow-x: hidden !important;
  overflow: auto;
  padding: 0px !important;
}

@media (min-width: 768px) {
  .employee-picker-popup .mat-dialog-container {
      top: 5%;
      left: 15%;
      width: 70% !important;
      height: 77% !important;
  }
}

@media (min-width: 1024px) {
  .employee-picker-popup .mat-dialog-container {
      top: 5%;
      left: 25%;
      width: 50% !important;
      height: 77% !important;
  }
}



.employee-picker-popup .mat-dialog-container {
  top: 5%;
  left: 10%;
  width: 80% !important;
  height: auto;
  max-height: 90vh;
  overflow-x: hidden !important;
  overflow: auto;
  padding: 0px !important;
}

@media (min-width: 768px) {
  .employee-picker-popup .mat-dialog-container {
      top: 5%;
      left: 15%;
      width: 70% !important;
      height: auto !important;
  }
}

@media (min-width: 1024px) {
  .employee-picker-popup .mat-dialog-container {
      top: 5%;
      left: 25%;
      width: 50% !important;
      height: auto !important;
  }
}

/* number styling in sub heading count */
.count-number{   
  display: inline-block;
 /* min-width: 29px; */
 background: #D7E3FE;
 padding: 6px;
 text-align: center;
 /* margin: 12px 
px
; */
 border-radius: 8px;
}

.sticky-header{
  position: sticky;
  top: 0;
  z-index: 1;
}

.bg-white{
  background-color: white !important;
}
.mat-elevation-z8{
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: rgb(22, 160, 133) !important;
  font-size: 14px;
  font-weight: bolder;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: white !important;
  background: white !important;
}

.mat-sort-header-arrow {
  color: var(--RC-table-header-text-color) !important;
}

/* style for component table heading  */
#table-main-heading{
  white-space: nowrap;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    color:var(--RC-table-heading-text-color);
    /* text-transform: capitalize !important; */
    padding-left: 0px;
}


/* table | style */
.barcolor{
  font-size: 21px;
  color: #c3bebe;
}


/* style for changing the table count  */
.table-count {
  color:var(--RC-table-count-color);
  font-size: 16px;
  text-transform: none !important;
}

.table-count-secondary{
  background: #D7E3FE;
  color: var(--RC-btn-save-background);
  min-width: 25px;
  display: inline-block;
  text-align: center;
  padding: 3px;
  border-radius: 5px;
}

/* table filter icon  */
.Filter-icon{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
}

.table-column-employee{
  color:var(--RC-table-employee-column-color) !important;
}

/* rc-label */
.rc-label{
    font-size: 14px;
    color:var(--RC-label-color);
    text-transform: capitalize !important;
    font-weight: 400;
    /* display: inline-block; */
    margin-bottom: 5px;
}

.rc-label-content{
  font-size: 16px;
  color: var(--RC-text-color);

}

.rc-table-column-count{
  width: 50px;
}

.rc-sub-heading{
  font-size: 17px;
  color: var(--RC-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 5px;
}

.bg-table-add{
  background: var(--RC-btn-save-secondary-background);
}

.bg-export-btn
{
  background-color: var(--RC-btn-save-background);
}


.table-btn-action{
  display: inline-block;
  width: 31px !important;
  height: 29px !important;
  margin: 2px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 1px;
}

.table-btn-action:hover{
  transform: scale(1.2);
}

.ng-select .ng-select-container {
  color: #333;
  background-color: var(--RC-white-color);
  border-radius: 4px;
  border: 1px solid darkgrey !important;
  min-height: 36px;
  align-items: center;
}

.content-white{
  background-color: var(--RC-common-bg-color);
  padding: 15px!important;
  margin-bottom: 20PX;
}

.common-table-header-flex{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.blueheadreviews {
  white-space: nowrap;
  text-align: left !important;
  font-size: 14px;
  color: #888 !important;
  
  text-transform: capitalize !important;
  font-weight: 400;
    border-right: 1px solid #b4bcc3 !important;
}
.rc-review-question{
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  text-align: left !important;
  font-size: 14px !important;
  color:   #3F3F3F;
  text-transform: none;
  font-weight: bold !important;
}
.dropdown-min-width
{
    min-width: 13rem !important;
}

/* th{
  background-color: #cccccc !important;
  color: #3F3F3F !important;
  padding: 0.25rem 0.25rem !important;
  font-weight: 200 !important;
} */

.table-label{
  font-size: 14px;
  color: #3F3F3F;
  font-weight: 200 !important;
}

.form-table-data{
  .ng-select .ng-select-container {
    color: #333;
    background-color: var(--RC-white-color);
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 33px;
    align-items: center;
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 33px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0px;
  left: 0;
  padding-left: 10px;
  padding-right: 50px;
}
}

.table-avatar {
.avatar{
  width: 30px;
  height: 30px;
}
}

.table-count-column{
  min-width: 50px;
  text-align: end;
  width: 50px;
}


/* table count styling count-reviews */
.count-reviews
{
  /* background-color: silver !important; */
    color: #000;
    font-weight: 700;
    border-radius: 13%;
    /* padding: 5px !important; */
    min-width: 50px;  
    text-decoration: none;
    display: inline-block;
    line-height: 15px;
    text-align: end;
    padding-right: 18px;
}
.mat-paginator-container {
  background: var(--RC-common-bg-color);
}

.element-content-left{
  width: 48% !important;
  background: var(--RC-white-color);
  padding: 14px;
  border-radius: 7px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.element-content-right{
  width: 48% !important;
  background: var(--RC-white-color);
  margin-left: 2%;
  padding: 14px;
  border-radius: 7px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.goal-type-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.goal-type-btn:hover {
  background-color: #f5f5f5;
}

.goal-type-btn.active {
  background-color: #255799;
  color: white;
  border-color: #255799;
}

.w-5{
  width: 5%;
}
.w-15{
  width: 15%;
}

.ng-select .ng-value {
  color:var(--RC-table-employee-column-color) !important;
  font-weight: bold;

}


.p-inputnumber-input {
  flex: 1 1 auto;
  text-align: end !important;
}

.fs-16{
  font-size: 16px;
}
.dropdown-headers{
  font-size: 18px;
  border: none;
  background: transparent;
  margin-bottom: 12px;
}

.portal-1-no-data{
  height: 200px;
  display: flex;
  justify-content: space-around;
}

.disable-link{
  color: grey !important;
  cursor: not-allowed !important;
}

.view-more-btn{
  float: right;
  color: var(--RC-btn-save-background);
  cursor: pointer;
  margin-top: 10px;
}


.review-employee-details-container{
  padding-top: 12px;
  padding-bottom: 12px;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  table{
   
    width: 100%;
 
    td{
      text-align: left !important;
      font-weight: 700;
    }
  }




}

.affirmation-container{
  padding: 12px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  margin: 12px 0px; 
}

.affirmation-header{
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;

}

.review-template-name{
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 18px;
  color: #474646;
  font-weight: 500;
}
.employee-review-section{
  background: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 12px;
  border-radius: 5px;
 
}

.review-active{
  border: 1px solid var(--RC-utility-color);
}

.manager-review-section{
  background: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 12px;
  border-radius: 5px;
}

.file-btn {
  color: white;
  background-color: var(--RC-btn-save-background);
  padding: 0px 7px;
  border-radius: 5px;
  /* font-size: 20px; */
  font-weight: bold;
}

.rc-bulk-action{
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 12px;
  border-radius: 5px;
  color: #fff;
  border: none;
}

.rc-bulk-action:disabled{
opacity: 0.5;
cursor: not-allowed;
}