#profile-pic {
  /* position: absolute; */
  width: 61px;
  /* left: 88%;
  top: -2px;
  overflow: visible; */
}
#Ellipse_1 {
  fill: rgba(8,172,33,1);
  stroke: rgba(255,255,255,1);
  stroke-width: 0px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.adduderWidth{
  margin-top: 5px;
  line-height: 20px;
  width: 100%;
  padding: 5px 20px 5px 10px;
  /* margin: 0; */
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF!important;

  /* width: 100%;
  background-color: #F2F2F2!important;
  border:none!important;
  height: 33px;
  border-radius: 4px;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 5px;
  margin-top: 5px; */
}
.imagewidh{
  max-height: 220px;
  max-width: 100%;
  width: 100%;
}
.input-edit-user {
  border: none !important;
}
.Ellipse_1 {
  position: absolute;
  /* overflow: visible; */
  width: 11px;
  height: 11px;
  /* left: 572%;
  top: 19px; */
}
.borderstyle{
  background-color: white;
  width: 11.88630%;
}
#navbarNav {
  background-color: #03080a;
}
.bluelink{
  color:   #3F3F3F!important;
}
.navbar-brand {
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap;
}
.btn-box1 {
  display: inline-block;
  text-align: center;
  position: relative;
}
.sidealign{
  padding-left: 15px;
  margin-top: 11px;
  margin-right: 0px;
}
.form-details{
  color:#5ebcfa;
}
.form-edit{
  color:white;
  background-color: #295BDC;
}

.profile-color{
  font-size: 14px!important;
  font-weight: bold;
  color:#FFFFFF!important;
}
.hie-left-pad-add {
  padding-left: 51px!important;
}
.text-caps{
  text-transform: uppercase!important;
}
.main-drop-menu{
  text-align: end!important;
  margin-bottom: 6px;
  padding-right:5px;
  margin-top: 16px!important;
  /* margin-right: 22px; */
}
#Charlie_Adam {
  left:0px;
  top: 49px;
  position: absolute;
  overflow: visible;
  width: 90px;
  white-space: nowrap;
  text-align: end!important;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;

}
#notifications {
  position: absolute;
  width: 30.261px;
  height: 30px;
  left: 0px;
  top: 26.5px;
  overflow: visible;
}
#Path_92 {
  fill: rgba(0,0,0,1);
}
.Path_92 {
  overflow: visible;
  position: absolute;
  width: 26.761px;
  height: 30px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Ellipse_4 {
  fill: rgba(238,76,88,1);
  stroke: rgba(255,255,255,1);
  stroke-width: 1.5px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Ellipse_4 {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 15px;
  left: 15.261px;
  top: 0px;
}
#help-24px {
  position: absolute;
  width: 31px;
  height: 31px;
  left: 75px;
  top: 26px;
  overflow: visible;
}
#Path_129 {
  fill: transparent;
}
.Path_129 {
  overflow: visible;
  position: absolute;
  width: 31px;
  height: 31px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_130 {
  fill: #FFFFFF;
}
.Path_130 {
  /* overflow: visible; */
  position: absolute;
  width: 27px;
  height: 42px;
  /* left: 86%;
  top: 6px;
  transform: matrix(1,0,0,1,0,0); */
}
/* .table>tbody>tr>td .left-right-padding{
  padding:0px!important;
} */
.left-right-padding{
  padding:0px!important;
}
/* side menu icons */
#Dashboard {
  position: absolute;
  width: 31.049px;
  height: 31.049px;
  left: 15px;
  top: 49px;
  overflow: visible;
}
#Path_72 {
  fill: transparent;
}
.Path_72 {
  overflow: visible;
  position: absolute;
  width: 31.049px;
  height: 31.049px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_73 {
  fill: rgba(255,255,255,1);
}
.Path_73 {
  overflow: visible;
  position: absolute;
  width: 23.287px;
  height: 23.287px;
  left: 3.881px;
  top: 3.881px;
  transform: matrix(1,0,0,1,0,0);
}

#Path_187 {
  fill: rgba(255,255,255,1);
}
.Path_187 {
  overflow: visible;
  position: absolute;
  width: 27px;
  height: 25px;
  left: 17px;
  top: 144px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_79 {
  fill: rgba(255,255,255,1);
}
.Path_79 {
  overflow: visible;
  position: absolute;
  width: 21.408px;
  height: 9.365px;
  left: 0px;
  top: 12.041px;
  transform: matrix(1,0,0,1,0,0);
}
#Group_42 {
  position: absolute;
  width: 28.435px;
  height: 21.407px;
  left: 16.338px;
  top: 229.847px;
  overflow: visible;
}
#Group_41 {
  position: absolute;
  width: 28.435px;
  height: 21.407px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Path_76 {
  fill: rgba(255,255,255,1);
}
.Path_76 {
  overflow: visible;
  position: absolute;
  width: 8.469px;
  height: 9.191px;
  left: 19.965px;
  top: 12.215px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_77 {
  fill: rgba(255,255,255,1);
}
.Path_77 {
  overflow: visible;
  position: absolute;
  width: 7.131px;
  height: 10.704px;
  left: 16.191px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_78 {
  fill: rgba(255,255,255,1);
}
.Path_78 {
  overflow: visible;
  position: absolute;
  width: 10.703px;
  height: 10.704px;
  left: 5.066px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_79 {
  fill: rgba(255,255,255,1);
}
.Path_79 {
  overflow: visible;
  position: absolute;
  width: 21.408px;
  height: 9.365px;
  left: 0px;
  top: 12.041px;
  transform: matrix(1,0,0,1,0,0);
}
#manage_accounts-24px {
  position: absolute;
  width: 31.052px;
  height: 31.052px;
  left: 15px;
  top: 319px;
  overflow: visible;
}
#Group_71 {
  position: absolute;
  width: 31.052px;
  height: 31.052px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Path_108 {
  fill: transparent;
}
.Path_108 {
  overflow: visible;
  position: absolute;
  width: 31.051px;
  height: 31.052px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Group_73 {
  position: absolute;
  width: 25.657px;
  height: 22.17px;
  left: 2.588px;
  top: 5px;
  overflow: visible;
}
#Group_72 {
  position: absolute;
  width: 25.657px;
  height: 22.17px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Ellipse_24 {
  fill: rgba(255,255,255,1);
}
.Ellipse_24 {
  position: absolute;
  overflow: visible;
  width: 11px;
  height: 11px;
  left: 5.412px;
  top: 0px;
}
#Path_109 {
  fill: rgba(255,255,255,1);
}
.Path_109 {
  overflow: visible;
  position: absolute;
  width: 11.98px;
  height: 9.057px;
  left: 0px;
  top: 11.819px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_110 {
  fill: rgba(255,255,255,1);
}
.Path_110 {
  overflow: visible;
  position: absolute;
  width: 12.498px;
  height: 12.938px;
  left: 13.158px;
  top: 9.232px;
  transform: matrix(1,0,0,1,0,0);
}

#Group_558 {
  position: absolute;
  width: 27px;
  height: 25px;
  left: 2px;
  top: 3px;
  overflow: visible;
}
#Path_244 {
  fill: rgba(255,255,255,1);
}
.Path_244 {
  overflow: visible;
  position: absolute;
  width: 27px;
  height: 25px;
  left: 15px;
  top: 413px;
  transform: matrix(1,0,0,1,0,0);
}

#Path_88 {
  fill: rgba(255,255,255,1);
}
.Path_88 {
  overflow: visible;
  position: absolute;
  width: 20.594px;
  height: 20.595px;
  left: 22px;
    top: 525px;
  transform: matrix(1,0,0,1,0,0);
}
#Group_75 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 3px;
    top: 7px;
  overflow: visible;
  fill: white;
}

#Group_75:hover {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 3px;
    top: 7px;
  overflow: visible;
  fill: black;
}
.Ellipse_25 {
  position: absolute;
  overflow: visible;
  width: 2.24px;
  height: 2.24px;
  left: 12.881px;
  top: 11.38px;
}

.Path_112 {
  overflow: visible;
  position: absolute;
  width: 4.48px;
  height: 2.25px;
  left: 11.76px;
  top: 14.5px;
  transform: matrix(1,0,0,1,0,0);
}

.Path_113 {
  overflow: visible;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
.eventsfill {
  overflow: visible;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}

.Path_222 {
  overflow: visible;
  position: absolute;
  width: 18px;
  height: 20px;
  left: 3px;
  top: 2px;
  transform: matrix(1,0,0,1,0,0);
}
#account_tree_black_24dp {
  transform: translate(1px, 3px) matrix(1,0,0,1,-1,0.4756) rotate(90deg);
  transform-origin: center;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 3px;
  top: 3px;
  overflow: visible;
  fill: #8394A6
}
#account_tree_black{
  transform: translate(1px, 3px) matrix(1,0,0,1,-1,0.4756) rotate(90deg);
  transform-origin: center;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 3px;
  top: 3px;
  overflow: visible;
  fill: #8394A6
}

.left-width{
  margin-left: 15px;
  margin-top: 70px;
}
.organ-style{
  /* margin-left: 10px; */
  padding-left: 32px!important;
  margin-bottom: 6px!important;
  white-space: nowrap;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    color: #E4A802;
    margin-top: 0px!important;
    text-transform: uppercase;
}
.image_rect{
  opacity: 2.7!important;
  background-color: #F2F2F2;
  margin-top:10px;

}
.subhead-color{
  /* background-color: #546193c4!important; */
  background-color: var(--RC-secondary-color)!important;
}
.image_rect_edit {
  opacity: 0.7;
  background-color: #F2F2F2;;
  margin-top: 15px;
  border: none;
  border-radius: 6px;
}
.city-edit{
  top: -2px!important;
}
.state-edit{
  top: -2px!important;
}
.cancel-btn{
  margin-right: 15px;
  border: none!important;
  border-radius: 4px!important;
  height: 34px;
  font-size: 13px!important;
}

.edit{
  margin-right: 5px;
  font-size: 12px!important;
}

.edit-color{
  background-color: #295BDC;
  font-size: 12px!important;
}
.delete-color{
  background-color:  #EE4C58;
  color: white;
  font-size: 12px!important;
}
.delete-color:hover{
  color: white!important;
}
.orangebtn:hover{
  color: white!important;
}

.org_name{
    width:100%;
    /* height: 66px; */
    overflow: visible;
    /* margin-top: 20px; */
}
.addcontact{
  text-align: end;
  color: rgba(41,91,220,1);
  padding-bottom:5px

}
.image-upload{
margin-top:75px;

}
.upload-size{
  width: 32px!important;
  height: auto;
}
.org_name_edit
{
    width:100%;
    height: 66px;
    overflow: visible;
}
.org_name_edit_last{
  width:100%;
  height: 66px;
  overflow: visible;
  margin-top: -10px;
}
.org_name_edit_lastend{
  width:100%;
  height: 66px;
  overflow: visible;
  margin-top: -10px;
}

.org_name_last{
  width:100%;
  height: 33px;
  overflow: visible;
  margin-top: 3px;
}

.white-bg{
  background-color: rgba(255,255,255,1);
  padding: 3px;
  text-transform: uppercase!important;
}
.bg-orange{
  background-color: #F37028 !important;
  padding: 5px 15px!important;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px!important;
  color: rgba(255,255,255,1)!important;
}
/* .bg-green{
  background-color: #46B800 !important;
  padding: 5px 15px!important;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px!important;
  color: rgba(255,255,255,1)!important;
} */
.bg-blue{
  background-color: #295BDC !important;
  padding: 5px 15px!important;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px!important;
  color: rgba(255,255,255,1)!important;
}
.icon-actionhie{
  padding-right: 5px;
  width: 22px;
  height: 18px;
  cursor: pointer;
}
.edit-btn{
  text-align: end;
}
.bg-orange:hover{
color: white!important;
}
.org{
  white-space: nowrap;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #F37028;
    text-transform: uppercase;
}
#city{
  left: 32px;
  top: -10px;
		position: absolute;
		overflow: visible;
		width: 44px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: lighter;
		font-size: 15px;
    font-weight: bold;
		color: rgb(13 72 226)!important;

}
#state{
  left: 27px;
  top: -10px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;		font-style: normal;
		font-weight: lighter;
		font-size: 15px;
    font-weight: bold;
		color:   #3F3F3F;
}
#Name{
    left: 32px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 190px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: lighter;
		font-size: 15px;
    font-weight: bold;
    color:rgb(13 72 226);
}
#country{
   
    left: 32px;
    top: 159px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: lighter;
		font-size: 15px;
    font-weight: bold;
		color:   #3F3F3F;
}
.city{
  padding-right: 5px !important;
}
.city-margin{
  margin-right: -8px;
  opacity: 1;
}
.addopacity{
  opacity: 4.7!important;
}
.imagewidh{
  max-height: 220px;
  max-width: 100%;
  width: 100%;
}

.state-margin{
  margin-left: 0px;
  opacity: 1;
}
.assign-leftend {
  margin-left: 10px;
  min-width: 48px!important;
}
 
 
#content-inside{
  height: 33px;
  width: 100%;
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: none;
  background: #F2F2F2!important;
/* 
  background-color: #F2F2F2!important;
  padding-left: 10px;
  padding-top: 3px;
  height: 33px;
  color: rgb(0, 0, 0) !important;
  opacity: 1!important;
  border-radius: 4px!important; */
} 
.org-head {
  font-size: 15px;
  font-weight: bold;
  color:   #3F3F3F;
}
.division{
  margin-right: 66px;
}
.image-width{
  width: 100px;
  height: 142px
}
#Address{
  position: absolute;
  width: 83px;
  height: 29px;
  left: 35px;
  top: 88px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  font-weight: bold;
  color:   #3F3F3F;

}
.input-edit-profile{
  /* background-color: #F2F2F2!important; */
  /* border: none!important; */
  /* height: 33px;
  border-radius: 4px;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 5px;
  margin-top: 5px; */

  line-height: 20px;
  width: 100%;
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: none;
  background: #F2F2F2!important;
}
.input-edit-profile-edit{
  line-height: 20px;
  width: 100%;
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF!important;

}
.readonlyinput{
  /* opacity: .5; */
  cursor: not-allowed;
}
.img-height{
  height: 190px;
  text-align: center;
}
#profile{
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
  padding-left: 16px;
  padding-bottom: 10px;
}
#contacts{
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3F3F3F;
  text-transform: uppercase;
  margin-top: 15px;
}
.pagination{
  font-size: 10px!important;
}
.location{
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3F3F3F;
  text-transform: uppercase;
}

#zip{
  position: absolute;
  width: 169px;
  height: 29px;
  left: 35px;
  top: 88px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 15px;
  font-weight: bold;
  color:   #3F3F3F;

}
#Id_right{
  left: 32px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 86px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 15px;
  font-weight: bold;
  color:   #3F3F3F;
}
/* table */
.tbl-color{
  border: 1px solid #03080A;
  /* border:2px solid #c2bbbb; */
  /* opacity: 1; */
  fill: #F2F2F2;
  stroke: #000000;
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  border-radius: 4px;
  /* border-collapse: separate !important; */
}
.tbl-color-form{
  border:2px solid #c2bbbb;
  opacity: 0.7;
  fill: #EEF5F9;
  stroke: #707070;
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  border-radius: 4px;
  text-align: center !important;
  border-collapse: collapse;
  font-size: 11px!important;
}
.tbl-color-form >td{
  padding: 3px!important;
}
.collapse-color{
  background-color:#EEF5F9!important;
}
.competency-tbl{
  border:2px solid #c2bbbb;
  fill: #eef5f9;
  opacity: 4.7!important;
  stroke: #707070;
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  border-radius: 4px;
  border-collapse: separate !important;
}
/* .table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs- -color: var(--bs-body-color);
  --bs- -bg: rgba(0, 0, 0, .05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, .1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, .075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
} */
.eventspace-element {
  padding-bottom: 5px;
  margin-right: 10px!important
}
.emp{
  border-right: none!important;
  padding: 8px!important;
}
/* .table>tbody>tr>td{


    text-align: left!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    border: none!important;
    color:#03080A!important;
}
.table>tbody>tr>.validation-err
{
  border-top:2px solid red!important;
} */
.mat-expansion-panel-body{
  border:solid 1px #dddddd;
  border-radius: 0px 0px 7px 7px;
}
.question-dis{
  font-size: large;
  font-weight: bold;
}
.btn-padding{
  margin-right: 10px;
  padding: 6px 14px!important;
  font-size: 12px!important;
}
.section-edit{
  padding-top: 8px!important;
}
.tbl-head{
  white-space: nowrap;
  text-align: left!important;
  font-family: Poppins,Arial;
  font-style: normal!important;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background-color: #03080a;
  text-transform: uppercase;

}

.first-padding{
  padding-left: 25px!important;
}
.table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
  /* background-color: rgb(238, 245, 249,0.3); */
  background-color: #FAFCFD!important;
}
.img-close{
  width: 20px!important;
  height:auto;
}
#Search {
  position: absolute;
  width: 283px;
  height: 32.5px;
  left: 362px!important;
  top: 15px;
  overflow: visible;
}
#search {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 223.499px;
  top: 0px;
  overflow: visible;
}
#Path_94 {
  fill: rgba(93,93,93,1);
}
.Path_94 {
  overflow: visible;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Search_d {
  opacity: 4.7;
  left: 17.5px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: rgba(93,93,93,1);
}
#Line_28 {
  opacity: 0.5;
  fill: transparent;
  stroke: rgba(0,0,0,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_28 {
  overflow: visible;
  position: absolute;
  width: 263px;
  height: 1px;
  left: 0px;
  top: 32.5px;
  transform: matrix(1,0,0,1,0,0);
}
.nav .open>a{
  background-color:#E4A802!important;
  color:#03080A!important;
}
.space-top-bottom{
  padding-top: 0PX;
  padding-bottom: 0px;
  /* margin-top: -10px; */
  /* margin-bottom: -5px; */
}

@media (max-width: 990px) {
  .navbar.navbar-default .navbar-header {
    float: none;
  }
  .navbar.navbar-default .navbar-left,
  .navbar.navbar-default .navbar-right {
    float: none !important;
  }
  .navbar.navbar-default .navbar-toggle {
    display: block;
  }
  .navbar.navbar-default .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar.navbar-default.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar.navbar-default .navbar-collapse {
    overflow: hidden;
  }
  .navbar.navbar-default .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar.navbar-default .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar.navbar-default .navbar-nav > li {
    float: none;
  }
  .navbar.navbar-default .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar.navbar-default .collapse.in {
    display: block !important;
  }
  .navbar.navbar-default .navbar-nav .dropdown-menu > li > a,
  .navbar.navbar-default .navbar-nav .dropdown-menu > li > a:hover,
  .navbar.navbar-default .navbar-nav .dropdown-menu > li > a:focus {
    /* color: #777; */
    color: white;
  }
}
.dropdown-menu > li > a{
  color: black;
  background-color: white;
}
/* .dropdown-menu > li > a:hover{
  color: white!important;
} */

/* Add contacts Section */
.section-contacts{
padding-left: 0px;
padding-right: 15px;
}
.section-question{
  padding-right: 15px;
  text-align: left;  
}

.addDir .mat-dialog-container{
  position: absolute;
  top: 10% !important;
  left: 30% !important;
  width: 600px !important;
  overflow: auto !important;
  height: 400px !important;
}

.addContact .mat-dialog-container {
  position: absolute;
  top: 20%;
  left: 20%;
  width: auto!important;
  height: auto!important;
  overflow: hidden!important;
  padding:-10!important;
}


.mat-dialog-container {
  position: absolute;
  top: 40px;
  left: 20%;
  width:auto!important;
  /* width: 60%!important; */
  height:77%!important;
  overflow: auto!important;
  padding:-10!important;
}


.editLocation .mat-dialog-container {
  position: static !important;
  top: 71px;
  left: 20%;
  /* width: auto!important; */
  width: auto !important;
  height: auto !important;
  overflow: auto!important;
  padding: 23px !important;
}
/* input.ng-invalid, select.ng-invalid, mat-select.ng-invalid {
  border: 1px solid red!important;
} */
.editContacts .mat-dialog-container {
  position: static !important;
  /* top: 71px;
  left: 25%; */
  width: auto!important;
  height: auto !important;
  overflow: hidden!important;
  padding:23px !important;
}


.deletePopContact .mat-dialog-container {
  position: absolute;
  top: 20%;
  left: 35%;
  width: auto!important;
  height: auto!important;
  overflow: hidden!important;
  padding:-10!important;
}
.emplist .mat-dialog-container{

  position: absolute;
  top: 1%;
  left: 21%;
  width: 60%!important;
  height: 500px !important;
  overflow: auto!important;
  padding: -10!important;
}
.editlevel .mat-dialog-container{
  position: absolute;
  top: 20% !important;
  left: 30% !important;
  width: 576px!important;
  height: 323px!important;
  padding: 31px!important;
  overflow: none!important;
}

.trasferdir .mat-dialog-container {
  position: absolute;
  top: 30% !important;
  left: 30% !important;
  width: 600px!important;
    height: 300px !important;
  overflow: none!important;
  padding: -10!important;
}

@media screen and (min-width:769px) and (max-width:853px){
  .emplist .mat-dialog-container{

    position: absolute;
    top: 1%;
    left: 15%;
    width: 75%!important;
    height: 500px !important;
    overflow: auto!important;
    padding: -10!important;
  }
  /* .editContacts .mat-dialog-container {
    position: absolute;
    top: 16%;
    left: 11%;
    width: auto!important;
    height: auto!important;
    overflow: hidden!important;
    padding:-10!important;
  } */
  /* .editLocation .mat-dialog-container {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 510px!important;
    height: 400px!important;
    overflow: hidden!important;
    padding:-10!important;
  } */

  .editlevel .mat-dialog-container{
    position: absolute;
    top: 20%!important;
    left: 19%!important;
    width: 576px!important;
    height: 323px!important;
    padding: 31px!important;
    overflow: none!important;
  }
  .addDir .mat-dialog-container{
    position: absolute;
    top: 15% !important;
    left: 15% !important;
    width: 600px !important;
    overflow: auto !important;
    height: 400px !important;
  }

  .trasferdir .mat-dialog-container {
    position: absolute;
    top: 15% !important;
    left: 15% !important;
    width: 600px!important;
    height: 300px !important;
    overflow: none!important;
    padding: -10!important;
  }

}



@media screen and (min-width:854px) and (max-width:1024px){
  .emplist .mat-dialog-container{

    position: absolute;
    top: 1%;
    left: 15%;
    width: 75%!important;
    height: 500px !important;
    overflow: auto!important;
    padding: -10!important;
  }
/* 
  .editContacts .mat-dialog-container {
    position: absolute;
    top: 16%;
    left: 20%;
    width: auto!important;
    height: auto!important;
    overflow: hidden!important;
    padding:-10!important;
  } */

  /* .editLocation .mat-dialog-container {
    position: absolute;
    top: 40px;
    left: 20%;
    width:auto!important;
    /* width: 60%!important; */
    /* height: 78%!important;
    overflow: hidden!important;
    padding:-10!important;
  }  */
  .editlevel .mat-dialog-container{
    position: absolute;
    top: 20% !important;
    left: 25% !important;
    width: 576px!important;
    height: 323px!important;
    padding: 31px!important;
    overflow: none!important;
  }

  .addDir .mat-dialog-container{
    position: absolute;
    top: 15% !important;
    left: 20% !important;
    width: 600px !important;
    overflow: auto !important;
    height: 400px !important;
  }
  .trasferdir .mat-dialog-container {
    position: absolute;
    top: 20% !important;
    left: 20% !important;
    width: 600px!important;
    height: 300px !important;
    overflow: none!important;
    padding: -10!important;
  }
}


@media screen and (min-width:1025px) and (max-width:1300px){
  .emplist .mat-dialog-container{

    position: absolute;
    top: 1%;
    left: 15%;
    width: 75%!important;
    height: 500px !important;
    overflow: auto!important;
    padding: -10!important;
  }
  /* .editContacts .mat-dialog-container {
    position: absolute;
    top: 16%;
    left: 20%;
    width: auto!important;
    height: auto!important;
    overflow: hidden!important;
    padding:-10!important;
  } */
/* 
  .editLocation .mat-dialog-container {
    position: absolute;
    position: absolute;
    top: 40px;
    left: 20%;
    width: 60%!important;
    height: 82%!important;
    overflow: hidden!important;
    padding:-10!important;
  } */

  .editlevel .mat-dialog-container{
    position: absolute;
    top: 20% !important;
    left: 20% !important;
    width: 576px!important;
    height: 323px!important;
    padding: 31px!important;
    overflow: none!important;
  }

  .addDir .mat-dialog-container{
    position: absolute;
    top: 15% !important;
    left: 25% !important;
    width: 600px !important;
    overflow: auto !important;
    height: 400px !important;
  }
  .trasferdir .mat-dialog-container {
    position: absolute;
    top: 20% !important;
    left: 25% !important;
    width: 600px!important;
    height: 300px !important;
    overflow: none!important;
    padding: -10!important;
  }


}

@media screen and (min-width:1500px) and (max-width:1999px){
  .emplist .mat-dialog-container{

    position: absolute;
    top: 1%;
    left: 15%;
    width: 75%!important;
    height: 500px !important;
    overflow: auto!important;
    padding: -10!important;
  }
  .editlevel .mat-dialog-container{
    position: absolute;
    top: 20%!important;
    left: 30% !important;
    width: 576px!important;
    height: 323px!important;
    padding: 31px!important;
    overflow: none!important;
  }
  .addDir .mat-dialog-container{
    position: absolute;
    top: 15% !important;
    left: 30% !important;
    width: 600px !important;
    overflow: auto !important;
    height: 400px !important;
  }

  .trasferdir .mat-dialog-container {
    position: absolute;
    top: 20% !important;
    left: 30% !important;
    width: 600px!important;
    height: 300px !important;
    overflow: none!important;
    padding: -10!important;
  }
}

@media screen and (min-width:2000px) and (max-width:2650px){
  
  .emplist .mat-dialog-container{

    position: absolute;
    top: 1%;
    left: 15%;
    width: 75%!important;
    height: 500px !important;
    overflow: auto!important;
    padding: -10!important;
  }

  /* .editContacts .mat-dialog-container {
    position: absolute;
    top: 20%;
    left: 35%;
    width: auto!important;
    height: auto!important;
    overflow: hidden!important;
    padding:-10!important;
  } */

  /* .editLocation .mat-dialog-container {
   
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%!important;
    height: 50%!important;
    overflow: hidden!important;
    padding: -10!important;
  } */

  .editlevel .mat-dialog-container{
    position: absolute;
    top: 25%!important;
    left: 35% !important;
    width: 576px!important;
    height: 323px!important;
    padding: 31px!important;
    overflow: none!important;
  }

  .addDir .mat-dialog-container{
    position: absolute;
    top: 15% !important;
    left: 35% !important;
    width: 600px !important;
    overflow: auto !important;
    height: 400px !important;
  }
  .trasferdir .mat-dialog-container {
    position: absolute;
    top: 25% !important;
    left: 40% !important;
    width: 600px!important;
    height: 300px !important;
    overflow: none!important;
    padding: -10!important;
  }

}



.addnew{
  text-align: left;
  color: #3F3F3F;
  font-size: 20px;
}
.deleteconfirm{
  text-align: center;
  /* color: #3F3F3F; */
  color: #000;
  font-size: 17px;
}
.confirm-msg{
  font-size: 15px;
  text-align: center;
  padding-top: 17px;
  padding-left: 25px;


}
.subheading{
  font-size: 14px;
  color: rgba(41,91,220,1);
  text-align: left;
  padding-top: 6px;
  /* padding-left: 5px!important; */
}

.headcontact-blue{
  color:rgba(41,91,220,1);
  font-size: 11px;
}
.subheading-blue{
  font-size: 20px;
}
.rowlength{
  margin-bottom: 10px;
}
.namebox{
  line-height: 28px;
  width: 100%;
  /* padding: 5px 20px 5px 10px; */
  padding: 1px 17px 3px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF!important;
}
.namebox-manager{
  border-radius: 4px;
  display: inline-block;
  width: 58% !important;
  border: 1px solid #ccc !important;
  padding-left: 10px;
}

.applyfilter-user {
  margin-right: 5px;
  margin-left: 0px;
  background-color: #295BDC;
  padding: 6px 20px!important;
  font-size: 13px!important;
  color: white!important;
  border: none;
  cursor: pointer;
  border-spacing: 0px;
  border-radius: 4px;
} 
.exbtnpadd{
  padding-top: 7px;
}
.namebox-dash{
  height: 23px;
  border-radius: 5px;
  width: 201px;
  border: 1px solid;
  padding-left: 10px;
}
.box2{
  width: 118%;
}
.actions-btn{
  margin-left: 128px!important;
  margin-top: 28px;
}
.deleteactions{
  margin-top: 38px;
}
.orangebtn{
  background-color: rgba(242,113,39,1) !important;
  color:white!important;
  font-size: 13px!important;
}
.btnwidth{
  padding-left: 40px!important;
  padding-right: 40px!important;
}
.crossbtn{
  text-align:right;
}
.close-btn{
  border: none;
  background-color: white;
  text-align: end;
    padding-right: 15px;
}
.crosssign{
  width: 27px!important;
  height:auto;
  border: none;
}
.no-btn{
  text-align: right;
  margin-left: -72px;

}
.spinner_container {
  border-radius: 10px;
  height: 70px;
  left: 50%;
  position: absolute;
  top: 80%;
  transform: translate(-50%,-50%);
  width: 70px;
  z-index: 3;
  opacity: 0.3;
}
/* hierarchy */
.searchbar{
  text-align: end;
  padding-right: 4px!important;
}
.editlevelbtn{
  margin-right: 5px;
}
.searchinput{
  width: 218px;
  border-radius: 7px;
  border: none;
  padding: 5px;
  padding-left:10px;
  background-color: rgba(240,240,240,1);
}
#search-blue {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 566px!important;
  top: 5px;
  transform: matrix(1,0,0,1,0,0);
}
.addentity{
  color:#f27127;
  font-size: 16px;
}
.level1{
  background-color: #F2F2F2!important;
}
.delete-action{
  margin-top: 5px;
}
/* .table>thead>tr>th {
  border-bottom:none!important;
} */
.hierarchytbl {
  border: 1px solid #03080A!important;
  width: 100%;
  margin-top: 8px;
  border-radius: 5px;
}
.divspace{
  padding-left:10px;
  padding-bottom: 7px;
}
.depspace{
  padding-left: 45px;
  padding-bottom: 9px;
  padding-top: 9px;
}

.form-input{
  width: 172px;
  padding-right:3px;
}
.group-color{
  background-color: #203864;
}
.tree-input{
  border:none;
  width: 133px;
  height: 33px;
  background-color: #EEF5F9!important;
}
.tree-inputc{
  border:none;
  width:133px;
  height: 34px;
  background-color: #eef5f9!important;
}
input[class="tree-input"]:enabled {
  background-color: white;
}

input[ng-reflect-is-disabled="false"]{
  background-color: white!important;
}
.level2 .tree-input {
  background-color: white!important;

}
.level2{
  background-color: white!important;
}

.level2 .arrow-btn{
  background-color: none!important;
}
.savebtn{
  background-color: #f27127;
}
.table-list{
  width: 28px;
  height: auto;
}
.employeelist-section{
  overflow: hidden;
  width: 1146px;
}

.review-stars{
  /* width: 270px; */
  /* margin-left: -124px!important; */
  /* margin-bottom: 10px!important; */
  margin-left: -11px !important;
  padding-top: 7px;
  display: inline-block;
}
.review-stars-review{
  /* width: 270px; */
  /* margin-left: -124px!important; */
  /* margin-bottom: 10px!important; */
  margin-left: -6px !important;
  display: inline-block;
}

#entityForm{
  display: block !important;
}
.hierarchytbl > tbody > tr:nth-child(2n+1) > td, .hierarchytbl > tbody > tr:nth-child(2n+1) > th {
  background-color: #F2F2F2!important;
}
.hierarchytbl > tbody > tr:nth-child(2n) > td, .hierarchytbl > tbody > tr:nth-child(2n) > th {
  background-color: rgb(238, 245, 249,0.3);
}
/* tree grid */
.htbl-head {
  /* background-color: #03080A;
  border-color: #03080A!important;
  font-weight: bold;
  padding: 5px;
  text-align: left;
  color: #FFFFFF; */
  white-space: nowrap;
  text-align: left!important;
  font-family: Poppins,Arial;
  font-style: normal!important;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background-color: #03080a;
  text-transform: uppercase;
}
.h-name{
  padding-left: 66px!important;
}
.arrow-btn{
  background-color: none;
  border: none;
}
.arrow-btn:active{
  background-color: none;
  border: none;
}
.arrow-btn[aria-expanded="true"] {
  transform: rotate(90deg);
}
.level-padding{
  padding-left: 166px;
}
.id-padding{
  padding-left: 51px;
}
.count-padding{
  padding-left: 30px;
}
.manager-padding{
  padding-left: 65px;

}
.buttonchild-padding{
  padding-left: 174px;
}
.buttonchildl-padding{
  padding-left: 122px;
}
.btn-sm {
  padding: 2px 3px!important;
  border-radius: 5px!important;
}
.row-margin{
  margin-top: 10px;
  margin-bottom: 10px;
}
.level2-padding{
  padding-left: 255px;
}
.id2-padding{
  padding-left: 48px;
}
.count2-padding{
  padding-left: 156px;
}
.manager2-padding{
  padding-left: 67px;
}
.level3{
  background-color: #F2F2F2!important;
}
.bg-gray{
  background-color: #FAFCFD!important;
}
.hie-left-pad{
  padding-left: 25px!important;
}
.hie-level3{
  padding-left: 85px!important;
}
.managerwidth{
  width: 15%;
}
.actionwidth{
  width: 20%;
  padding-right: 17px;
}
.btn-allign{
  text-align: end!important;
  /* padding-right: 13px!important; */
}
.add-entity{
  padding-left: 27px!important;
}
.level3-paddig{
  padding-left: 242px;
}
.id3-padding{
  padding-left: 48px;
}
.count3-padding{
  padding-left: 153px;
}
.manager3-padding{
  padding-left: 66px;
}
.buttonset-padding{
  padding-left: 180px;
}
.entity-left{
  margin-left: 19px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dividion-left{
  margin-left: 31px;
  margin-top: 10px;
}
.dept-left{
  margin-left: 70px;
}
.div-sec{
  background-color:#fafcfd!important ;
}
.add-en{
  background-color:#eef5f9!important ;
}
.en-input{
  line-height: 20px;
  /* width: 100%; */
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF!important;
  /* border:none; */
  width:150px;
  /* height: 40px; */
  /* background-color: white!important; */
  /* border-radius: 4px; */
}
.en-input::placeholder {
  font-size: 12px;
}
.entityForm{
  padding-left: 50px;
}
.divForm{
  padding-left: 25px;
}
.depForm{
  padding-left: 38px;
}
.manageren-padding{
  padding-left: 100px;
}
.button-entity{
  padding-left: 60px;
  padding-top: 8px;
  padding-bottom: 14px;
}
.button-entityl{
  padding-left: 69px;
  padding-top: 8px;
  padding-bottom: 14px;
}

.add-level{
  padding-left: 93px;
}
.divspace .arrow-btn{
  background-color:#fafcfd!important ;
}
#search-blue path {
  fill: rgba(41,91,220,1);
}
.searchinput::placeholder {
  color: rgba(41,91,220,1);
  opacity:0.7;
}
.head-padding{
  padding-left:5px!important;
}
.head-margin{
  margin-left: -7px;
}
.radio #active {

  margin-left: 8px!important;
}




/* style for tab profiel/horizontal starts */
a:hover,
a:focus{
    text-decoration: none !important;
    outline: none !important;
}
.tab{ font-family: 'Montserrat', sans-serif;
  overflow: visible; }
  .tab .nav-tabs{ border: none; margin-top: 0px;
    padding-left: 5px;
   }
   

.tab .nav-tabs li a:hover {
  background-color: #1292be!important;
}
.tbl-head-cycle {
  background-color: #e7e9ee;
  /* border-color: #295BDC!important; */
  white-space: nowrap;
  text-align: left !important;
}
.pro-img path {
  fill: #070808; 
}
.editlevelbtn:hover{
  color: white !important;
}
.mat-content{
  overflow: hidden!important;
}
.tab .nav-tabs li a:before,
.tab .nav-tabs li a:after{
  content: '';
  border-bottom: 3px solid rgba(41,91,220,1);
  color:rgba(41,91,220,1)!important;
  background-color: #1292be!important;
  height: 3px;
  width: 0;
  position: absolute;
  left: 0px;
  bottom: -3px;
  transition: all 0.3s ease 0s;
}
.tab .nav-tabs li a:after{
    border-radius: 50%;
    transform: translateX(-50%) scale(0);
    transform-origin: bottom center;
    bottom: 0;
}
.nav-tabs>li.active>a{
  /* background-color: #1292be!important;
  color: white!important; */
  border: none!important;
  font-weight: 700;
  background-color: #FFFFFF!important;
  color:#33475D!important;
  overflow: visible!important;
}

.nav-tabs>li.submenuDisabled>a{
  /* background-color: #1292be!important;
  color: white!important; */
  border: none!important;
  font-weight: 700;
  background-color: grey!important;
  color:#03080A!important;
  overflow: visible!important;
  pointer-events: none;
  cursor: not-allowed;
}


.nav-tabs>li.active>a >#Path_254 {
  fill:#03080A!important;
}
.tab .nav-tabs .settings-li .interns-li :hover #account_tree_black_24dp {
  fill:#03080A!important;
}

.nav-tabs>li.active > a > #account_tree_black_24dp {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #account_tree_black_24dp path {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #account_tree_black {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #account_tree_black > path {
  fill:#33475D!important;
}

.nav-tabs>li.active:hover > a > #account_tree_black_24dp {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #account_tree_black #account_tree_black_1 #account_tree_black_11 #stroke-white {
  stroke:#33475D!important;
}
.nav-tabs>li:hover > a > #account_tree_black #account_tree_black_1 #account_tree_black_11 #stroke-white {
  stroke:#33475D!important;
  transition: 1s;
}
.tab .nav-tabs li a:hover #account_tree_black_24dp {
  fill:#33475D!important;
}
.tab .nav-tabs li a:hover #account_tree_black {
  fill:#33475D!important;
}
.tab .nav-tabs li a:hover > #account_tree_black  #Path_254 {
  fill:#33475D!important;
}
.tab .nav-tabs li a:hover > #account_tree_black_24dp  #Ellipse_25 {
  fill:#33475D!important;
}
.tab .nav-tabs li a:hover > #account_tree_black_24dp path {
  fill:#33475D!important;
}

.tab .nav-tabs li a:hover > #Group_75  #Path_357 {
  fill:#33475D!important;
}
.tab .nav-tabs li a:hover > #Group_75  #Path_358 {
  fill:#33475D!important;
  /* transition: 1s ease-out; */
}

.nav-tabs>li.active > a > #Group_75  {
  fill:#33475D!important;
}
.tab .nav-tabs li a:hover >  #account_tree_black_24dp  #Path_113{
  fill: #33475D!important;
}
/* settings tab */
.nav-tabs>li.active > a > #account_tree_black  #Path_254 {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #account_tree_black_24dp  #Ellipse_25 {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #account_tree_black  #Path_270 {
  fill:#33475D!important;
}

.nav-tabs>li.active > a > #account_tree_black  #pathform {
  fill:#33475D!important;
}
.dropdown-menu >li .interns-li :hover > #account_tree_black_24dp  #Path_112
{
  fill:#33475D!important;
}
.dropdown-menu >li .interns-li :hover > #account_tree_black_24dp  #Path_113
{
  fill:#33475D!important;
}
.tab .nav-tabs .settings-li .interns-li :hover > #account_tree_black  #pathform
{
  fill:#33475D!important;
}
.tab .nav-tabs .settings-li .interns-li :hover > #account_tree_black  #Path_254
{
  fill:#33475D!important;
}
.tab .nav-tabs .settings-li .interns-li > #account_tree_black  #Path_254
{
  fill:#33475D!important;
}

.tab .nav-tabs li a:hover > #account_tree_black  #Path_270 {
  fill:#33475D!important;
}
.tab .nav-tabs .settings-li .interns-li :hover > #account_tree_black  #Path_270
{
  fill:#33475D!important;
}
.tab .nav-tabs .settings-li .interns-li > #account_tree_black  #Path_270
{
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #Group_75 {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #Group_75 #Path_357 {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #Group_75 #Path_358 {
  fill:#33475D!important;
}
.nav-tabs>li.active > a > #Group_75 #Path_159 {
  fill:#33475D!important;
}

.nav-tabs>li a:hover > #Group_75 #Path_159 {
  fill:#33475D!important;
}
.tab .nav-tabs li a:hover #Group_75 {
  fill:#33475D!important;
}

.nav-tabs>li.active > a > #Group_75 #Path_358 {
  fill:#33475D!important;
}

.nav-tabs>li.active > a > #Group_75 #Path_3581 {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  stroke:#33475D!important;
}

.tab .nav-tabs li a:hover > #Group_75  #Path_3581 {
  stroke:#33475D!important;
}
/* .nav-tabs>li.active>a { */
/* .nav-tabs>li.active>a:hover{
  background-color: white!important;
} */
/* .tab .nav-tabs li a:hover{
  background-color: white!important;
} */
.default-height{
  height: 200px!important;
}
/* tab for user profile color change */
/* .tab .nav-tabs li.active a:after, .tab .nav-tabs li a:hover:after {
  color:rgba(41,91,220,1)!important;
}
.tab .nav-tabs li[class="active"] a #Path_222 {
  fill:rgba(41,91,220,1);
}
.tab .nav-tabs li[class="active"] a #Ellipse_25{
  fill:rgba(41,91,220,1)!important;
}
.tab .nav-tabs li[class="active"] a #Path_112{
  fill:rgba(41,91,220,1)!important;
}
.tab .nav-tabs li[class="active"] a > svg >g> g #Path_113{
  fill:rgb(52, 107, 248)!important;
} */
/* ends */


.tab .tab-content{
    /* color: rgb(0 0 0); */
    color:#03080A!important;
    background-color: #fff;
    font-size: 14px;
    line-height: 25px;
    padding: 0px 0px 10px;
    /* border-top: 3px solid #e1e1e1; */
}
@media only screen and (max-width: 479px){
    .tab .nav-tabs li{
        text-align: center;
        background-color: blueviolet;
    }
    .tab .nav-tabs li a:before{ bottom: 0; }
}
/* style for tab profiel/horizontal ends */
.tree-noninput{
  padding-right: 107px;
}
.tree-noninput-id{
  padding-right: 130px;
}

.hie-head th{
  padding: 5px;
}
.calender.cdk-overlay-container:empty {
  display: block!important;
}
.angular-editor[_ngcontent-dcm-c168] .angular-editor-wrapper[_ngcontent-dcm-c168] .angular-editor-textarea[_ngcontent-dcm-c168] {
 
  width: 700px!important;
}
.angular-editor-toolbar[_ngcontent-eks-c169]{

  width: 700px!important;
}
/* question starts */

.addquesform .mat-dialog-container{
  position: absolute;
  top: 53px;
  left: 240px;
  width: 929px;
  height: 589px!important;
  overflow:hidden!important;
  padding: -10!important;
}
.addquestpopup .mat-dialog-container{
  position: absolute;
  top: 37px;
  left: 376px;
  width: 552px!important;
  height: 184px!important;
  overflow:hidden!important;
  padding: -10!important;
}
.addquestion .mat-dialog-container{
  position: absolute;
  top: 52px;
  left: 319px;
  width: 735px!important;
  height: 441px!important;
  overflow:scroll!important;
  padding: -10!important;
}
.queston-padding{
  padding-left: 29px!important;
}
.input-question{
  margin-left: 15px!important;
  height: 33px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border-color: #80808066;
  border-width: thin;
}
 .rtext{
  padding-bottom: 15px;
 }
 .allignopt{
  padding-bottom: 15px;
  padding-top: 15px;
 }
 .box-qus{
   border:1px solid #dddddd;
   border-radius: 4px;
    margin: 15px!important;
    padding: 10px;
 }
 .text-input{
  width: 100%;
  height: 32px;
  padding: 10px;

 }
 .bluebtn:hover{
   color: white !important;
 }
 .desc-input{
  width: 100%!important;
  padding: 10px;
  border: 1px solid #dddddd!important;
 }
 .type-qn{
  background-color: #eef5f9;
  padding: 5px !important;
 }
 .hr-margin{
  margin-top: 10px!important;
  margin-bottom: 10px!important;
 }
 .delete-link{
  color: red;
  text-decoration: underline;
  padding-left: 18px;
 }
 .prev-link{
  color: #295bdc;
  text-decoration: underline;
  padding-left: 18px;
 }
 .qneditor > .angular-editor{
  padding-left: 15px;
 }
 .bluebtn:hover{
   color:white;
 }
 .top-pad-check{
  margin-top: 65px!important;
 }
 .top-pad-view{
  margin-top: 36px!important;
 }
 .top-pad-part{
  padding-top: 21px!important;
 }
 .noright{
  border-right: none;
 }
 .noleft{
  border-left: none;
 }
/* drag and drop sample */
 .example-list {
  width: 100%;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  padding-top: 10px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* star rating */
.star-text{
  padding-top:30px;
  text-align: left;
}

.test-form{
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  padding-left: 6px;
  margin-bottom: 15px;
}
.qpanel-head{
  background-color: #eef5f9!important;
  font-weight: bold;
 
}
.qpanel-head:hover{
  background-color: #eef5f9!important;
  font-weight: bold;
 
}
.qstyle{
  font-weight: bold;
  padding-top: 12px;
}
.converlisting > .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none!important;
}
.converlisting > .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none!important;
} 
.converlisting >.mat-expansion-panel-body{
  border: none !important;
}
.ngx-select__toggle {
  width:98%!important;
  border: none!important
}
.ngx-select__search{
  border:1px solid #7a7a7a54!important;
  padding: 10px!important;
}
.expandedboxwidth {
  width: 115%!important;
}
.qbox{
  border:1px solid #c1c2c5;
  border-radius: 8px;
}
.hr-margin-new {
  margin-top: 3px!important;
  margin-bottom: 3px!important;
}
.crosssign-new {
  width: 19px!important;
  height: auto;
  border: none;
  
}
.addgroup >.ngx-select__selected-plural[_ngcontent-rvs-c161] {

  background-color: #d1e8ff!important;
}
.addadmins  .ngx-select__selected{
    width: 236px!important;
    border: 1px solid black!important;
    height: 40px!important;
    border-radius: 4px!important;
    overflow:hidden!important;
}
.drop-select-adduser {
  height: 26px;
  border: none;
  background-color: white;
}
mat-slider {
  width: 857px;
  margin-left: 15px;
  margin-right: 53px;
}
.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px
}
.mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
  background-color: #295BDC!important;
}
.mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
  background-color: #295BDC!important;
}
.cont {
  width: 93%;
  text-align: left;
  margin-left: 15px;
  padding-top:30px;
  background: white;
  color: rgb(10, 6, 6);
  border-radius: 5px;
  overflow: hidden;
}

div.stars {
  width: 270px;
  margin-left: -62px!important;
  display: inline-block;
}
select[multiple]{
  width: 150px!important;
}
#search-confinal {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 471px;
  top: 5px;
  transform: matrix(1,0,0,1,0,0);
  color: #295bdc;
}
#search-confinal path {
  fill: rgba(41,91,220,1);
}
.outerpadd{
  margin:20px;
  margin-left: 0px!important;
  width: 100%;
  padding:10px;
}
/* star */
label.star[_ngcontent-ple-c176] {
  font-size: 30px!important;
}
.star[_ngcontent-nco-c176]:checked ~ label.star[_ngcontent-nco-c176]:before {
  content: "\f005";
  color: #295BDC!important;
  transition: all .25s;
}
.star-5[_ngcontent-fat-c176]:checked ~ label.star[_ngcontent-fat-c176]:before {
  color: #295BDC!important;
  text-shadow:none!important;
}
.star-1[_ngcontent-hww-c176]:checked ~ label.star[_ngcontent-hww-c176]:before {
  color:  #295BDC!important;
}
input.star:checked~label.star:before {
  color: #295BDC!important;
}
.review-stars input.star:checked~label.star:before {
  color: #fd4!important;
}
.textwidth{
  width:100%!important;
}
.rating-list li {
  float: right;
  color: #ddd;
  padding: 10px 5px;
}
.rating-list li:hover,
.rating-list li:hover ~ li {
  color: #4B8BD1;
}
.rating-list {
  display: inline-block;
  list-style: none;
}
.headbold{
  font-weight: bold;
  padding:10px;
}
#UserDetailsEdit {
  left: 58px;
  top: 168px;
  position: absolute;
  overflow: visible;
  width: 121px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  color:   #3F3F3F;
}
#groupsEdit {
  left: 58px;
  top: 609px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  color:   #3F3F3F;
}
#hierarchysecEdit {
  left: 58px;
  top: 467px;
  position: absolute;
  overflow: visible;
  width: 102px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  color:   #3F3F3F;
}
.todotext{
  font-size: 30px;
  font-weight: bold;
  padding-top: 40px;
  padding-left: 290px;
}
.listtext{
  font-size: 22px;
  padding-top: 40px;
  padding-left: 11px;
  padding-bottom: 40px;
}
.i_bg-color{
  width: 100%;
  background-color:  rgb(212 218 221)!important;
}
/* .profile-color:hover{
  background-color:  white!important;
} */
/* .dropdown:hover{
  background-color:  white!important;
} */
.close-btn-add {
  border: none;
  background-color: white;
  text-align: end;
}
.text-red-ivalid{
  color:red;
  font-size: 20px;
  /* position: absolute; */
}
.input-edit-red{
  border-color: red!important;
}
.input-editphone {
  border: none !important;
}
.padding-special{
  margin-right: -27px!important;
}
.bluebtn-upload {
  background-color: #295BDC;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  color: white!important;
  padding: 6px 20px!important;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.drop-upload{
  text-align: center;
  font-size: 8px;
  color: rgba(41,91,220,1);
  padding-left: 49px;
}
.upload-text-drop {
  position: absolute;
  top: 47%;
  left: 44%;
  text-align: center;
  font-size: 8px;
  color: rgba(41,91,220,1);
}
.displayname{
  position: absolute;
  top: 47%;
  left: 10%;
  font-size: 8px;
  color: rgba(41,91,220,1);
}
.upload-type-drop{
  text-align: center;
  font-size: 8px;
  color: rgba(41,91,220,1);
  padding-left: 52px;
}
.cons-message-import{
  background-color: #e1f0f7;
  padding: 5px 15px;
  /* width: 466px; */
  line-height: 17px!important;
  font-size: 10px;
  text-align: left;
  font-weight:bold;
  margin-bottom: 10px;
}
.mreview{
  margin-left: -26px;
}
.cons-message{
  background-color: #e1f0f7;
  padding: 21px;
  width: 623px;
  text-align: left;
  font-weight:bold;
  margin-left: 308px;
  margin-bottom: 10px;
}
.form-edit-deact {
  color: white!important;
  background-color: #c9302c; 
}
.form-edit-act {
  color: white!important;
  background-color: #295BDC;
}
.middlephone{
  /* width: 270px; */
  width: calc(100% - 165px)!important;
  margin-left: 10px;

}
.closest{
  padding-right: 0px!important;
  margin-right: -15px!important;
}
.exten{
  width: 70px!important;
  margin-left: 4px!important;
}

.text-danger-addcontact{
   background-color: rgb(238, 101, 101);
  padding:3px;
  color:white!important;
  margin-bottom: 10px!important;
  z-index: 999999!important;
  margin: top 10px!important;
  font-size: 75%!important;
}
.input-editphone:focus {
  border: none!important;
}
#toast-container > .toast {
  width: 500px; 
}
.bottom-mar{
  margin-bottom: 15px;
}
.updown-img{
    width: 20px!important;
    height: 20px!important;
    margin-right: 15px!important;
    cursor: pointer;
}
.btn:focus{
  outline: none!important;

}
.caret{
  transform: rotate(3deg)!important;
}
.btn .caret {
  margin-left: 0px;
  margin-right: 0px!important;
}
.addhover:hover {
  cursor: pointer;
  border-spacing: 0px;
  border-spacing: 0px;
}
.greenround{
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  color:white;
  font-size: 12px;
  text-align: center;
  padding: 2px;
}
.redround{
  height: 20px;
  width: 20px;
  background-color: rgb(211, 12, 12);
  border-radius: 50%;
  display: inline-block;
  color:white;
  font-size: 12px;
  text-align: center;
  padding: 2px;
}
.table-striped > tbody > tr:nth-child(2n+1):hover{
  background: #D9D9D9!important;
}
.table-striped tbody>tr:nth-of-type(odd):hover{
  background: #D9D9D9!important;
}
.table-striped tbody>tr:nth-of-type(odd):focus{
  background: #D9D9D9!important;
}

/* .table>tbody>tr:hover {
  background-color: #D9D9D9!important;
} */
.table-hover tbody tr:hover td  
{ 
  background-color: #D9D9D9!important;
} 
.table-striped >tbody>tr:hover{
  background-color: #D9D9D9!important;
}
.table-striped >tbody>tr:nth-child(odd):hover, 
.table-striped >tbody>tr:nth-child(odd)>th:hover {
  background-color: #D9D9D9!important;
 }


.ngx-select_multiple[_ngcontent-iiy-c141] {
  height: 120px!important;
}
.ngx-select_multiple[_ngcontent-hrv-c141] {
  overflow-x: scroll!important;
}
.ext{
  color:#0D4DE9!important;
}
#search-blue-group {
  overflow: visible;
  position: relative;
  width: 17px;
  height: 24px;
  left: 569px!important;
  top: 5px;
  transform: matrix(1,0,0,1,0,0);
}

.search-icon {
  position: relative;
  /* top: 46%; */
  right: 30px;
  margin-top: -10px;
  color:rgba(41,91,220,1)!important;
}
.search-icon-hierarchy{
  position: relative;
  /* top: 46%; */
  right: 30px;
  margin-top: -10px;
  color:rgba(41,91,220,1)!important;
}
.search-icon-emp {
  position: relative;
  top: 30%;
  right: 30px;
  color: rgba(41,91,220,1)!important;
}
.search-icon-mytodo {
  position: relative;
  top: 43%;
  right: 30px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}
.search-icon-gp {
  position: relative;
  top: 59%;
  right: 30px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}
.search-icon-manage{
  position: relative;
  top: 59%;
  right: 30px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}
.searchValue {
  border-radius: 7px;
  border:none;
  width: 215px!important;
  padding: 6px;
  padding-left: 10px;
  color: rgba(41,91,220,1)!important;
  background-color: rgba(240,240,240,1)!important;
  margin-top: 6px;
}
.searchValue-hierarchy {
  border-radius: 7px;
  border:none;
  width: 215px!important;
  padding: 3px;
  padding-left: 10px;
  color: rgba(41,91,220,1)!important;
  background-color: rgba(240,240,240,1)!important;
  margin-top: 6px;
}
.spinner_container-profile{
  border-radius: 10px;
  height: 70px;
  left: 147%;
  position: absolute;
  top: 27%;
  transform: translate(-50%,-50%);
  width: 70px;
  z-index: 3;
  opacity: 0.3;
}
.searchValue-mytodo{
  border-radius: 7px;
  border:none;
  width: 215px!important;
  padding: 6px;
  padding-left: 10px;
  color: rgba(41,91,220,1)!important;
  background-color: rgba(240,240,240,1)!important;
  margin-top: 6px;
}
.searchValue::placeholder {
  color: rgba(41,91,220,1);
  opacity:0.7;
}
.dotedtext{
  display: inline-block;
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.dotedtext:hover {
  z-index: 1;
  width: auto;
  background-color: #FFFFCC;
}
.savebtnadd{
  background-color: #f27127;
  margin-right: 5px;
  font-size: 14px!important;
}
.profileselect .ngx-select__toggle {
 margin-bottom: 22px;
 border-color: white!important;
}
.profileselect .ngx-select__selected {
  height: 30px!important;
  width: 135px;
}
.profileselect .ngx-select__choices {
  position: absolute!important;
  width: 100%!important;
  overflow-x: scroll!important;
}
.handicon{
  cursor: pointer;
  border-spacing: 0px;
}
.login_input{
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
   
}

.file_textw{
  border: none;
  width:150px;
}
.phone_std{
  margin-top: 5px;
  line-height: 20px;
  width:45px!important;
  padding: 5px 20px 5px 10px;
  /* margin: 0; */
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF!important;

/* 
  width:45px!important;
  height: 33px!important;
  border-radius: 4px;
  background-color: #F2F2F2!important;
  border:none!important;
  margin-top: 5px;
  margin-bottom: 5px; */
}
.phone_std_imp {
  width:45px!important;
  height: 33px!important;
  /* border: none!important; */
}

.phone_style{
  margin-top: 5px;
  line-height: 20px;
  width: calc(100% - 135px)!important;
  padding: 5px 20px 5px 10px;
  /* margin: 0; */
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF!important;
  margin-left:5px;


  /* margin-left:5px;
    width: calc(100% - 135px)!important;
    height: 33px!important;
    border-radius: 4px;
    background-color: #F2F2F2!important;
    border:none!important; */
 
}
.phone_std_profile{
  line-height: 20px;
  width:45px!important;
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: 1px solid;
  background: #FFFFFF;
  /* width:45px!important;
  height: 33px!important;
  border-radius: 4px;
  background-color: #F2F2F2!important;
  border:none!important;
  margin-top: 5px;
  margin-bottom: 5px; */
}
.org-head{
  /* font-size: 15px;
  font-weight: bold;
  color:   #3F3F3F; */
    font-size: 14px;
    color: #295bdc;
    font-weight: 600;
    padding: 4px 0;
}
.phone_style_profile{
  /* margin-left:5px;
  width: calc(100% - 135px)!important;
  height: 33px!important;
  border-radius: 4px;
  background-color: #F2F2F2!important;
  border:none!important; */

  line-height: 20px;
   margin-left:1%!important;
width: 60%;
  padding: 5px 20px 5px 10px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: 1px solid;
  background: #FFFFFF;
}
.ext_style_profile{
  width:18% !important;
  line-height: 20px;
  margin-left:1% !important;

  padding: 5px;
  margin: 0;
  font-size: 15px;
  outline: none;
  border: 1px solid;
  background: #FFFFFF;

  /* height: 33px!important;
  border-radius: 4px;
  background-color:  #F2F2F2!important;
  border:none!important; */
}


.phone_styleimp{
  margin-left:5px;
  width: calc(100% - 138px)!important;
  height: 33px!important;
  border-radius: 4px;
}
.ext_style{
  margin-top: 5px;
  width:55px!important;
  line-height: 20px;
  padding: 5px 20px 5px 10px;
  /* margin: 0; */
  font-size: 15px;
  outline: none;
  border: 1px solid #03080a;
  background: #FFFFFF!important;


  /* height: 33px!important;
  border-radius: 4px;
  background-color: #F2F2F2!important;
  border:none!important; */
}
.add-new-orange{
  /* padding-bottom: 5px; */
  font-size: 18px;
  color: #3F3F3F;
}
.profile-dropdown{
  left: 0px!important;
}
.ext_style_imp{
  width:55px!important;
  /* border: none!important; */
  height: 33px!important;
}
.addgroupstyle .mat-dialog-container {
  position: absolute;
  top: 65px;
  left: 331px;
  width: 690px!important;
  /* height: 494px!important; */
  height: 510px!important;
  overflow: hidden!important;
  padding: -10!important;
}
.activecolor{
  background-color: #81ecba;
  padding: 3px;
  border-radius: 7px;
}
.activecolor1{
  background-color: #81ecba;
  padding: 3px;
  border-radius: 37px;
}
.yellowcolor{
  background-color: #e6d37f;
  padding: 3px;
  border-radius: 7px;
}
.inactivecolor{
  background-color: #e7afaf;
  padding: 3px;
  border-radius: 7px;
}
.inactivecolor1{
  background-color: #e7afaf;
  padding: 3px;
  border-radius: 37px;
}
.image-upload-edit {
  margin-top: 50px;
  padding-left: 14px;
}
.deletedir .mat-dialog-container {
  position: absolute;
  top: 155px;
  left: 29%;
  width: 416px!important;
  height: 239px!important;
  overflow: none!important;
  padding: -10!important;
}
.imageupload-width{
  height: auto;
  width: auto;
  object-fit: cover;
} 
.imageupload-width-user {
  height: 150px;
  width: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.text-danger-min {
  /* background-color: rgb(238, 101, 101);
  text-transform: none!important;
  border-radius: 4px;
  padding: 0px 3px;
  color: white!important;
  margin-bottom: -2px!important;
  z-index: 3!important;
  position: absolute;
  top: -16px;
  left: 17px; */
  background-color: rgb(238, 101, 101);
  text-transform: none!important;
  border-radius: 4px;
  padding: 4px;
  color: white!important;
  margin-bottom: 10px!important;
  z-index: 3!important;
  position: relative;
}

.spinner_containerconver {
  border-radius: 10px;
  height: 70px;
  left: 45%;
  position: absolute;
  top: 80%;
  transform: translate(-50%,-50%);
  width: 70px;
  z-index: 3;
  opacity: 0.3;
}
.btn-red
{
  background-color: red !important;
}


.choose-usertrans {
  width: 350px;
  height: 40px;
  position: relative;
  background-color: white !important;
}
.margintrsn{
  margin-top: 20px!important;
  padding-left: 15px;;
}
.choose-user:focus{
  border: none !important;
}
.minstyle{
  margin-right: 10px;
}
.mininput{
  margin-right: 10px;
  padding-left: 39px;
  width: 90px;
  border: 1px solid #dddddd!important;
}
.draftcolor {
  background-color: #e9712b!important;
  padding: 3px;
  border-radius: 7px;
}
/* for text display as dots and show when hover it */
[data-title] {
	font-size: 18px;
	position: relative;
	cursor: help;
}
[data-title]:hover::before {
	content: attr(data-title);
	position: absolute;
	bottom: -6px;
  left:26px;
	padding: 10px;
	background: #000;
	color: #fff;
	font-size: 14px;
	white-space: nowrap;
}
.dottext{
  overflow:hidden;
  max-width:150px;
  white-space:nowrap; 
  text-overflow:ellipsis;
}
.dottext-userlist{
  overflow:hidden;
  width:100%;
  white-space:nowrap; 
  text-overflow:ellipsis;
}
.dottext-useredit{
  overflow:hidden;
  width:100%;
  white-space:nowrap; 
  text-overflow:ellipsis;
  border:none!important;
}
.bg-yellow {
  color:white !important;
  background-color:#E6A801 !important;
}
.bg-yellow:hover{
  color:white!important;
}
.text-red-inv {
  color: red;
  font-size: 12px;
}
.user_name_edit-red {
  width: 293px;
  height: 66px;
  margin-bottom: -15px;
  overflow: visible;
  color: red!important;
}
.org_name_edit2-red {
  width: 293px;
  height: 66px;
  margin-bottom: -15px;
  overflow: visible;
  color: red!important;
}
.text-danger-addgroup {
  background-color: rgb(238, 101, 101);
  padding: 5px;
  color: white!important;
  margin-bottom: -23px!important;
  z-index: 999999!important;
  top: 134px;
  left: -412px;
  position: relative;
}
.botspace{
  margin-bottom: 8px;
}
.botspace-padd{
  margin-bottom: 5px;
}
.dottext-userlist-hie {
  overflow: hidden;
  border:none;
  height: 34px;
  max-width: 143px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.gray-border{
  border-color: #80808066;
  border-width: thin;
}
.import-text{
  width: 100%;
    background-color: #d8e7ef;
    border: none;
    border-radius: 4px;
    height: 33px;
    padding-left: 5px;
}

.dottex-group {
  overflow: hidden;
  max-width: 201px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.imp-color{
    background-color: #d8e7ef;
    border: none;
    border-radius: 4px;
    height: 30px;
    padding-left: 5px;
}
.dotposition{
  width: 103px;
  /* position: absolute; */
}
.org-val{
  top: -29px;
  left: -15px;
  position: absolute;
}

.left-rate{
  margin-left: 20px;
}
.chatbox{
  border-radius: 9px;
  background-color: #d8e7ef;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}
.name-box{
  text-align: end;
  font-size: 11px;
}
.top-mar{
  margin-top: 5px;
}
.validation-err{
  margin-top: 10px;
  border-color: red;
  border: 2px solid red!important;
  border-top: 2px solid red!important;
  
}
.surveydtls{
  padding:15px;
  text-align: center;
}
.surhead{
  font-size: 22px;
    margin: 10px;
}
.sursub{
  margin:10px;
}
.sublast{
  margin-bottom:10px;
}
.quest-drop{
    width: 198px;
    background-color: white;
    border-radius: 4px;
    height: 30px;
    padding-left: 5px;
}
.surdethead{
  font-size:18px;
}
.content-blue{
    background-color: white;
    padding: 15px;
    font-size: 20px;
    color:#0d64ed;
    margin-bottom: 20PX;
    text-align: center;
}
.goal-pad{
  padding:15px;
  font-size: 22px;
}
.dispmsg{
  background-color: #d8e7ef;
  padding:20px;
  text-align: center;
  margin-left: 300px;
  border-radius: 7px;
}

#LastLogin-up {
  position: absolute;
  width: 110px;
  height: 29px;
  left: 35px;
  top: 357px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color:   #3F3F3F;
}
.drop-select-group {
  width: 375px;
  height: 40px;
  border:1px solid #7a7a7a54!important;
  background-color: white;
}
.ngx-select_multiple{
  border:none!important;
}
.goal .mat-dialog-container {
  position: absolute;
  top: 93px;
  left: 344px;
  width: 614px!important;
  height: 222px!important;
  padding: 31px!important;
  overflow: none!important;
}
.goal .mat-progress-bar-fill::after {
  background-color: #2698c5!important;
}
.goaleventType{
  height: 40px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
}
.reviewdueDate{
  height: 40px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
  width:762px;
}
.dueDate{
  height: 40px;
  border-radius: 4px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
  width:100%;
}
.addreviewdueDate{
  height: 40px;
  border-radius: 4px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
  width:100%;
}
.reviewerform .ngx-select__search{
  width:94%!important;
}
.reviewerform .ngx-select__choices{
  width:94%!important;
}
.reviewerform .ngx-select__selected {
  width: 762px;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
} 
.user-drop-gedit .ngx-select__search {
  width:101%!important;
}
.user-drop-gedit-goal .ngx-select__search {
  width:101%!important;
}
.user-drop-review .ngx-select__search {
  width:101%!important;
  margin-left: -3px;
}
.input-edit-phone {
  border: none !important;
}
.action-btngp{
  margin-left: 84px;
}
.action-head{
  text-align: center;padding-left: 58px!important;
}
.left-width-revirew{
  margin-left: 15px!important;
}
.user-drop .ngx-select__toggle-buttons{
  display:block;
}
.addScroll .ngx-select_multiple{
  position: relative!important;
}
.addScroll .ngx-select__choices{
  position: relative!important;
  height: 110px!important;
  width: 98%!important;
}
.addScroll .ngx-select__choices .dropdown-menu {
  position: relative!important;
}
.user-drop-gedit .ngx-select__choices{
  width: 100%!important;
}
.user-drop-gedit-goal .ngx-select__choices{
  width: 100%!important;
}
.namebox .dropdown-menu {
  max-height: 110px!important;
}
.addnewhir {
  text-align: left;
  color: #3F3F3F;
  font-size: 20px;
}
.msgdatesend{
background-color: #afe7cd;
padding: 5px;
}
.namegrp{
  margin-left: 2px!important;
  width: 106%!important;
  border: 1px solid #7a7a7a54!important;
}
.grey-text-border{
  border: 1px solid #7a7a7a54!important;
}
.namedrp{
  margin-left: 2px!important;
  width: 416px!important;
  border: 1px solid #7a7a7a54!important;

}
.delete-coloropt {
  background-color: #EE4C58;
  color: white;
  font-size: 12px!important;
  padding:6px 7px!important;
}
.addoption .mat-dialog-container {
  position: absolute;
  top: 52px;
  left: 375px;
  width: 540px!important;
  height: 463px!important;
  overflow: scroll!important;
  padding: -10!important;
}

.mat-date-range-input-container{
  justify-content: flex-end;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 7px!important;
}
.blue-padd{
  margin-right:0px!important;
  margin-left: 5px!important;
}
.delete-coloropt:hover{
color: white !important;
}
.active-user-do {
  font-size: 14px;
  color:#03080A!important;
  /* color:rgb(9 9 9); */
  margin-bottom: 0px!important;
}
.task-padd{
  margin-top:5px;
  margin-bottom: 7px;
}
.to-do-style{
  font-weight: bold;
  margin-top: 10px!important;
  margin-left: 20px!important;
  font-size: 16px;
}
.scale{
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 7px;
    font-weight: bold;
}
label.star {
  float: right;
  padding: 10px;
  font-size: 25px!important;
}
.cons-message-indi {
  background-color: #e1f0f7;
  padding: 21px;
  width: 905px;
  text-align: center;
  margin-left: 162px;
  margin-bottom: 10px;
}
.changehead{
  font-size: 18px;
}
.content-white-conver {
  height: auto;
  background-color: white;
  padding: 5px 25px 36px 25px;
  margin-bottom: 20PX;
}
.head-inner{
  border-radius: 8px 8px 0px 0px;
  margin-left: 0px!important;
  margin-right: 0px!important;
  margin-bottom: -3px!important;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #295BDC;
  color: white!important;
}
.type-mar{
  margin-left: -85px;
}
.groupconf .mat-dialog-container {
    top: 74px;
    left: 419px;
    width: 628px!important;
    height: 262px!important;
    padding: 31px!important;
    overflow: none!important;
    padding: -10!important;
}
.surveysel .ngx-select__choices {
  max-height: 119px!important;
}
.addadmins .ngx-select__choices {
  max-height: 110px!important;
}
.progress{
  margin-left: 19px!important;
  width: 97%;
}
.mat-expansion-panel-header{
  border-radius: 7px 7px 0px 0px;
}
.box-mar{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}
.descstyle{
  padding-top: 8px;
  margin-left: 40px;
  padding-bottom: 8px;
}
.marginl{
  margin-left: 35px;
  margin-right: 35px;
}
.error-span{
  padding: 10px 50px!important;
}
.error-spanemail{
  padding: 10px 110px!important;
}
.leftpad-midd{
  padding-left: 50px!important;
}
.upload-field-visible {
    margin-bottom: 10px;
}
.red-inval{
  margin-bottom: 27px;
}
.red-inval-first{
  margin-bottom: 27px;
  margin-left: 0px;
}
.reviewver .ngx-select__choices{
  width:100%!important;
}
.user-drop-gedit .ngx-select__disabled{
  width: 101%!important;
}
.user-drop-gedit-goal .ngx-select__disabled{
  width: 101%!important;
}
.goal-res-employee  .ngx-select__disabled{
  width: 100%!important;
}
.goal-res-employee .ngx-select_multiple{
  width: 100%!important;
}
.targetval {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #80808066!important;
}
.commentbox .mat-dialog-container {
  position: absolute;
  top: 52px;
  left: 270px;
  width: 696px!important;
  height: 331px!important;
  overflow: hidden!important;
  padding: -10!important;
}

.searchformst {
  margin-right: -199px;
  margin-top: -5px;
}
.searchbarspace{
  margin-right: 3px;
}
.addscroll{
  overflow:scroll;
}
.search-icon-mytodo {
  position: relative;
  top: 52%;
  right: 27px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}
input:focus{
  outline: none;
}
select:focus{
  outline: none;
}
.toppadd{
  padding-top: 6px!important;
}
.text-titledan {
  background-color: rgb(238, 101, 101);
  text-transform: none!important;
  border-radius: 4px;
  padding: 4px 0px;
  color: white!important;
  margin-bottom: 10px!important;
  z-index: 3!important;
  position: relative;
  top: -75px;
  left: 57px
}
.empparticipant .mat-dialog-container {
    top: 53px;
    top: 96px;
    top: 52px;
    left: 216px;
    width: 888px!important;
    height: 374px!important;
    overflow: hidden!important;
    padding: -10!important;
}
.review-val{
  margin-left: 297px;
}
.loginwidth{
  width:35%!important;
}
.marginname{
  margin-bottom: 5px!important;
  margin-top: 15px!important;
}
.nav-tabs .dropdown-menu{
  z-index: 10000!important;
}
.signoffbox {
  border-radius: 9px;
  background-color: #d8e7ef;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
  border: none!important;
  margin-left: 1px;
}
.notrespond{
  background-color:#81ECBA;
  padding:5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
.signoffuser{
  background-color:#81ECBA;
  padding:5px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
.finaluser{
margin-left: -234px!important;
}
.hiredDate > .show{
    width: 372px!important;
}
.text-opt-style{
  text-transform: capitalize; 
}
.appfilter{
  position: relative;
  top: 7px;
}
.filt{
  background-color: #295BDC;
    padding: 6px;
    margin-right: 5px;
    border-radius: 4px;
    font-size: 13px;
    border: none;
    cursor: pointer;
    border-spacing: 0px;
}
#templatemo_slider { clear:both; }
.mat-card{
  padding: 25px;
}
.padding-ruser{
  padding-left: 1px!important;
  padding-top: 10px;
}
.valmargin-review{
  margin-left: 305px;
}

.dueDatereview {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
  width: 97.3%;
}
.leftmarginreview{
  margin-bottom: 20px!important;
}
.add-goal .ngx-select__selected {
    width: 746px;
    border: 1px solid #80808066!important;
    height: 40px!important;
    border-radius: 4px!important;
    overflow: hidden!important;
}
.add-goal-new .ngx-select__selected {
  width: 100%!important;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.add-goal-new .ngx-select {
  width: 100%!important;
}
.addScroll-goalnew .ngx-select__choices {
  height: 110px!important;
  width: 99%!important;
}
.dueDategoal {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
  width: 100%;
}
.dueDatecon {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
  width: 100%;
}
.add-goalcon .ngx-select__selected {
  width: 99%;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.add-goalcon .ngx-select{
  width: 101%!important;
}
.goal-additional .ngx-select_multiple{
  border: none!important;
    margin-left: -2px;
    width: 101.5%!important;
}
.goal-additional .ngx-select__choices {
  width: 98%!important;
}
.dueDate-rev {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #80808066!important;
  padding-left: 10px;
  width: 100%;
}
.resemp-review .ngx-select__disabled{
  width:100%!important;
}
.resemp-review  .ngx-select_multiple{
  width:100%!important;
}
.input-wid-review {
  width: 100%!important;
}
.add-goal-new  .ngx-select__search{
  width: 100%!important;
}
.add-goal-new  .ngx-select__choices{
  width: 100%!important;
}
.addScroll-con .ngx-select__choices {
  height: 110px!important;
  width: 100%!important;
}
.addScroll-rvgoal .ngx-select__choices {
  height: 110px!important;
  width: 100%!important;
}
.addScroll-rvgoal .ngx-select__search {
  width: 100%!important;
}
.addadminsgp .ngx-select_multiple{
  width: 107%!important;
}
.container{
  width:100%!important;
  padding-left: 0px!important;
  padding-right: 0px!important;
}
.search-icon-admin {
  position: absolute;
  top: 47%;
  right: 168px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}
.childtable{
  border: none!important;
}
.childtable > tr > td{
  border: none!important;
}
.child-first-member {

  padding-left: 23px!important;
}
.small-width{
  width:11%!important;
}
.small-widthname{
  width:21.5%!important;
}
.small-widhead{
  width:13%!important;
}
.plusbtn{
  text-align: left;
  padding-left: 45px;
}
.spinner{
  margin-left: 569px;
  padding-top: 147px
}
.spinner-blue{
  margin-left:50%;
}
.search-icon-forms {
  position: relative;
  top: 61%;
  right: 30px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}

.search-icon-confinal {
  position: relative;
  top: 52%;
  right: 17px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}
.review-add-new .ngx-select{
  width: 100%;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.review-add-new .ngx-select__choices {
  width: 100%;
}
.review-add-new  .ngx-select__search {
  width: 100%!important;
}
.review-add-new .ngx-select__selected {
  width:100%!important;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.goal-add-new .ngx-select{
  width: 100%;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.goal-manager-new .ngx-select{
  width: 100%;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.review-emp-new .ngx-select__search{
  width: 100%;
}
.review-date-new{
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #80808066!important;
  padding-left: 10px!important;
}
.left-padd-review{
  padding-left: 0px!important;
    padding-right: 0px!important;
}
.left-padd-goal{
  padding-left: 17px!important;
  padding-right: 19px!important;
}
.goal-emp-new .ngx-select__search {
  width: 100%;
}
.goal-emp-new.ngx-select {
  width: 100%;
} 
.reviewadd-goal-part .ngx-select__search{
  width: 100%;
}
.con-goal-new .ngx-select__search{
  width: 100%;
}
.additional-padd{
  padding-left: 10px!important;
    padding-right: 10px!important;
}

.con-goal-new .ngx-select__search{
  width: 100%;
}
.con-date-left-pad{
  padding-left: 17px!important;
  padding-right: 17px!important;
}
.ngx-select__choices {
  overflow-x: scroll!important;
}
.mat-progress-spinner circle {
  stroke-width: 6%!important;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #295bdc!important
}
.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle {
  transition-property: stroke!important;
  animation-duration: 4000ms!important;
  animation-iteration-count: infinite!important;
}
.startendDate{
    width: 200px;
    height: 30px;
    border-color: #cbc3c3;
    background-color: white;
    /* border-radius: 7px; */
    border:1px solid #cbc3c3;
    margin-left:5px;
    padding:10px;
    font-size: 13px;
}
.headerlogo{
  margin-top: 7px;
  width: 188px;
  height: 38px;
  margin-left: 6px;
}
.end-date{
  margin-right: 5px;
}
#review-elements {
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  color: rgba(0,0,0,1);
  text-transform: capitalize !important;
  padding-left: 0px;
  /* padding-top: 10px;
  margin-bottom: 12px; */
}



.input-filter-search {
  outline: none;
  border-radius: 5px;
  border: 1px solid #D0D0D0;
  font-size: 14px;
  padding: 2px 33px 5px 10px;
  width: 100%;
  
  max-width: 100%;
  max-height: 33px;
  }
.input-filter-search {
  background: url('../img/search.svg') top right no-repeat !important;
  background-size: contain !important;
}



.box-button
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.box-button-container
{
  
    margin-top: 28px !important;
}

 .profileselect .ngx-select__search {
  /* width: 285px!important; */
  width: 100% !important;
  /* height: 33px !important; */
  border-color: #cbc3c3 !important;
  background-color: white !important;
 
  font-size: 13px !important;
  min-width: 152px;
}  

 .profileselect .ngx-select__choices {
    width: 100% !important;
    height: auto;
    max-height: 200px;
    min-width: 152px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 0;
    position: absolute;
}

 .profileselect .ngx-select__toggle {
  outline: 0;
  position: relative;
  text-align: left!important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  height: 33px !important;
  border: 1px solid #7a7a7a54!important;
  /* height: 15px!important; */
  padding-top: 7px;

  width: 100% !important;
  min-width: 152px;
  margin-right: 6px;
  justify-content: space-between;
}

 .profileselect .form-control {
  min-height: 33px !important;
  outline: 0;
  position: relative;
  text-align: left!important;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-flex;
  align-items: stretch;
  border: 1px solid #7a7a7a54!important;
  /* height: 15px!important; */
  padding-top: 7px;

  width: 100% !important;
  margin-right: 6px;
  min-width: 152px;
  justify-content: space-between;
}
.barcolor{
  font-size: 21px;
  color: #c3bebe;
}

.searchValueElements
{
  border-radius: 7px;
  border: none;
  width: 215px!important;
  height: 31px;
  padding: 3px;
  padding-top: 2px;
  padding-left: 10px;
  color: rgba(41,91,220,1)!important;
  background-color: rgba(240,240,240,1)!important;
}

.margin-top4{
  margin-top: 4%;
}
/* .table>:not(caption)>*>* {
  padding: .8rem!important;
  border-bottom-width: 1px!important;
  
} */
.left-margin
{
  margin-left: 15px;
}
.pd-30px
{
    padding-left: 30px !important;
}
.pd-7px
{
    margin-left: 7px !important;
}
.pd-40px
{
    padding-left: 40px !important;
}
.pd-23px
{
    padding-left: 23px !important;
}
.pd-5px
{
    padding-left: 5px;
    
}
.pd-2px
{   
padding-right: 2px;
}
.mg-t-5px
{
    margin-top: 5px;
}
.mg-l-2px
{
  margin-left: 2px;
}
.preview-header-font
{
  text-transform: uppercase !important;
    font-family: 'Poppins', sans-serif !important;
    color:   #3F3F3F;
    text-align: center;
    font-weight: 900 !important;
    letter-spacing: 1px !important;
}