/*-----------------------------------------------
                    side-bar 
 --------------------------------------------------*/

 .left-nav ul li {
    display: inline-flex;
    width: 100%;
    padding-left: 5px;
    padding-right: 9px;
    padding-top: 12px!important;
  }
  .left-nav ul li a:active{
    background-color: #E4A802!important;
    color: #03080A!important;
}
  .left-nav ul{
    overflow: hidden;
    padding-left: 0rem;
    width: 100%;
  }

  .left-nav ul li a{
    padding:4px!important;
    width: 100%;
    border-radius: 8px;
  }
  .side-btn{

      /* display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      border-radius: 0.25rem;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
  
  }

  
  .left-nav ul li .displayItem{  
     /* opacity:0; */
     /* transition:2s; */
     padding-left: 10px;
     overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
    /* color: #fff; */
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }
  
  /* .left-nav:hover .displayItem{ */
    /* opacity:1; */
    /* color: #fff !important; */
  /* } */
  

/* -----------------------------------------------------------------------------------
                              sub head bars
-------------------------------------------------------------------------------------- */
  /* nav bar librarayyy */

.hero{
  width: 100%;
  height: auto;
  position: relative;
  margin: 100px auto;
}

.btn-box{
  display: inline-block;
  border-bottom: 1px solid #ccc;
  text-align: center;
  position: relative;

}


/*-----------*/

.tdIcon:hover .rowIcons
{
   display: block;
}

.rowIcons ul {
  list-style: none;
  display: inline-flex;
  margin: 0 !important;
  padding: 0;
}

.rowIcons ul li {   
      margin-right: 5px;  
}
.addhover
{
  position: relative;
}

.rowIcons
{
  position: absolute;
  right: 24px;
  top: 1px;
  background: #fff;
  padding: 2px;
  box-shadow: #d0d0d0 1px 1px 1px 1px;
  display: none;
}
.rowIcons ul li a img{
  max-width: 30px;
  height: auto;
}
tr.addhover td {
  cursor: initial;
}
tr.addhover td {
  cursor: initial;
}
.rowIcons ul li a img:hover{
  max-width: 33px;
  height: auto;
}
/*--------Nav bar --------*/

ul.nav-external {
  list-style: none;
  display: inline-flex;
  margin: 0;
  padding-top: 10px;
  padding-left: 0rem;
}
ul.nav-external li {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.nav-sublink ul {
  list-style: none;
  padding: 0 ;
  background: #fff;
  box-shadow: #d0d0d0 0px 0px 6px 0px;
}
.nav-sublink ul li{
 
  border-bottom: 1px solid #d0d0d0;
  padding: 0px 15px 0px 15px;
}

.nav-sublink {
  display: none;
  position: absolute;
  left: 30px;
  z-index: 3;
}
.firstNav:hover .nav-sublink
{
  display: block;
}

li.nvLink a  , .firstNav{
  color: #000;
  text-decoration: none;
  padding: 2px 10px 2px 10px;
  font-weight: 500;
}

li.firstNav.navList {
  color: #5fa2db;
}

li.nvLink a:hover  
{
  color: #295BDC!important; ;
}
.firstNav:hover{
  color: #295BDC!important; ;
}

li.nvLink a:hover  svg{
  fill:#295BDC!important;;
}

.nav-sublink ul li {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}


.navList:after {
  content: '';
  display: none;
  background: #295BDC!important; ;
  height: 2px;
  margin-top: 5px;
}

.active.navList:after 
{
  display: block;
}

a.nav-link.active:after {
  content: '';
  display: block;
  height: 3px;
  background-color: #295BDC!important;;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
a.nav-link
{
width: 100%;
position: relative;
color: #000!important;
font-weight: bold;
}
a.nav-link svg{
  fill: rgba(0, 0, 0, 1) ;
}
a.nav-link.active svg{
  fill:#295BDC!important; ;
}

a.nav-link.active 
{
  color: #295BDC!important; ;
} 