
.leftmenu-open{
    background:var(--RC-left-menu-color) !important;
    }
    .leftmenu-closed {
      background:var(--RC-left-menu-color) !important;
    }
    
    a.nav-link1.active {
        font-weight: 500;
      color:var(--RC-utility-color) !important
    }
    
    a.nav-link1:hover {
      color:var(--RC-utility-color) !important
    }
    a.nav-link1 {
      width: 100%;
      position: relative;
      color: var(--RC-top-menu-color) !important;
      font-weight: 400;
      text-decoration: none;
      font-size: 14px;
      /* transition: 1s ease-in; */
    }



    .tab .nav-tabs li a{
        text-transform: none;
        color: var(--RC-l2-menu-color);
        font-size: 12px!important;
        font-weight: 400!important;
        margin-left: 3px !important;
        padding: 12px 30px 13px;
        margin: 0 0 1px 0;
        border-left: none !important;
        padding-right: 20px!important;
        border-radius: 0;
        position: relative;
        border: none!important;
    }


    .tab .nav-tabs li a:hover {
      
        border: none!important;
        background-color: var(--l2-menu-hover)!important;
        color:var(--RC-top-menu-hover-active-color)!important;
        /* transition: 0.2s ease-out; */
        
      }

    
      .nav-tabs>li.active>a{
        /* background-color: #1292be!important;
        color: white!important; */
        border-bottom: 2px solid var(--RC-utility-color) !important;
        font-weight: 500!important;
        background-color: var(--RC-white-color)!important;
        color:var(--RC-top-menu-hover-active-color)!important;
        overflow: visible!important;
      }

    .sidemenu-span-highlighted {
      color: var(--RC-white-color) !important;
      font-size: 14px !important;
      font-family: "Roboto" !important;
      font-weight: 100 !important;
      background: var(--RC-left-menu-highlight-background-color) !important;
      border-radius: 2.5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span{
        color: #000 !important;
        font-weight: 500;
      }
    }

    /* TOP MENU STYLES */

.blacknav {
    background-color: var(--RC-primary-color);
    text-align: end;
    padding-right: 35px;
    margin-top: 0px;
    padding-top: 0px;
    height: 55px;
  }
  
  .topbar a.nav-link1.active:after,
  a.nav-link1:hover:after {
    content: '';
    display: block;
    height: 3px;
    background-color: var(--RC-utility-color);
    position: absolute;
    bottom: 0px;
    width: 100%;
    bottom: -3px;
  }
  
  
  .nav-tabs .nav-link.active {
    background-color: var(--RC-utility-color);
  }
  

  /* .highlighted {
    background: #1575e7  !important;
    border-radius: 2.5px;
  }
  .highlighted-2 {
    background: #1575e7  !important;
    border-radius: 2.5px;
  }
   */
  