
/* ================================ */
/*        Layout                    */
/* ================================ */
.leftmenu {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary-bg);
    color: var(--text-color);
    transition: width 0.3s ease;
    overflow: hidden;
  }
  
  .leftmenu--open {
    width: var(--menu-width-open);
  }
  
  .leftmenu--closed {
    width: var(--menu-width-closed);
  }
  
  .leftmenu__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .leftmenu__item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    transition: background 0.3s;
  }
  
  .leftmenu__item:hover {
    background-color: var(--menu-hover-bg);
  }
  
  .leftmenu__link {
    text-decoration: none;
    color: var(--text-color);
    font-size: 15px;
    display: block;
    width: 100%;
  }
  
  .leftmenu__icon {
    margin-right: 10px;
  }
  
/* ================================ */
/*      Components                  */
/* ================================ */
/* Dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
  background: var(--primary-bg);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  min-width: 150px;
  z-index: 1;
}

.dropdown:hover .dropdown__content {
  display: block;
}

.dropdown__item {
  padding: 8px 12px;
  color: var(--text-color);
  text-decoration: none;
  display: block;
}

.dropdown__item:hover {
  background: var(--menu-hover-bg);
}

body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  background: #f4f4f4;
  color: var(--text-color);
}




/* ================================ */
/*        Utilities                 */
/* ================================ */
.hidden {
  display: none;
}

.flex {
  display: flex;
  align-items: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
