.drop-select-dashboard {
    /* width: 209px;
    height: 23px;
    background-color: white;
    border-radius: 7px; */
    width: 209px;
    height: 33px;
    border-color: #cbc3c3;
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
}
.st-ed-date {
    padding-right: 10px;
    padding-left: 10px;
    width: 209px;
    height: 33px;
    border: 1px solid #cbc3c3!important;
    border-color: #cbc3c3!important;
    background-color: white;
    border-radius: 7px;
}

.db-feedback{
    color:#203864;
    font-size: 16px;
    font-weight: bold;
    padding-left: 22px;
}
.firstrowdiv1{
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    width: 500px;
    height: 362px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    /* text-align: center; */
}
.firstrowdiv7{
    background-color: white !important;
    border-radius: 10px;
    margin: 8px;
    width: auto;
    /* width: 393px; */
    height: 130px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    /* text-align: center; */
}
.rightspacediv{
    padding-left: 3px;margin-right: 20px;
}
.number-feed{
    font-size: 40px;
    font-weight: bold;
    color:rgba(39,183,237,1);
    padding-left: 30px;
    padding-top: 32px;
    padding-bottom: 32px;
}
.number-content {
    font-size: 16px;
    color: #3b5483;
    padding-left: 30px;
    font-weight: lighter;
}
.firstrowdiv2 {
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    width: 682px;
    padding-left: 40px;
    /* width: 432px; */
    height: 362px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.firstrowdiv22 {
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    /* width: 432px; */
    height: 332px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.div2outer{
    margin-left: -218px;
}
.eventprogress{
    padding-top: 10px;
    padding-left: 30px;
    color:#3b5483;

}
.firstlinegraph {
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    width: 485px;
    height: 250px;
    margin-left: -37px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}
.firstcandlestick {
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    width: 634px;
    height: 250px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.apexcharts-menu-icon {
    display:none;
}
.percentage{
        margin-left: 31px!important;
        margin-right: 31px!important;
        background-color: white !important;
        border-radius: 15px;
        margin: 20px;
        height: 100px;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}
.progress-dash {
    height: 25px;

    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 15px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}
/* .mat-progress-bar {
    height: 30px!important;
    border-radius: 15px!important;
    width: 562px!important;
} */
.mat-progress-bar-buffer {
    background-color: #e4e6f5!important;
}
.mat-progress-bar-fill::after {
    /* background-color: #EE4C58!important; */
    background-color:  #337ab7!important;
   
}
.mat-progress-bar {
    height: 23px!important;
    border-radius: 6px!important;
    width: 100%!important;
}
.progressdiv{
    padding-top: 33px;
    padding-right: 20px
}
.padding-num-content{
    padding-left: 58px!important;
}

.margin-to-left{
    margin-left: -65px;
}
.divrow2outer {
    margin-left: -2%;
}
.firstrowpie1 {
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    margin-right: -28px;
    margin-left: 10px;
    /* padding-right: 34px; */
    /* width: 440px; */
    width:auto;
    height: 310px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.firstgraph1 {
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    width: 296px;
    height: 391px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.graph1{
    margin-left: -46px;
}
.apexcharts-toolbar{
    display:none!important;
}
#SvgjsG1233 path{
    fill:#56C1AB!important;
}
#SvgjsG1246 path{
    fill:#EE4C58!important;
}
/* .apexcharts-legend-marker{
    background-color:#56C1AB!important;
} */
/* .position-right{
    right: 256px!important;
} */
/* .apexcharts-legend-text{
    display:none!important;
} */
.apexchartszoukuiif>foreignObject{
    height:30px!important;
}
#SvgjsCircle1231{
    width:10px!important;
    height:10px!important;
}
.firstrowdiv3 {
    background-color: white !important;
    border-radius: 10px;
    margin: 20px;
    width: 427px;
    height: 250px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.mat-calendar-controls{
    position: absolute;
    /* top: -52px; */
    top: -44px;

}
/* .mat-calendar-body-label{
    display:none;
} */
.mat-calendar-body {
    font-size: 12px;
}
.mat-calendar-body {
    font-size: 13px;
}
.mat-calendar-body {
    font-size: 11px!important;
}
.mat-calendar-body-cell{
    width: 14.2857%!important;
    padding-top: 9.14286%!important;
    padding-bottom: 0.14286%!important;
    
}
.mat-calendar-table-header th {
    font-weight: bold!important;
    color:black;
    font-size: 12px!important;
}
.shaowDates{
    background-color: #295BDC!important;
    text-align: center;
    color:white!important;
    margin-left: 54px;
    width: 421px;
    margin-top: 20px;
    border-radius: 6px;
    position: absolute;
        top: 0;
        left: 0;
        opacity: 1.8;
        height:65px;
}
.mat-calendar-content{
    z-index: 9;
margin: 20px;
}
.eventchart  .apexcharts-canvas {
     height: 550!important;
     width: 1100!important;
  }
  #chart{
      display:contents!important;
      height: 550!important;
      width: 1100!important;
  }
  .companypie{
    padding-left: 3px;
    margin-right: 122px;
    margin-left: -13px;
  }
  .goalpie{
    padding-left: 3px;
    margin-right: 338px;
    margin-left: -11px;
  }
  .first1 {
    background-color: white !important;
    border-radius: 15px;
    margin: 20px;
    width: auto;
    /* margin-right: 0px!important; */
    /* width: 250px; */
    height: 310px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}

.first2 {
    background-color: white !important;
    border-radius: 15px;
    margin: 20px;
    width: auto;
    margin-right: 7px!important;
    /* padding-left: 40px; */
    /* width: 432px; */
    height: 310px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.first12 {
    background-color: white !important;
    border-radius: 15px;
    margin: 20px;
    width: auto;
    height: 310px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    /* text-align: center; */
}
.margin-to-leftsmall{
    margin-left: -60px;
    /* margin-left: 47px; */
}
.startEnd{
    position: relative;
    top: 17px;
    font-size: 15px;
    font-weight: bold;
}

.first3{
    background-color: white !important;
    border-radius: 15px;
    margin: 20px;
    margin-top: 70px;
    margin-left: 40.2px;
    width: 419px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 9;
    position: absolute;
    height: 310px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);

}

.d2outer {
    margin-left: -6%;
}
.past-margin{
    margin-left: -3%;
}
.number-feed2 {
    font-size: 40px;
    font-weight: bold;
    color: rgba(39,183,237,1);
    padding-left: 44px;
    padding-top: 118px;
    padding-bottom: 32px
}
.number-content2 {
    font-size: 15px;
    padding-top: 12px;
    color: #3b5483;
    padding-left: 44px;
    padding-right: 20px;
    font-weight: lighter;
}

.progress-bardash {
    float: left;
    width: 39%;
    padding-top: 2px;
    height: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #da1f66;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    transition: width .6s ease;
}
.dashheadblue{
    background-color: #295BDC;
    width: 100%;
    height: 40px;
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px
}
.dashwhitetext{
    padding-left: 20px!important;
    padding-top: 10px!important;
    color: white;
    font-size: 14px;
    font-weight: bold;
}